.module-card-press {
  .module-card__inner {
    @include position(relative);
    @include padding(40px 36px 33px 40px);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    
    @include mq($from: 'm') {
      min-height: 400px;
    }
  }

  &__title {
    padding-bottom: 30px;
    font-size: rem(24px);
    line-height: (28 / 24);
    letter-spacing: -0.5px;

    @include mq($until: 'l') {
      font-size: rem(20px);
    }
  }

  &__tag {
    align-self: flex-start;
    margin-bottom: 24px;
    border-radius: 4px;
    text-transform: uppercase;
  }
  
  &__date {
    @include margin(auto null null);
    @include padding(20px null null);
    border-top: 1px solid transparent;
    font-size: rem(15px);
    line-height: (20 / 15);
    letter-spacing: -0.8px;
  }
}
