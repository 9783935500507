.module-card-icon {
  .module-card__link {
    .no-touchevents &:focus,
    .no-touchevents &:hover {
      ~ .module-card__inner {
        .module-card-icon__title {
          color: $brand-blue-bright;
        }
      }
    }
  }
  &__title {
    @include font('sans-serif', 'bold');
    color: $brand-grey-darker;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-grey;
  }

  &__tag {
    @include font('sans-serif', 'bold');
    color: $brand-purple-bright;
    background-color: $brand-purple;
  }

  &__icon {
    background-color: $brand-blue-lighter;
  }
}
