.module-popup-form {
  @include padding(75px 110px);
  text-align: center;

  @include mq($until: 'm') {
    @include padding(null 32px);
  }

  .module-header {
    margin-bottom: 45px;
  }

  .module-header__title {
    font-size: rem(34px);
    line-height: (38 / 34);
    letter-spacing: -1px;

    @include mq($until: 'm') {
      font-size: rem(24px);
      letter-spacing: -.6px;
    }
  }

  .module-form__footer {
    flex-direction: column;
    align-items: center;
  }

  .module-form__submit {
    margin-bottom: 30px;
  }

  .module-form__messages,
  .module-form__submit {
    flex-basis: auto;
  }
}

.js-popup-form {
  .popup__content {
    max-width: 760px;
  }
}