.widget-location {
  &__location {
    @include font('sans-serif', 'bold');
    color: $brand-grey-darker;
  }

  &__address {
    @include font('sans-serif', 'medium');
    color: $brand-grey;
  }
}
