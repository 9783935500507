[data-view="template-dashboard"] {
    position: relative;
    background-color: #fff;
    padding-bottom: 60px;

    &::before {
        @include position(absolute, -220px null null 50%);
        @include size(960px);
        @include margin(null null null -480px);
        content: '';
        background-color: $brand-blue-lightest;
        border-radius: 50%;
    }

    .header {
        @include mq($from: 1280px) {
            padding-top: 190px;
        }
    }

    .header-nav {
        top: 99px;

        @include mq($until: 1280px) {
            top: 86px;
        }
    }

    .header-inner {
        max-width: 760px;
    }

    .dashboard-top {
        position: relative;
        padding-top: 200px;
        padding-bottom: 15px;
        z-index: 0;

        @include mq($until: 'l') {
            padding-top: 160px;
        }

        @include mq($until: 'm') {
            padding-top: 140px;
        }

        &::before {
            @include position(absolute, -320px null null 50%);
            @include size(1160px);
            @include margin(null null null -580px);
            content: '';
            background-color: $brand-blue-lightest;
            border-radius: 50%;
            opacity: 0;
        }

        .module-header {
            max-width: gs(8, 7);
            margin-right: auto;
            margin-left: auto;

            @include mq($from: 'm') {
                text-align: center;
            }

            @include mq($from: 'xxl') {
                max-width: gs(8, 7, 1392px, 12, 48px);
            }

            &__text {
                @include margin(null auto);
                margin-top: 24px;
                max-width: 640px;

                @include mq($until: 'm') {
                    margin-left: 0;
                }
            }
        }
    }

    .dashboard-sidebar {
        &__menu {
            a {
                @include padding(12px null);
                @include font('sans-serif', 'bold');
                display: block;
                border-bottom: 1px solid $brand-blue-light;
                color: $brand-blue-bright;
                font-size: rem(11px);
                line-height: (22 / 11);
                letter-spacing: 2.8px;
                text-transform: uppercase;
            }

            &-item {
                &--active {
                    a {
                        color: $brand-purple-bright;
                        border-bottom: 3px solid $brand-purple;
                    }
                }
            }
        }
    }

    .dashboard-grid {
        position: relative;
        z-index: 10;

        &__wrapper {
            @include padding(120px null);
            display: flex;
            border-top: 1px solid $brand-blue-light;

            @include mq($until: 'l') {
                @include padding(60px null);
                flex-direction: column;
            }

            @include mq($until: 'm') {
                @include padding(40px null);
            }
        }

        &__title {
            @include font('sans-serif', 'bold');
            margin-bottom: 30px;
            color: $brand-grey-dark;
            font-size: rem(28px);
            letter-spacing: -0.6px;
            line-height: (32 / 28);

            &:not(:first-child) {
                margin-top: 80px;
            }
        }

        &__sidebar {
            width: 260px;
            padding-right: 60px;
            flex-grow: 1;
            flex-shrink: 0;

            @include mq($until: 'l') {
                padding-right: 0;
                margin-bottom: 45px;
            }

            @include mq($until: 'm') {
                width: 100%;
            }
        }

        &__content {
            padding-left: 140px;
            flex-grow: 1;
            width: 100%;

            @include mq($until: 'xl') {
                padding-left: 90px;
            }

            @include mq($until: 'l') {
                padding-left: 0;
            }

            .module-card-workshop {
                + .module-card-workshop {
                    margin-top: 24px;
                }
            }
        }
    }

    .dashboard-plan {
        &__title {
            @include font('sans-serif', 'bold');
            font-size: rem(20px);
            letter-spacing: -.4px;
            line-height: (24 / 20);
            color: $brand-grey-darker;
            margin-bottom: 10px;
            max-width: 100%;
        }

        &__text {
            @include font('sans-serif', 'book');
            font-size: rem(18px);
            letter-spacing: -.6px;
            line-height: (28 / 18);
            color: $brand-grey;
            max-width: 100%;
            margin-bottom: 20px;
        }
    }

    .module-card-icon__icon {
        background-color: $brand-purple;
    }
}