.module-card-date {
  &:not(:first-child) {
    border-color: $brand-blue-light;
  }

  &__hour,
  &__date {
    @include font('sans-serif', 'bold');
    color: $brand-blue-bright;
  }

  &__text,
  &__caption {
    @include font('sans-serif', 'regular');
    color: $brand-grey;
  }
}
