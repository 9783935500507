[data-view="template-list-news"] {
  .header--basic {
    padding-bottom: 100px;

    .center {
      &::after {
        content: none;
      }
    }
  }

  .content-archive__items {
    position: relative;

    &::after {
      content: '';
    }

    &:first-child {
      &::after {
        bottom: -80px;
        left: -45px;
      }
    }

    &:last-child {
      &::after {
        top: -40px;
        right: -45px;
      }
    }
  }
}
