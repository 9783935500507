.module-video {
  @include size(760px, auto);
  @include aspect-ratio(1, 1);

  @include mq($until: 'xl') {
    width: 600px;
    max-width: 100%;
  }
  
  @include mq($until: 'l') {
    max-width: calc(100% + 30px);
    width: 100%;
  }

  @include mq($until: 'm') {
    @include margin(null auto);
    max-width: 560px;

    &::before {
      min-height: 520px;
    }
  }

  &__dots {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    fill: transparent;
    stroke-width: 2px;
    stroke: $brand-blue-light;
    stroke-dasharray: 3px 12px;
    stroke-linecap: round;

    @include mq($until: 'm') {
      top: 50%;
      left: 50%;
      min-width: 500px;
      min-height: 500px;
      transform: translate(-50%, -50%);
    }

    circle {
      r: calc(50% - 4px);
    }
  }

  &__button {
    @include position(absolute, 50% null null 50%);
    @include size(100%, auto);
    max-width: 87%;
    overflow: hidden;
    border-radius: 50%;
    background-color: $brand-blue-light;
    transform: translate(-50%, -50%);

    @include mq($until: 'm') {
      min-width: 420px;
    }

    &::before {
      @include size(100%, auto);
      @include padding(100% null null);
      content: '';
      display: block;
    }

    .no-touchevents &:hover,
    .no-touchevents &:focus {
      .module-video__play {
        .svg {
          fill: $brand-white;
        }
      }
    }
  }

  &__poster {
    @include position(absolute, 0 null null 0);
    @include size(100%);

    img {
      @include size(100%);
      @include object-fit(cover);
      display: block;
    }
  }

  &__play {
    @include position(absolute, 50% null null 50%);
    @include size(72px);
    @include margin(-36px null null -36px);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $brand-white;
    transition: background-color 0.3s $ease-out-quart;
    z-index: 3;

    .svg {
      transition: fil 0.3s $ease-out-quart;
    }
  }

  &--purple {
    .module-video__play {
      .svg {
        fill: $brand-ic-purple;
      }
    }

    .module-video__button {
      .no-touchevents &:hover,
      .no-touchevents &:focus {
        .module-video__play {
          background-color: $brand-ic-purple;
        }
      }
    }

    .module-video__poster:before,
    .module-video__poster:after {
      display: none;
    }
  }

  &--blue {
    .module-video__play {
      .svg {
        fill: $brand-blue;
      }
    }

    .module-video__button {
      .no-touchevents &:hover,
      .no-touchevents &:focus {
        .module-video__play {
          background-color: $brand-blue;
        }
      }
    }
  }
}
