[data-view="template-sso"] {
  background-color: #E5F7FE;

  &.template-sso--premium {
    background-color: #E5FBF6;
  }

  .g-recaptcha {
    margin-top: 32px;

    @include mq($until: 'm') {
      margin-top: 18px;
    }
  }

  .module-form__field-link {
    margin-top: auto;
    max-width: 50%;
    text-align: right;
    text-decoration: underline;
  }

  .module-form__footer {
    position: relative;
    z-index: 2;
  }

  .module-form__group--discount {
    margin-top: 32px;

    @include mq($until: 'm') {
      margin-top: 18px;
    }
  }

  .sso-discount-code {
    position: relative;

    &__button {
      @include padding(0);
      width: 100%;
      background: transparent;
      cursor: pointer;
      border: 0;
      display: block;

      label {
        display: flex;
        align-items: center;

        b {
          flex-shrink: 0;
        }
      }

      .svg--caret-down {
        margin-left: 8px;
        flex-shrink: 0;
        transition: transform .3s $ease-out-quad;
      }

      &[aria-expanded="false"] {
        label {
          margin-bottom: 0;
        }
      }

      &[aria-expanded="true"] {
        .svg--caret-down {
          transform: rotate(-180deg);
        }
      }
    }
    
    + .module-form__inner {
      &:not(.is-visible) {
        display: none;
      }
    }
  }

  .module-form__group--total {
    margin-top: 32px;

    @include mq($until: 'm') {
      margin-top: 18px;
    }

    .module-form__field {
      .module-form__checkbox-radio {
        @include mq($from: 'm') {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }
  }

  .sso-total {
    @include padding(36px 44px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $brand-white;
    border-radius: 12px;

    @include mq($until: 'm') {
      @include padding(26px 20px);
    }

    &__title {
      @include font('sans-serif', 'medium');
      font-size: rem(18px);
      line-height: (24 / 18);
      letter-spacing: -.5px;
      color: $brand-grey-darker;
      flex-shrink: 0
    }

    &__price {
      @include font('sans-serif', 'bold');
      font-size: rem(28px);
      letter-spacing: -.6px;
      line-height: (32 / 18);
      color: $brand-grey-darker;
      flex-shrink: 0;

      @include mq($until: 'm') {
        font-size: rem(22px);
      }
    }
  }

  .sso-success {
    position: relative;
    z-index: 2;
    flex-grow: 1;

    &__wrapper {
      @include padding(80px null);
      @include margin(null auto);
      max-width: 600px;
      text-align: center;

      @include mq($until: 'xs') {
        padding-top: 40px;
      }
    }

    &__icon {
      @include margin(null auto 36px);
      width: 180px;
      display: block;

      @include mq($until: 'xs') {
        width: 150px;
        margin-bottom: 20px;
      }
    }

    &__title {
      @include font('sans-serif', 'heavy');
      color: $brand-grey-darker;
      font-size: rem(60px);
      line-height: (58 / 60);
      letter-spacing: -1.3px;

      @include mq($until: 'm') {
        font-size: rem(48px);
        letter-spacing: -1px;
      }
    }

    &__text {
      margin-top: 36px;
      color: $brand-grey;
      font-size: rem(20px);
      letter-spacing: -.4px;
      line-height: (28 / 20);
    }

    .button {
      margin-top: 43px;
    }
  }
}
