.flatpickr-calendar {
  box-shadow: 0 10px 40px rgba($brand-grey-darker, 0.08);
}

.flatpickr-months {
  .flatpickr-prev-month,
  .flatpickr-next-month {
    svg {
      fill: $brand-blue-bright;
    }

    &:hover {
      svg {
        fill: $brand-purple-bright;
      }
    }
  }
}

.flatpickr-current-month {
  @include font('sans-serif', 'bold');
  color: $brand-grey-darker;
}

.flatpickr-day {
  @include font('sans-serif', 'medium');
  color: $brand-blue-bright;

  &.today {
    background-color: rgba($brand-blue-bright, 0.2);

    &:hover,
    &:focus {
      color: $brand-white;
      background-color: $brand-blue-bright;
    }
  }

  &.selected {
    color: $brand-white;
    background-color: $brand-blue-bright;

    &:hover,
    &:focus {
      color: $brand-white;
      background-color: $brand-blue-bright;
    }
  }

  &:hover,
  &:focus {
    color: $brand-white;
    background-color: $brand-blue-bright;
  }

  &.flatpickr-disabled {
    color: rgba($brand-blue-bright, 0.2);
  }
}

span.flatpickr-weekday {
  @include font('sans-serif', 'medium');
  color: $brand-grey-darker;
}
