.content-column {
  .content__line-segment {
    &--1 {
      min-height: 122px;
      max-height: 122px;
    }
  }

  .module-image__item--1:not(.module-image__item--skip-overlay) picture {
    position: relative;

    &::before,
    &::after {
      @include size(100%, 100%);
      @include position(absolute, 0 null null 0);
      content: '';
      opacity: .7;
      z-index: 2;
    }

    &::before {
      background-image: linear-gradient(198deg, rgba(0,181,247,0.00) 19%, #00B5F7 97%);
    }
    
    &::after {
      background-image: linear-gradient(202deg, rgba(51,150,255,0.00) 33%, #0A0870 96%);
    }
  }

  .module-video__poster {
    &::before,
    &::after {
      @include size(100%, 100%);
      @include position(absolute, 0 null null 0);
      content: '';
      z-index: 2;
    }
    
    &::before {
      background: rgba(0,181,247,0.60);
    }
    
    &::after {
      background-image: linear-gradient(198deg, rgba(0,103,226,0.00) 0%, #0A0870 98%);
      opacity: .6;
    }
  }

  .module-image {
    @include mq($until: 'm') {
      @include margin(null auto);
    }
    
    @include mq($until: 'xs') {
      @include margin(null -30px);
      width: calc(100% + 60px);
    }
  }

  &--mobile-reflect {
    .content__column {
      &:last-child {
        @include mq($until: 'm') {
          margin-bottom: 50px;
        }
      }
    }
  }

  html:not(.mobile):not(.ie) &.js-in-view {
    &:not(.in-view) {
      .module-image__item--1 {
        clip-path: circle(100px);
      }
    }
  }
}