.button-pill {
  @include font('sans-serif', 'heavy');

  &--blue {
    color: $brand-white;
    background-color: $brand-blue;

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-purple-bright, 0.3);
    }

    .no-touchevents &:focus,
    .no-touchevents &:hover {
      background-color: $brand-purple-bright;
    }
  }

  &--blue-light {
    color: $brand-blue;
    background-color: $brand-blue-light;

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-blue, 0.3);
    }

    .no-touchevents &:focus,
    .no-touchevents &:hover {
      color: $brand-white;
      background-color: $brand-blue;
    }
  }

  &--green-bright {
    color: $brand-grey-darker;
    background-color: $brand-green-bright;

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-green-bright, 0.4);
    }

    .no-touchevents &:hover,
    .no-touchevents &:focus {
      background-color: $brand-green;
    }
  }

  &--green-faded {
    color: $brand-green-darker;
    background-color: $brand-green;

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-green, 0.4);
    }

    .no-touchevents &:hover,
    .no-touchevents &:focus {
      color: $brand-grey-darker;
      background-color: $brand-green-bright;
    }
  }

  &--purple-faded {
    color: $brand-purple-bright;
    background-color: #EBDAF6;

    &::after {
      box-shadow: 0 0 0 3px rgba(#EBDAF6, 0.4);
    }

    .no-touchevents &:focus,
    .no-touchevents &:hover {
      color: #fff;
      background-color: $brand-purple-bright;
    }
  }

  &--purple-bright {
    color: $brand-white;
    background-color: $brand-purple-bright;

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-purple-bright, 0.4);
    }

    .no-touchevents &:focus,
    .no-touchevents &:hover {
      color: $brand-purple-bright;
      background-color: $brand-purple;
    }
  }

  &--white {
    color: $brand-purple-bright;
    background-color: $brand-white;

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-purple-bright, 0.3);
    }

    .no-touchevents &:focus,
    .no-touchevents &:hover {
      color: $brand-white;
      background-color: $brand-purple-bright;
    }
  }

  &--bordered {
    &.button-pill--purple-bright {
      color: $brand-purple-bright;
      border-color: $brand-purple-bright;
      background: transparent;

      &::after {
        box-shadow: 0 0 0 3px rgba($brand-purple-bright, 0.3);
      }

      .no-touchevents &:focus,
      .no-touchevents &:hover {
        color: $brand-white;
        border-color: transparent;
        background-color: $brand-purple-bright;
      }
    }
  }

  &[disabled] {
    color: #65769f;
    border-color: transparent;
    background-color: #dadee7;
  }
}
