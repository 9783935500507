.sso-header {
  @include size(100%, auto);
  background-color: $brand-white;

  &__bar {
    @include position(relative);
    padding-right: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid rgba($brand-grey-darker, .05);
    min-height: 123px;

    @include mq($until: 'xl') {
      padding-right: 35px;
      min-height: 98px;
      display: flex;
      justify-content: space-between;
    }

    @include mq($until: 'l') {
      padding-right: 15px;
    }

    @include mq($until: 'm') {
      min-height: 79px;
    }
  }

  &__exit {
    @include mq($until: 'l') {
      display: none;
    }
  }

  // &__exit-mobile {
  //   @include position(absolute, 0 0 null null);
  //   @include size(77px, 100%);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   @include mq($from: 'l') {
  //     display: none;
  //   }
    
  //   .svg {
  //     fill: $brand-grey-darker;
  //   }
  // }

  &__logo {
    @include padding(38px 36px 38px 35px);
    display: flex;
    align-items: center;
    border-right: 2px solid rgba($brand-grey-darker, .05);

    @include mq($until: 1280px) {
      @include padding(0 32px 0 30px);
    }

    @include mq($from: 'xl') {
      max-height: 122px;
    }

    @include mq($until: 'xl', $from: 'l') {
      max-height: 113px;
    }

    @include mq($until: 'l') {
      padding-right: 19px;
    }

    &-inhub {
      width: 134px;
      flex-shrink: 0;
      margin-bottom: -13px;

      @include mq($until: 1280px) {
        width: 98px;
      }

      &-text {
        fill: $brand-grey-darker;
      }
  
      &-dot {
        fill: $brand-blue;
      }
    }

    &-thf {
      width: 40px;
      margin-right: 4px;
      margin-bottom: 5px;
      flex-shrink: 0;

      @include mq($until: 1280px) {
        width: 29px;
        margin-bottom: 1px;
      }

      &-bg {
        fill: $brand-grey-darker;
      }
  
      &-text {
        fill: $brand-white;
      }
    }

    img {
      display: block;
      width: 220px;

      @include mq($until: 1280px) {
        width: 160px;
      }

      @include mq($until: 'm') {
        width: 140px;
      }
    }

    @include mq($until: 'xl') {
      position: relative;
      border: 0;
      z-index: 3;
    }
  }

  &__steps {
    @include font('sans-serif', 'heavy');
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(12px);
    line-height: 1;

    @include mq($until: 'l') {
      max-width: calc(100% - 180px);
      margin-left: auto;
    }

    @include mq($from: 'l') {
      @include position(absolute, 50% null null 50%);
      transform: translate(-50%, -50%);
    }

    &-line {
      content: '';
      display: inline-block;
      background-color: #D6EAFF;
      height: 2px;
      width: 70px;

      @include mq($until: 's') {
        width: 30px;
      }

      &--full {
        background-color: $brand-blue;
      }
    }

    &-item {
      @include position(relative);
      display: flex;
      align-items: center;
      justify-content: center;

      &-number {
        &::before {
          @include position(absolute, 50% null null 50%);
          content: '';
          transform: translate(-50%, -50%);
          border-radius: 50%;
        }
      }

      &:not(.sso-header__steps-item--disabled) {
        .sso-header__steps-item-number {
          @include position(relative);
          @include size(40px);
          z-index: 3;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $brand-blue;
          background-color: $brand-white;
          border-radius: 50%;

          @include mq($until: 'xs') {
            @include size(34px);
          }
  
          &::before {
            @include size(24px);
            border: 2px solid $brand-blue;
          }
        }

        + .sso-header__steps-line {
          width: 55px;

          @include mq($until: 's') {
            width: 30px;
          }
        }

        &:not(:last-child) {
          &::after {
            @include position(absolute, 50% null null 100%);
            @include size(26px, 2px);
            content: '';
            background-color: $brand-blue;
            transform: translateY(-50%);
            flex-shrink: 0;
            pointer-events: none;

            @include mq($until: 's') {
              width: 15px;
            }
          }
        }
      }

      &--disabled {
        .sso-header__steps-item-number {
          color: transparent;
          pointer-events: none;

          &::before {
            @include size(14px);
            border: 3px solid $brand-white;
            background-color: #D6EAFF;
          }
        }
      }
    }
  }
}

.sso-header-mobile {
  @include mq($until: 'l') {
    padding-top: 20px;
    margin-bottom: -20px;
  }

  @include mq($from: 'l') {
    display: none;
  }
  
}

.template-sso--premium {
  .sso-header__steps-item:not(.sso-header__steps-item--disabled) .sso-header__steps-item-number {
    color: $brand-green-darker;
    
    &::before {
      border-color: $brand-green-bright;
    }
  }

  .sso-header__steps-line,
  .sso-header__steps-item--disabled .sso-header__steps-item-number:before {
    background-color: rgba($brand-green-bright, .3);
  }

  .sso-header__steps-line--full,
  .sso-header__steps-item:not(.sso-header__steps-item--disabled):not(:last-child):after {
    background-color: $brand-green-bright;
  }
}