.tag {
  @include font('sans-serif', 'bold');

  &--blue {
    color: $brand-blue-bright;
    background-color: $brand-blue-light;
  }

  &--white {
    color: $brand-blue-bright;
    background-color: $brand-white;
  }

  &--purple-bright {
    color: $brand-purple-bright;
    background-color: $brand-purple;
  }

  &--green-bright {
    color: $brand-green-darker;
    background-color: $brand-green;
  }

  &--orange-bright {
    color: $brand-orange-bright;
    background-color: $brand-orange;
  }
}
