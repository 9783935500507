.module-dates {
  @include mq($until: 'm') {
    @include margin(null auto);
    max-width: 560px;
  }

  &__list {
    &-hidden {
      margin-top: -1px;

      .module-card-date {
        &:last-child {
          border-bottom: none;
        }
      }
    }

    .module-card-date {
      &:first-child {
        @include mq($until: 'm') {
          padding-top: 0;
        }
      }

      &:last-child {
        @include mq($until: 'm') {
          padding-bottom: 0;
        }
      }
    }

    &-expand {
      @include position(relative);
      @include padding(5px null);
      z-index: 1;
      text-align: center;

      &::before {
        @include position(absolute, null null 100% 0);
        @include size(100%, 290px);
        content: '';
        z-index: -1;
        pointer-events: none;
        background-image: linear-gradient(to top, rgba($brand-white, 1) 0%, rgba($brand-white, 0) 100%);
      }

      &.is-open {
        &::before {
          display: none;
        }
      }

      [aria-expanded="false"] {
        .module-dates__list-expand-expanded {
          display: none;
        }
      }

      [aria-expanded="true"] {
        .module-dates__list-expand-unexpanded {
          display: none;
        }

        .svg--caret-down {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__list-hidden {
    display: none;

    &.is-visible {
      display: block;
    }
  }
}
