.header--basic {
  padding-bottom: 70px;

  .center {
    &::after {
      @include size(100%, 1px);
      @include margin(80px null null);
      content: '';
      display: block;
      background-color: $brand-blue-light;
    }
  }
}
