.content-partners {
  padding-top: 80px;
  padding-bottom: 80px;

  .module-header {
    margin-bottom: 65px;

    &__text {
      max-width: gs(6, 5);
      margin-top: 28px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .module-partners {
    margin-right: gs(1, 1);
    margin-left: gs(1, 1);

    @include mq($until: 'm') {
      @include margin(null auto);
      max-width: 560px;
    }

    @include mq($from: 'xxl') {
      margin-right: gs(1, 1, 1392px, 12, 48px);
      margin-left: gs(1, 1, 1392px, 12, 48px);
    }
  }
}
