.footer-join {
  &__inner {
    background-color: $brand-green-bright;

    &::after {
      content: '';
      background-image: url('../assets/images/decorations/dot-pattern-1@2x.png');
      background-size: 38px auto;
    }
  }
}
