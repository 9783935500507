@charset 'UTF-8';

@mixin font-face($font-family, $file-path, $font-weight: 400, $font-style: normal, $file-formats: eot woff2 woff ttf svg) {
  @font-face {
    font-family: $font-family;
    font-style: $font-style;
    font-weight: $font-weight;

    src: font-source-declaration($font-family, $file-path, $file-formats);
  }
}
