.content-partners {
  &::after,
  &::before {
    @include position(absolute, null null null 50%);
    @include size(4px, 25px);
    @include margin(null null null -2px);
    content: '';
    border-radius: 2px;
    background-color: $brand-blue-light;
  }

  &::after {
    bottom: 0;
  }

  &::before {
    top: 0;
  }
}
