.module-card-activity {
  .module-card__inner {
    padding: 0;
  }

  &__image {
    @include aspect-ratio(260, 360);
    display: block;
    overflow: hidden;

    img {
      @include position(absolute, 0 null null 0);
      @include size(100%, 100%);
      @include object-fit(cover, center);
      display: block;
    }
  }
  
  &__tags {
    margin-bottom: 10px;
  }

  &__tag {
    margin-top: 8px;
    margin-right: 3px;
  }

  &__title {
    font-size: rem(22px);
    line-height: (26 / 22);
    letter-spacing: -0.6px;

    @include mq($until: 'm') {
      font-size: rem(20px);
      letter-spacing: -.5px;
    }
  }

  &__text {
    margin-top: 20px;
    margin-bottom: -20px;
    font-size: rem(16px);
    letter-spacing: -0.7px;

    @include mq($until: 'xl') {
      margin-top: 10px;
    }
    
    &:not(.is-shown) {
      max-height: 0;
      opacity: 0;
    }

    &.is-ready {
      transition: max-height .3s $ease-out-quad, opacity .3s $ease-out-quad, margin .3s $ease-out-quad;
    }
  }

  &__content {
    @include position(absolute, null null 0 0);
    @include size(100%, auto);
    @include padding(40px);
    z-index: 3;
  }

  .module-card__link {
    &:not(:hover) {
      ~ .module-card__inner {
        .module-card-activity__text:not(.is-shown) {
          max-height: 0 !important;
        }
      }
    }

    &:hover {
      ~ .module-card__inner {
        border-radius: 36px;
        
        .module-card-activity__text:not(.is-shown) {
          opacity: 1;
          max-height: 100%;
          margin-bottom: 0;
        }
      }
    }
  }
}
