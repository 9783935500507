.module-card-curriculum {
  &__title {
    @include font('sans-serif', 'bold');
    color: $brand-grey-darker;
  }

  &__from {
    @include font('sans-serif', 'medium');
    color: $brand-grey;

    a {
      color: $brand-blue-bright;

      .no-touchevents &:hover,
      .no-touchevents &:focus {
        color: $brand-purple-bright;
      }
    }
  }

  &__text {
    @include font('sans-serif', 'book');
    color: $brand-grey;
  }

  &__metas {
    border-color: $brand-blue-light;
    
    &-item {
      @include font('sans-serif', 'bold');
      color: $brand-grey-darker;
      fill: $brand-blue;
    }
  }

  &--is-curriculum {
    .module-card-curriculum__eyebrow {
      color: $brand-purple-bright;
      background-color: $brand-purple;
    }
  }
}
