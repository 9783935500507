.module-text {
  &__head {
    display: inline-flex;
    align-items: center;
    margin-bottom: 24px;

    .eyebrow + .tag {
      margin-left: 10px;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-size: rem(44px);
    line-height: 1;
    letter-spacing: -1px;

    @include mq($from: 'xxl') {
      font-size: rem(48px);
    }

    @include mq($until: 'm') {
      font-size: rem(36px);
    }
  }

  &__lead {
    font-size: rem(24px);
    line-height: (32 / 24);
    letter-spacing: -0.7px;
  }

  &__content {
    font-size: rem(18px);
    line-height: (28 / 18);
    letter-spacing: -0.6px;

    @include mq($until: 'm') {
      font-size: rem(16px);
      letter-spacing: -.4px;
    }
  }

  &__button {
    margin-top: 50px;
  }
}

sup{
    vertical-align:super;
    font-size:11px;
}