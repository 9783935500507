.circle {
  display: block;
  border-radius: 50%;
  pointer-events: none;
  backface-visibility: hidden;

  &::before {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    content: '';
    border-radius: 50%;
  }

  &--green:before {
    background-color: $brand-green;
  }

  &--yellow:before {
    background-color: $brand-yellow;
  }

  &--blue-lightest:before {
    background-color: $brand-blue-lightest;
  }
}

.circles {
  @include position(absolute, 0 null null 0);
  @include size(100%);
  z-index: -2;

  .circle {
    &::before {
      transform: scale(0);
      animation: 1.1s .7s $ease-out-expo forwards;

      @include animation-keyframes {
        from {
          transform: scale(0);
        }
        to {
          transform: scale(1);
        }
      }
    }
  }

  .circle--1 {
    @include position(absolute, -220px null null 50%);
    @include size(1160px);
    @include margin(null null null -580px);
  }

  .circle--2 {
    @include position(absolute, null null -2750px 50%);
    @include size(3280px);
    @include margin(null null null -1640px);

    @include mq($until: 'm') {
      display: none;
    }
  }

  .has-footer-join & {
    .circle--2 {
      bottom: -2400px;
    }
  }
}
