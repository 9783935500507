.sidebar-author {
  &__title {
    @include font('sans-serif', 'bold');
    color: $brand-grey-darker;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-grey;

    a {
      color: $brand-purple-bright;

      .no-touchevents &:hover,
      .no-touchevents &:focus {
        color: $brand-grey-darker;
      }
    }
  }
}
