.header-nav {
  @include position(absolute, 123px null null 0);
  @include size(100%, 74px);
  @include padding(null 30px);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid transparent;
  overflow: auto;

  @include mq($until: 'l') {
    height: 61px;
  }

  @include mq($until: 'm') {
    @include padding(null 44px);
  }
  
  @include mq($from: 'xl') {
    @include padding(null 50px null 35px);
  }

  @include mq($until: 1160px) {
    top: 113px;
  }

  @include mq($until: 'm') {
    top: 79px;
  }

  &.has-menu {
    padding-right: 0px;
    
    + .center {
      @include mq($until: 'l') {
        margin-top: 30px;
      }

      @include mq($from: 'l') {
        margin-top: 55px;
      }
    }
  }

  &__page-name {
    font-size: rem(14px);
    line-height: (20 / 14);
    letter-spacing: -0.4px;
  }
  
  &__menu {
    padding-right: 30px;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    @include mq($until: 'm') {
      padding-right: 44px;
    }

    @include mq($from: 'xl') {
      padding-right: 50px;
    }

    &:not(:first-child) {
      margin-left: 40px;
    }

    &-item {
      &:not(:first-child) {
        margin-left: 24px;
      }

      .svg {
        fill: currentColor;

        &:first-child {
          margin-right: 6px;
        }
        
        &:last-child {
          margin-left: 6px;
        }
      }

      a:not(.button) {
        display: flex;
        align-items: center;
        font-size: rem(14px);
        line-height: (22 / 14);
        letter-spacing: -0.35px;
        transition: color .3s $ease-out-quad;

        @include mq($until: 'm') {
          letter-spacing: -.2px;
        }
      }
    }
  }
}
