.module-card-date {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  padding-top: 35px;
  padding-bottom: 36px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  @include mq($until: 'm') {
    flex-direction: column;
  }

  &__metas {
    flex-basis: gs(2, 1);
    flex-shrink: 0;

    @include mq($until: 'm') {
      flex-basis: auto;
    }

    @include mq($from: 'xxl') {
      flex-basis: gs(2, 1, 1392px, 12, 48px);
    }
  }

  &__date {
    font-size: rem(16px);
    line-height: (24 / 16);
    letter-spacing: -0.2px;
  }

  &__hour {
    margin-top: 7px;
    font-size: rem(11px);
    line-height: (16 / 11);
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  &__content {
    flex: 1;
    margin-right: gs(0, 1);
    margin-left: gs(0, 1);

    @include mq($until: 'm') {
      @include margin(12px 0 null);
    }

    @include mq($from: 'xxl') {
      margin-right: gs(0, 1, 1392px, 12, 48px);
      margin-left: gs(0, 1, 1392px, 12, 48px);
    }
  }

  &__text {
    font-size: rem(16px);
    line-height: (24 / 16);
    letter-spacing: -0.5px;
  }

  &__caption {
    margin-top: 17px;
    font-size: rem(13px);
    line-height: (16 / 13);
    letter-spacing: -0.4px;
  }

  &__button {
    flex-basis: gs(2, 1);
    flex-shrink: 0;
    margin-top: -3px;
    text-align: right;

    @include mq($until: 'm') {
      margin-top: 14px;
      text-align: left;
      flex-basis: auto;
    }

    @include mq($from: 'xxl') {
      flex-basis: gs(2, 1, 1392px, 12, 48px);
    }
  }

  + .module-card-date {
    margin-top: -1px;
  }
}
