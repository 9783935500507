.header {
  &--line {
    &::after,
    &::before {
      content: '';
    }

    &::after {
      @include position(absolute, null null 0 50%);
      @include size(4px, 40px);
      @include margin(null null null -2px);
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
      background-color: $brand-blue-light;
    }

    &::before {
      @include position(absolute, null null 50px 50%);
      @include size(8px);
      @include margin(null null null -4px);
      border-radius: 50%;
      background-color: $brand-blue;
    }

    + .content {
      .content__line {
        &-segment {
          &--1 {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
          }
        }
      }

      &::before {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  .header-nav ~ .header__images {
    .module-image--80 .module-image__item--1 {
      @include mq($from: 'l') {
        margin-top: 0;
      }

      @include mq($until: 'l') {
        margin-top: -20px;
      }
    }
  }
}
