.module-table {
  &--desktop {
    .box {
      @include font('sans-serif', 'regular');
      color: $brand-grey-darker;
      fill: $brand-blue-bright;
    }
  
    thead {
      .box {
        @include font('sans-serif', 'bold');
  
        small {
          @include font('sans-serif', 'bold');
        }
      }

      th {

        &:first-child {
          border-bottom: 1px solid $brand-blue;
        }
  
        &:not(:first-child) {
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
          border-bottom: 1px solid $brand-blue;
        }
      }
    }

    tbody {
      td,
      th {
        &::after {
          content: '';
        }
      }
  
      th {
        &::after {
          @include position(absolute, null null 0 0);
          @include size(100%, 1px);
          background-color: $brand-blue-light;
        }
      }
  
      td {
        .box {
          color: $brand-blue-bright;
        }
  
        &::after {
          @include position(absolute, null 20px 0 20px);
          @include size(auto, 1px);
          background-color: rgba($brand-blue, 0.4);
        }
      }
  
      tr:last-child {
        th,
        td {
          &::after {
            content: none;
          }
        }
      }
    }
  
    tfoot {
      .box {
        @include font('sans-serif', 'bold');
        color: $brand-grey-darker;
      }
  
      th {
        border-top: 1px solid $brand-blue;
      }
  
      td {
        &:first-child {
          border-top: 1px solid $brand-blue;
        }
  
        &:not(:first-child) {
          border-top: 1px solid $brand-blue;
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }
    }
  
    th,
    td {
      &:not(:first-child) {
        background: $brand-blue-lighter;
      }
    }
  }

  &--mobile {
    fill: $brand-blue-bright;
    
    .module-table__mobile-head {
      &-button {
        @include font('sans-serif', 'bold');
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        background-color: $brand-blue-lighter;
        color: $brand-grey-darker;

        &.is-active {
          color: $brand-white;
          background-color: $brand-blue-bright;
        }
      }
    }

    td {
      color: $brand-grey-darker;
      border-bottom: 1px solid $brand-blue-light;
    }

    tbody {
      td {
        &:not(:first-child) {
          color: $brand-blue-bright;
        }
      }
    }

    tfoot {
      td {
        @include font('sans-serif', 'bold');
        border-top: 2px solid $brand-blue;
        color: $brand-grey-darker;
      }
    }
  }

  small {
    @include font('sans-serif', 'regular');
    color: $brand-grey;
  }

  &__button {
    @include font('sans-serif', 'heavy');
    border-radius: 8px;
    background-color: $brand-blue-bright;
    color: $brand-white;

    .no-touchevents &:focus,
    .no-touchevents &:hover {
      background-color: rgba($brand-blue, .2);
      color: $brand-blue-bright;
    }
  }
}
