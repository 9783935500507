.button {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0 none;
  cursor: pointer;
  border-radius: 0;
  background: none;
  text-align: left;
  text-decoration: none;

  &[disabled] {
    pointer-events: none;
  }
}
