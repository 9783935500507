.module-card-topic {
  .module-card__inner {
    padding-top: 0;
    padding-bottom: 50px;
  }

  &__title {
    @include position(relative);
    @include margin(null null 18px);
    font-size: rem(20px);
    letter-spacing: -0.6px;
    line-height: (24 / 20);
  }

  &__text {
    font-size: rem(16px);
    line-height: (22 / 16);
    letter-spacing: -0.5px;
  }

  &__icon {
    @include size(56px);
    @include position(relative);
    @include margin(-79px null 23px -16px);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $brand-white;

    img {
      width: 24px;
    }
  }

  &__image {
    @include size(calc(100% + 80px), auto);
    @include aspect-ratio(360, 240);
    @include margin(null null 45px -40px);
    display: block;

    img {
      @include position(absolute, 0 null null 0);
      @include size(100%, 100%);
      @include object-fit(cover);
      display: block;
    }
  }
}
