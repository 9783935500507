.module-tabs {
  @include mq($from: 'xl') {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: gs(0, 1);
    padding-left: gs(0, 1);
  }
  
  @include mq($from: 'xxl') {
    padding-right: gs(0, 1, 1392px, 12, 48px);
    padding-left: gs(0, 1, 1392px, 12, 48px);
  }
  
  &__list {
    display: flex;
    flex: 1;
    flex-basis: gs(7, 6);
    align-items: center;
    justify-content: space-between;
    overflow: auto;
    
    @include mq($from: 'xxl') {
      flex-basis: gs(7, 6, 1392px, 12, 48px);
    }

    @include mq($until: 'm') {
      flex-basis: auto;
      width: calc(100% + 44px);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    li {
      text-align: center;
      flex-shrink: 0;
      
      @include mq($until: 'xl') {
        + li {
          margin-left: 20px;
        }
      }

      &:first-child {
        @include mq($until: 'xl') {
          padding-left: gs(0, 1);
        }

        @include mq($until: 'm') {
          padding-left: 25px;
        }
        
        @include mq($until: 'xl') {
        }

        @include mq($from: 'xxl') {
          padding-left: gs(0, 1, 1392px, 12, 48px);
        }
      }

      &:last-child {
        @include mq($until: 'xl') {
          padding-right: gs(0, 1);
        }

        @include mq($until: 'm') {
          padding-right: 25px;
          position: relative;
          padding-right: 94px;

          .button {
            position: relative;
            z-index: 2;
            white-space: nowrap;
          }

          &::before,
          &::after {
            @include position(absolute, 0 null null null);
            content: '';
            height: 100%;
          }

          &::before {
            right: 44px;
            width: 25px;
            z-index: 1;
          }

          &::after {
            right: 0;
            width: calc(44px + 10px);
            z-index: 0;
          }
        }

        @include mq($from: 'xxl') {
          padding-right: 0;
        }
        
      }
    }

    button {
      @include position(relative);
      @include size(100%, auto);
      @include padding(27px null 29px);
      display: block;
      width: 120px;

      @include mq($until: 'xl') {
        width: 100px;
      }

      @include mq($until: 'm') {
        padding-top: 29px;
      }

      &::after {
        @include position(absolute, null null 0 0);
        @include size(100%, 4px);
        content: '';
        opacity: 0;
        transition: opacity 0.3s $ease-out-quart;
      }

      .no-touchevents &:hover,
      .no-touchevents &:focus,
      &.is-active {
        &::after {
          opacity: 1;
        }
      }
    }

    &-day,
    &-date {
      display: block;
      text-align: center;
      transition: color 0.3s $ease-out-quart;
    }

    &-day {
      margin-bottom: 6px;
      font-size: rem(10px);
      line-height: (14 / 10);
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    &-date {
      font-size: rem(18px);
      line-height: (24 / 18);
      letter-spacing: -0.2px;
      
      @include mq($until: 'm') {
        font-size: rem(16px);
      }
    }
  }

  &__button {
    flex-basis: gs(3, 2);
    flex-shrink: 0;
    text-align: right;

    @include mq($until: 'xl') {
      margin-top: 40px;
      text-align: center;
    }

    @include mq($from: 'xxl') {
      flex-basis: gs(3, 2, 1392px, 12, 48px);
      padding-right: gs(0, 1, 1392px, 12, 48px);
    }
  }
}
