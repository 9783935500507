.site-header {
  @include position(absolute, 0 null null 0);
  @include size(100%, auto);
  z-index: 900;

  @include mq($until: 'xl') {
    transition: transform .3s $ease-out-quad;

    body.header-sticky & {
      position: fixed;
      top: -117px;
    }

    body.menu-is-open & {
      position: fixed;
    }

    body.header-sticky:not(.header-is-hidden) &,
    body.header-sticky.menu-is-open & {
      transform: translateY(100%);
    }
  }

  @include mq($until: 'm') {
    body.header-sticky & {
      position: fixed;
      top: -81px;
    }
  }
  
  &__bar {
    @include position(relative);
    padding-right: 50px;
    display: flex;
    border-bottom: 2px solid transparent;

    @include mq($until: 'xl') {
      padding-right: 0;
      min-height: 98px;
      display: flex;
      justify-content: space-between;

      &::before {
        @include position(absolute, 0 null null 0);
        @include size(100%, 100%);
        content: '';
        background-color: $brand-white;
        z-index: 2;
      }
      
      body.header-sticky &,
      body.menu-is-open & {
        background-color: $brand-white;
      }
    }

    @include mq($until: 'm') {
      min-height: 79px;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    border-right: 2px solid transparent;

    @include mq($from: 'xl') {
      max-height: 122px;
    }

    @include mq($until: 'xl', $from: 'l') {
      max-height: 113px;
    }

    img {
      display: block;
      width: 220px;

      @include mq($until: 1280px) {
        width: 160px;
      }

      @include mq($until: 'm') {
        width: 140px;
      }
    }

    @include mq($until: 'xl') {
      position: relative;
      border: 0;
      z-index: 3;

      body.submenu-is-open & {
        visibility: hidden;
      }
    }
  }

  &__menu-button {
    @include padding(0);
    @include size(113px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 0;
    border-left: 2px solid transparent;

    @include mq($from: 'xl') {
      display: none;
    }

    @include mq($until: 'xl') {
      position: relative;
      z-index: 3;
    }

    @include mq($until: 'm') {
      @include size(79px);
    }

    &-bars {
      @include size(17px, 17px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-bar {
      @include size(100%, 3px);
      display: block;
      flex-shrink: 0;
      border-radius: 1.5px;
      transition: transform .3s $ease-out-quad, opacity .2s $ease-out-quad;

      &:nth-child(1) {
        transform-origin: left center;
      }

      &:nth-child(3) {
        transform-origin: left center;
      }

      body.menu-is-open & {
        &:nth-child(1) {
          transform: translate(2px, 1px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translate(2px, -1px) rotate(-45deg);
        }
      }
    }
  }

  &__desktop-submenu-backdrop {
    @include position(absolute, null null 0 0);
    @include size(100%, 1000px);
    content: '';
    z-index: -1;
    backface-visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: transform .3s $ease-out-quad, opacity .3s $ease-out-quad;

    @include mq($until: 'xl') {
      display: none;
    }

    body.submenu-is-open & {
      opacity: 1;
    }
  }

  &__submenu-close-button {
    @include position(absolute, 0 null null 30px);
    @include padding(0);
    @include size(auto, 100%);
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    visibility: hidden;
    z-index: 3;

    @include mq($until: 'xl') {
      body.submenu-is-open & {
        visibility: visible;
      }
    }

    .svg {
      fill: currentColor;
      margin-right: 5px;
    }
  }

  &__nav {
    @include padding(15px null null 40px);
    width: 100%;

    @include mq($until: 'xl') {
      @include position(fixed, 0 null null 0);
      @include padding(113px 30px null 30px);
      width: 100%;
      height: 100vh; /* Fallback */
      height: calc(var(--vh, 1vh) * 100);
      visibility: hidden;
      opacity: 0;
      overflow: auto;
      transition: opacity .3s $ease-out-quad, visibility .3s $ease-out-quad;

      body.menu-is-open & {
        visibility: visible;
        opacity: 1;
      }
    }

    @include mq($until: 'm') {
      @include padding(79px null null null);
    }

    &-wrapper {
      @include mq($until: 'xl') {
        @include padding(40px null 40px null);
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
  
        body.menu-is-open & {
          visibility: visible;
        }
      }
    }
    
    &-top,
    &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &-bottom {
      @include mq($until: 'xl') {
        padding-bottom: 34px;
      }
    }

    &-top {
      margin-bottom: 23px;

      @include mq($until: 'xl') {
        flex-direction: column-reverse;
      }

      
    }
  }

  &__dropdown {
    .dropdown__button {
      @include position(relative);
      @include padding(7px 14px);
      @include font('sans-serif', 'bold');
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-shrink: 0;
      min-width: 200px;
      border: 1px solid transparent;
      border-radius: 8px;
      font-size: rem(12px);
      line-height: (16 / 12);
      letter-spacing: .4px;

      @include mq($until: 's') {
        width: 100%;
        background-color: $brand-white;
      }
      
      .svg {
        flex-shrink: 0;
        fill: currentColor;

        &.svg--caret-down {
          margin-left: auto;
        }
      }
      
      .svg:not(.svg--caret-down) {
        margin-right: 10px;
      }
    }
  }

  &__secondary-nav,
  &__primary-nav {
    display: flex;
    align-items: center;

    @include mq($until: 'xl') {
      width: 100%;
    }
  }

  &__secondary-nav {
    &:first-child {      
      @include mq($until: 'xl') {
        flex-direction: column;
        padding-top: 46px;
      }

      .site-header__secondary-nav-item:not(:first-child) {
        @include mq($until: 'xl') {
          margin-top: 18px;
        }

        .site-header__secondary-nav-item-button {
          @include mq($until: 'xl') {
            @include position(relative);
            @include padding(14px 16px 14px 38px);
            flex-shrink: 0;
            border: 1px solid transparent;
            border-radius: 8px;
            background-color: $brand-white;

            @include mq($until: 's') {
              background: transparent;
              border: 0 !important;
            }
          }
  
          .svg--blank {
            @include mq($from: 'xl') {
              display: none;
            }
  
            @include position(absolute, 16px null null 13px);
          }
        }
      }
    }
    
    &:last-child {
      @include mq($until: 'xl') {
        flex-direction: column-reverse;
        padding-bottom: 40px;
        border-bottom: 1px solid transparent;
      }

      .site-header__secondary-nav-item {
        @include mq($until: 's') {
          &-button,
          .button {
            text-align: center;
            justify-content: center;
          }
        }

        &:not(:last-child) {
          @include mq($until: 'xl') {
            margin-top: 18px;
          }
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;

      @include mq($until: 's') {
        width: 100%;
        text-align: center;
        justify-content: center;

        .button {
          width: 100%;
        }
      }

      &:not(:last-child) {
        @include mq($from: 'xl') {
          margin-right: 30px;
        }
      }

      &-select {
        @include padding(7px 10px);
        border-radius: 8px;
        display: flex;
        align-items: center;
        transition: color .3s $ease-out-quad, border-color .3s $ease-out-quad;

        .svg {
          fill: currentColor;
          margin-right: 8px;
        }
      }

      &-button {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        transition: color .3s $ease-out-quad;

        .svg {
          margin-right: 8px;
          fill: currentColor;
        }

        & + .site-header__secondary-nav-item-button {
          margin-left: 5px;
        }
      }
    }
  }

  &__primary-nav {
    @include mq($until: 'xl') {
      flex-direction: column;
      align-items: flex-start;
    }
    
    &-item {
      @include mq($until: 'xl') {
        width: 100%;
      }

      &:not(:last-child) {
        .site-header__primary-nav-item-button {
          padding-right: 12px;

          @include mq($until: 'xl') {
            padding-right: 0;
          }
        }
        
        + .site-header__primary-nav-item .site-header__primary-nav-item-button {
          padding-left: 12px;

          @include mq($until: 'xl') {
            padding-left: 0;
          }
        }
      }
      
      &:first-child {
        .site-header__primary-nav-item-button::before {
          left: 0;
          width: calc(100% - 12px);
        }
      }

      &:last-child {
        .site-header__primary-nav-item-button::before {
          width: calc(100% - 12px);
        }
      }
      
      &-button {
        @include position(relative);
        @include padding(0 0 0 0);
        display: flex;
        align-items: center;
        flex-shrink: 0;
        border: 0;
        background: transparent;
        z-index: 2;
        text-align: left;

        @include mq($until: 'xl') {
          width: 100%;
        }

        // Mouse navigation improvement
        &::before,
        &:after {
          @include size(100px);
          @include position(absolute, 18px null null null);
          content: '';
          transform: rotate(45deg);
          pointer-events: none;
          z-index: 5;

          @include mq($until: 'xl') {
            display: none;
          }
        }

        &::before {
          left: -65px;
          transform: rotate(-30deg);
        }

        &::after {
          right: -65px;
          transform: rotate(30deg);
        }

        &-label {
          @include position(relative);
          display: flex;
          align-items: center;
          padding-bottom: 20px;

          @include mq($until: 'xl') {
            padding-bottom: 0;
          }
          
          &::before {
            @include size(100%, 4px);
            @include position(absolute, null null 0 0);
            content: '';
            pointer-events: none;
            opacity: 0;
            transition: opacity .3s $ease-out-quad;
  
            @include mq($until: 'xl') {
              display: none;
            }
          }
        }


        .svg {
          margin-left: 8px;
        }

        .svg--caret-down {
          @include mq($until: 'xl') {
            display: none;
          }
        }

        .svg--arrow-right {
          @include size(32px);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: $brand-white;

          @include mq($from: 'xl') {
            display: none;
          }
        }

        &:not(button) {
          .no-touchevents &:focus,
          .no-touchevents &:hover {
            .site-header__primary-nav-item-button-label::before {
              opacity: 1;
            }
          }
        }
      }

      &.is-current {
        .site-header__primary-nav-item-button-label::before {
          opacity: 1;
        }
      }

      &.submenu-is-open {
        .site-header__submenu {
          @include mq($from: 'xl') {
            opacity: 1;
            visibility: visible;
            transition: visibility .3s .15s $ease-out-quad, opacity .3s .15s $ease-out-quad;
          }

          @include mq($until: 'xl') {
            body.menu-is-open & {
              visibility: visible;
              transform: translateX(-100%);
            }
          }
        }

        .site-header__primary-nav-item-button {
          @include mq($from: 'xl') {
            z-index: 4;
  
            &::before,
            &:after {
              pointer-events: auto;
              z-index: 6;
            }
          }

          .site-header__primary-nav-item-button-label::before {
            opacity: 1;
          }
        }
      }
    }
  }

  &__submenu {
    @include position(absolute, calc(100% - 1px) null null 0);
    @include size(100%, auto);
    visibility: hidden;
    opacity: 0;
    transition: visibility .3s $ease-out-quad, opacity .3s $ease-out-quad;

    @include mq($until: 'xl') {
      @include position(fixed, 0 null null 100%);
      @include padding(113px null null null);
      height: 100vh; /* Fallback */
      height: calc(var(--vh, 1vh) * 100);
      z-index: 3;
      opacity: 1;
      transition: transform .3s $ease-out-quad, visibility .3s $ease-out-quad;
      overflow: auto;
    }

    @include mq($until: 'm') {
      @include padding(79px null null null);
    }

    &-wrapper {
      @include margin(null auto);
      @include padding(65px null 55px null);
      display: flex;
      max-width: 960px;

      @include mq($until: 'xl') {
        @include padding(55px 30px);
        max-width: 100%;
        flex-direction: column;
      }
    }

    &-content {
      width: 100%;
      max-width: 300px;
      
      @include mq($from: 'xl') {
        margin-right: 100px;
      }

      @include mq($until: 'xl') {
        padding-bottom: 13px;
      }

      @include mq($until: 'm') {
        max-width: 560px;
      }

      &-text {
        @include margin(14px null null null);
      }

      .button {
        margin-top: 18px;
      }
    }

    &-nav {
      width: 100%;
      max-width: 260px;
      margin-top: -17px;

      @include mq($until: 'xl') {
        margin-top: 40px;
      }

      @include mq($until: 'm') {
        max-width: 100%;
      }

      &:not(:last-child) {
        @include mq($from: 'xl') {
          margin-right: 40px;
        }
      }

      &-item {
        display: inline-block;
        width: 100%;

        &:not(:last-child) {
          border-bottom: 1px solid transparent;
        }

        &-label {
          @include padding(11px null);
          @include font('sans-serif', 'bold');
          display: block;
          font-size: rem(10px);
          line-height: (14 / 10);
          letter-spacing: 2px;
          text-transform: uppercase;
        }

        &-button {
          @include padding(17px null);
          @include font('sans-serif', 'bold');
          display: block;
          font-size: rem(16px);
          line-height: (22 / 16);
          letter-spacing: -.4px;
          transition: color .3s $ease-out-quad;
        }
      }

      &--single {
        max-width: 560px;
        column-count: 2;
        column-gap: 40px;

        @include mq($until: 's') {
          column-count: 1;
        }
      }
    }
  }

  .desktop-only {
    @include mq($until: 'xl') {
      display: none;
    }
  }

  &--minimal {
    background-color: $brand-white;

    .site-header__bar {
      padding-right: 0;
    }

    .site-header__nav {
      padding-top: 45px;
      padding-right: 48px;
      padding-bottom: 44px;
      text-align: right;
    }
  }
}
