.button-icon {
  @include font('sans-serif', 'bold');

  &--blue {
    color: $brand-blue-bright;
    
    &::before {
      background-color: #D6EAFF;
    }

    .svg {
      fill: $brand-blue-bright;
      background-color: $brand-white;
    }
  }

  &--blue-bright {
    color: $brand-white;
    
    &::before {
      background-color: $brand-blue-bright;
    }

    .svg {
      fill: $brand-blue-bright;
      background-color: $brand-white;
    }
  }

  &--purple,
  &--purple-bright {
    .svg {
      fill: $brand-purple-bright;
      background-color: $brand-white;
    }
  }

  &--purple {
    color: $brand-purple-bright;

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-purple-bright, 0.4);
    }
    
    &::before {
      background-color: $brand-purple;
    }
  }

  &--purple-bright {
    color: $brand-white;

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-purple-bright, 0.3);
    }

    &::before {
      background-color: $brand-purple-bright;
    }
  }

  &--orange-bright {
    color: $brand-white;
    
    .svg {
      fill: $brand-orange-bright;
      background-color: $brand-white;
    }

    &::before {
      background-color: $brand-orange-bright;
    }

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-orange-bright, 0.3);
    }
  }

  &--green-faded {
    color: $brand-green-darker;
    
    &::before {
      background-color: $brand-green;
    }

    .svg {
      fill: $brand-green-darker;
      background-color: $brand-white;
    }
  }

  &--green-bright {
    color: $brand-grey-darker;

    .svg {
      fill: $brand-grey-darker;
      background-color: $brand-white;
    }

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-green-bright, 0.3);
    }
    
    &::before {
      background-color: $brand-green-bright;
    }
  }

  &--white {
    color: $brand-purple-bright;
    
    
    .svg {
      fill: $brand-white;
      background-color: $brand-purple-bright;
    }

    &::before {
      background-color: $brand-white;
    }

    &::after {
      box-shadow: 0 0 0 3px rgba($brand-white, 0.6);
    }
  }

  &[disabled] {
    color: #65769F;
    
    &::before {
      background-color: #dadee7;
    }

    .svg {
      fill: #65769F;
      background-color: $brand-white;
    }
  }
}
