.module-card-activity {
  .module-card__dot {
    background-color: $brand-white;
  }
  
  .module-card__link {
    .no-touchevents &:focus,
    .no-touchevents &:hover {
      ~ .module-card__inner {
        .module-card-activity__image::after {
          opacity: 0;
        }
      }
    }
  }

  .module-card__inner {
    &::before {
      content: none;
    }
  }

  .module-card__dot {
    &::before {
      background-image: image('decorations/card-small-arrow-white@2x.png');
    }
  }

  &__image {
    border-radius: 24px;

    &::after {
      @include position(absolute, 0 null null 0);
      @include size(100%, 100%);
      content: '';
      z-index: 1;
      pointer-events: none;
      background-image: linear-gradient(198deg, rgba(6, 41, 78, 0.00) 28%, #06294E 82%);
      transition: opacity .3s $ease-out-quad;
    }
  }

  &__title {
    @include font('sans-serif', 'bold');
    color: $brand-white;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-white;
  }

  &__content {
    color: $brand-white;
  }
}
