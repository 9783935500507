.site-footer {
  background-color: $brand-grey-darker;

  // &::before {
  //   @include size(803px);
  //   @include position(absolute, null null -537px 50%);
  //   content: '';
  //   border-radius: 50%;
  //   border: 4px solid rgba($brand-grey, .2);
  //   transform: translateX(-50%) translateX(580px);
  // }

  &__primary-nav {
    &-item {
      &-button {
        @include font('sans-serif', 'medium');
        font-size: rem(20px);
        line-height: (24 / 20);
        letter-spacing: -.2px;

        @include mq($from: 'xxl') {
          font-size: rem(22px);
        }

        .no-touchevents &:hover,
        .no-touchevents &:focus {
          color: $brand-blue;
        }
      }
    }
  }

  &__secondary-nav {
    &-item {
      &-button {
        .no-touchevents &:hover,
        .no-touchevents &:focus {
          color: $brand-blue;
        }
      }
    }
  }

  &__text {
    color: $brand-blue;
  }

  &__metas {
    display: flex;
    align-items: center;

    &-item,
    &-item-button {
      color: $brand-blue;
    }

    &-item-button {
      .no-touchevents &:hover,
      .no-touchevents &:focus {
        color: $brand-blue;
      }
    }
  }

  .eyebrow {
    color: $brand-blue;
  }

  &__column {
    &-toggler {
      @include mq($until: 's') {
        color: $brand-blue;
      }
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      border-color: rgba(#4D5A7C, .4);
    }
  }

  &__dropdown {
    .dropdown__button {
      @include font('sans-serif', 'heavy');
      color: $brand-white;
      font-size: rem(10px);
      line-height: (16 / 10);
      letter-spacing: 1.5px;
      background: $brand-blue-bright;
    }
  }
}
