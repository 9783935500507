.module-header {
  &__title {
    @include font('sans-serif', 'heavy');
    color: $brand-grey-darker;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-grey;

    a {
      color: $brand-blue-bright;
    }
  }

  &__caption {
    @include font('sans-serif', 'regular');
    color: $brand-grey;
  }

  &--s {
    .module-header__title {
      @include font('sans-serif', 'bold');
    }
  }
}
