.header-nav {
  &.has-menu {
    border-color: rgba($brand-grey-darker, .05);
  }

  &__page-name {
    @include font('sans-serif', 'bold');
    color: $brand-grey-darker;
  }

  &__menu {
    &-item {
      a:not(.button) {
        @include font('sans-serif', 'medium');
        color: $brand-purple-bright;

        .no-touchevents &:hover,
        .no-touchevents &:focus {
          color: $brand-blue-bright;
        }
      }
    }
  }
}
