.widget-video {
  @include size(100%, auto);
  @include aspect-ratio(16, 9);
  overflow: hidden;

  &__embed,
  &__poster {
    @include position(absolute, 0 null null 0);
    @include size(100%);
  }

  &__play {
    @include position(absolute, 50% null null 50%);
    @include size(72px);
    @include margin(-36px null null -36px);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $brand-white;
    transition: background-color 0.3s $ease-out-quart;

    @include mq($until: 'm') {
      @include size(56px);
      @include margin(null);
      @include margin(-28px null null -28px);
    }
    
    .svg {
      transition: fill 0.3s $ease-out-quart;
    }
  }

  &__poster {
    img,
    picture {
      display: block;
      width: 100%;
      height: 100%;
    }

    img {
      @include object-fit(cover);
    }

    .button-circle {
      @include position(absolute, 50% null null 50%);
      @include margin(-36px null null -36px);
    }

    .no-touchevents &:hover,
    .no-touchevents &:focus {
      .svg {
        fill: $brand-white;
      }
    }
  }
}
