.header-inner {
  &__title {
    @include font('sans-serif', 'heavy');
    color: $brand-grey-darker;

    b,
    strong {
      color: $brand-blue;
    }
  }

  &__text,
  &__metas {
    color: $brand-grey;
  }
  
  &__text {
    @include font('sans-serif', 'book');
  }

  &__metas {
    @include font('sans-serif', 'medium');

    li {
      &::after {
        background-color: rgba($brand-grey, 0.5);
      }
    }
  }
}
