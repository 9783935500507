.button-back {
  display: inline-flex;
  align-items: center;
  font-size: rem(14px);
  line-height: (20 / 14);
  letter-spacing: -0.4px;

  span {
    + span {
      margin-left: 10px;
    }
  }

  .svg {
    fill: currentColor;
    margin-top: 1px;
    transition: transform 0.3s $ease-out-quart;
  }

  .no-touchevents &:hover,
  .no-touchevents &:focus {
    .svg {
      transform: translateX(-5px);
    }
  }
}
