.module-header {
  &__image,
  &__content {
    position: relative;
  }

  &__images {
    @include mq($until: 'm') {
      @include margin(null auto 30px);
      width: 260px;
    }
  }

  &__content {
    z-index: 1;
  }

  &__eyebrow {
    margin-bottom: 20px;
  }

  &__title {
    font-size: rem(44px);
    line-height: 1;
    letter-spacing: -.8px;

    @include mq($from: 'xxl') {
      font-size: rem(48px);
      letter-spacing: -1px;
    }

    @include mq($until: 'm') {
      font-size: rem(40px);
    }

    @include mq($until: 's') {
      font-size: rem(36px);
    }
  }

  &__text {
    margin-top: 32px;
    font-size: rem(18px);
    line-height: (28 / 18);
    letter-spacing: -0.6px;

    @include mq($until: 'm') {
      font-size: rem(16px);
    }

    a {
      text-decoration: underline;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 46px;

    .button:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__caption {
    max-width: 240px;
    margin-top: 17px;
    margin-right: auto;
    margin-left: auto;
    font-size: rem(13px);
    line-height: (20 / 13);
    letter-spacing: -0.41px;
  }

  &--s {
    .module-header__title {
      font-size: rem(36px);
      line-height: (40 / 36);
      letter-spacing: -1px;

      @include mq($until: 'm') {
        font-size: rem(28px);
        letter-spacing: -.6px;
      }
    }
  }

  &--m,
  &--l {
    .module-header__text {
      @include mq($from: 'm') {
        font-size: rem(20px);
        line-height: (28 / 20);
        letter-spacing: -0.4px;
      }
    }
  }

  &--l {
    .module-header__title {
      font-size: rem(60px);
      line-height: (58 / 60);
      letter-spacing: -1.3px;
    }
  }
}

html:not(.mobile):not(.ie) .js-in-view {
  .module-header {
    .module-header__eyebrow,
    .module-header__text,
    .module-header__caption,
    .button-line {
      transform: translateY(20px);
      opacity: 0;
      transition: transform .4s .5s $ease-out-quad, opacity .4s .5s $ease-out-quad;
    }
    
    .button-icon {
      &,
      .button-icon__label,
      &::before,
      .svg--arrow-right {
        transition-delay: .6s;
      }
    }

    .module-header__eyebrow {
      transition-delay: .1s;
    }
    
    .module-header__caption,
    .button-line {
      transition-delay: .7s;
    }
  }

  &.in-view {
    .module-header {
      .module-header__eyebrow,
      .module-header__text,
      .module-header__caption,
      .button-line {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  &:not(.in-view) {
    .module-header {
      .module-image:first-child:last-child {
        .module-image__item--1 {
          clip-path: circle(100px);
        }
      }
    }
  }
}
