.module-filters-bar {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid transparent;

  @include mq($until: 'm') {
    flex-direction: column-reverse;
    border-bottom: 0;
    align-items: flex-start;
  }

  &__tags,
  &__sorting {
    display: flex;
    align-items: flex-start;
  }

  &__text,
  &__sorting-select {
    font-size: rem(15px);
    line-height: (20 / 15);
    letter-spacing: -.8px;
  }

  &__tags {
    width: 100%;
    margin-top: -16px;
    padding-right: 30px;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    
    @include mq($until: 'm') {
      padding-top: 24px;
      border-top: 1px solid transparent;
      margin-top: 15px;
    }

    &-item {
      @include padding(10px 14px);
      @include margin(16px 16px null null);
      border: 1px solid transparent;
      border-radius: 12px;
      font-size: rem(15px);
      line-height: (18 / 15);
      letter-spacing: -0.3px;

      .svg {
        margin-left: 6px;
        transition: transform .3s $ease-out-quad;
      }
      
      .no-touchevents &:hover,
      .no-touchevents &:focus {
        .svg {
          transform: rotate(90deg);
        }
      }
    }

    .module-filters-bar__text {
      margin-top: 16px;

      @include mq($until: 'm') {
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &__sorting {
    @include position(relative);
    flex-shrink: 0;
    
    &-select {
      @include padding(10px 35px 10px 11px);
      appearance: none;
      border: 1px solid transparent;
      border-radius: 8px;
      margin-left: 10px;
      transition: box-shadow .3s $ease-out-quad;
      cursor: pointer;

      .no-touchevents &:hover,
      .no-touchevents &:focus {
        box-shadow: 0 3px 12px 0 rgba(6,41,78,0.08);
      }
    }

    .svg--caret-down {
      @include position(absolute, 19px 17px null null);
    }

    .module-filters-bar__text {
      margin-top: 10px;
    }
  }
}
