.module-text {
  &__title {
    @include font('sans-serif', 'heavy');
    color: $brand-grey-darker;
  }

  &__lead {
    @include font('sans-serif', 'medium');
    color: $brand-grey-darker;
  }

  &__content {
    @include font('sans-serif', 'regular');
    color: $brand-grey;
  }
}
