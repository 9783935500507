.module-card-curriculum {
  .module-card__inner {
    @include position(relative);
    @include padding(41px null);
    z-index: 5;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    
    @include mq($from: 'm') {
      min-height: 440px;
    }

    a {
      pointer-events: auto;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 13px;

    .tag:not(:first-child) {
      margin-left: 8px;
    }
  }

  &__title {
    margin-bottom: 23px;
    font-size: rem(24px);
    letter-spacing: -0.5px;
    line-height: (28 / 24);
  }

  &__from {
    margin-bottom: 23px;
    font-size: rem(13px);
    line-height: (20 / 13);
    letter-spacing: -0.4px;

    a {
      position: relative;
      z-index: 3;
      text-decoration: underline;
      transition: color 0.3s $ease-out-quart;
    }
  }

  &__text {
    font-size: rem(16px);
    line-height: (24 / 16);
    letter-spacing: -0.5px;
    margin-bottom: 20px;
  }

  &__metas {
    margin-top: auto;
    border-top: 1px solid transparent;
    padding-top: 15px;

    &-item {
      display: flex;
      align-items: center;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: rem(12px);
      line-height: (16 / 12);
      letter-spacing: 0.8px;
      text-transform: uppercase;

      &:last-child {
        padding-bottom: 0;
      }

      .svg {
        margin-right: 20px;
      }
    }
  }
}
