.eyebrow {
  @include font('sans-serif', 'bold');
  color: $brand-blue-bright;

  &--blue-bright {
    color: $brand-blue-bright;
  }

  &--grey-darker {
    color: $brand-grey-darker;
  }

  &--orange-bright {
    color: $brand-orange-bright;
  }

  &--purple-bright {
    color: $brand-purple-bright;
  }
}
