.module-form__field,
.module-form__radio,
.module-form__checkbox {
  label {
    @include font('sans-serif', 'book');
    color: $brand-grey;

    a {
      color: $brand-purple-bright;
    }

    b {
      @include font('sans-serif', 'medium');
      color: $brand-grey-darker;
    }
  }

  small {
    @include font('sans-serif', 'regular');
    color: $brand-grey;
  }

  &--s {
    label {
      b {
        color: $brand-grey;
      }
    }
  }

  &--error {
    > label {
      color: $brand-orange-bright;

      b {
        color: inherit;
      }
    }
  }
}

.module-form__field {
    .module-form__input {
        @include font('sans-serif', 'book');
        border-color: #add5ff;
        color: $brand-grey;

        &:focus {
            box-shadow: 0 0 0 3px rgba($brand-blue, 0.1);
        }
    }

    .margion-top-25 {
        margin-top: 25px;
    }

    .module-form__inner {
        &::after {
            background-image: url('../assets/images/icons/input-error@2x.png');
        }

        .hint-text {
            font-size: 12px;
        }
    }

    &-link {
        @include font('sans-serif', 'medium');
        color: $brand-purple-bright;
    }

    .module-form__password-show {
        .svg {
            fill: $brand-blue;
        }
    }

    &--date {
        .module-form__inner {
            &::after {
                background-image: url('../assets/images/icons/calendar-blue@2x.png');
            }
        }
    }

    &--error {
        .module-form__inner {
            .module-form__input {
                padding-right: 60px;
                border-color: $brand-orange-bright;
                color: $brand-orange-bright;
            }

            &::after {
                content: '';
            }
        }
    }
}

.module-form__select {
  &::after {
    background-image: url('../assets/images/icons/select-arrow@2x.png');
  }
}

.module-form__radio,
.module-form__checkbox {
  label {
    &::before {
      border-color: #add5ff;
    }
  }

  input:checked {
    + label::before {
      border-color: $brand-purple-bright;
    }
  }
}

.module-form__checkbox {
  label {
    &::after {
      background-image: image('icons/checked-purple@2x.png');
    }
  }
}

.module-form__radio {
  label {
    &::after {
      background-color: $brand-purple-bright;
    }
  }
}

.module-form__checkbox-radio {
  label {
    &::before {
      background-color: rgba($brand-green-bright, .3);
    }
  }

  input {
    &:checked {
      + label {
        &::before {
          background-color: $brand-green-bright;
        }
      }
    }
  }

  &--purple {
    label {
      &::before {
        background-color: $brand-purple;
      }
    }

    input {
      &:checked {
        + label {
          &::before {
            background-color: $brand-purple-bright;
          }
        }
      }
    }
  }
}

::placeholder {
  opacity: 1;
  color: rgba($brand-grey, 0.5);
}

:-ms-input-placeholder {
  color: rgba($brand-grey, 0.5);
}

::-ms-input-placeholder {
  color: rgba($brand-grey, 0.5);
}
