.module-form__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: -20px;
    margin-left: -20px;

    @include mq($from: 'xxl') {
        margin-right: -24px;
        margin-left: -24px;
    }

    @include mq($until: 'm') {
        flex-direction: column;
    }

    .module-form__radio,
    .module-form__field,
    .module-form__checkbox {
        flex: 1;
        padding-right: 20px;
        padding-left: 20px;

        .payment-methods {
            @include mq($from: 's') {
                padding-right: 20px;
                padding-left: 20px;
            }
        }

        @include mq($until: 'm') {
            width: 100%;

            &:not(:first-child) {
                margin-top: 18px;
            }
        }

        @include mq($from: 'xxl') {
            padding-right: 24px;
            padding-left: 24px;
        }
    }

    + .module-form__row,
    + .module-form__field:not(.module-form__field--no-space) {
        margin-top: 32px;

        @include mq($until: 'm') {
            margin-top: 18px;
        }
    }

    .module-form__field {
        .module-form__checkbox {
            padding-bottom: 5px;
        }

        .form-group {
            ul {
                li {
                    padding-left: 0px;

                    &:before {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    .module-form__inner, .module-form__field {
        .text-danger {
            color: red;
        }
    }
}
