.form-filter {
  position: relative;
  z-index: 4;

  &.is-open {
    z-index: 5;

    .form-filter__button {
      &::before {
        opacity: 1;
      }

      &::after {
        opacity: 1;
      }
    }
  }

  &:not(:first-child) {
    margin-left: 25px;
  }

  &__button {
    @include position(relative);
    @include size(100%, auto);
    @include padding(20px 30px);
    display: flex;
    align-items: center;
    border-radius: 16px;
    border: 1px solid transparent;
    font-size: rem(20px);
    line-height: (24 / 20);
    letter-spacing: -0.6px;
    transition: background-color .3s $ease-out-quad;

    &::before {
      @include position(absolute, 0px);
      border: 1px solid transparent;
    }

    &::after {
      @include position(absolute, -3px);
      z-index: -1;
    }

    &::before,
    &::after {
      content: '';
      border-radius: 16px;
      opacity: 0;
      transition: opacity .3s $ease-out-quad;
    }

    .svg:not(.svg-caret-down) {
      margin-right: 9px;
    }

    .svg {
      fill: currentColor;
    }

    .svg--caret-down {
      margin-left: auto;
      transition: transform .3s $ease-out-quad;
    }

    &[aria-expanded="true"] {
      + .form-filter__dropdown {
        visibility: visible;
        opacity: 1;
      }

      .svg--caret-down {
        transform: rotate(180deg);
      }
    }

    .no-touchevents &:hover,
    .no-touchevents &:focus {
      &::after {
        opacity: 1;
      }
    }
  }

  &__dropdown {
    @include position(absolute, calc(100% + 16px) null null 50%);
    @include size(100%, auto);
    min-width: 230px;
    visibility: hidden;
    opacity: 0;
    border-radius: 16px;
    transform: translateX(-50%);
    transition: opacity .2s $ease-out-quad, visibility .2s $ease-out-quad;

    &-items {
      overflow: auto;
      max-height: 280px;
      padding: 32px 32px 30px;
    }

    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px 20px 32px;
      border-top: 1px solid transparent;
    }

    &-clear {
      padding: 0;
      border: 0;
      background: transparent;
      font-size: rem(15px);
      line-height: (20 / 15);
      letter-spacing: -0.8px;
      text-decoration: underline;
    }
  }

  .module-form__checkbox {
    label {
      font-size: rem(16px);
    }

    + .module-form__checkbox {
      margin-top: 10px;
    }
  }
}
