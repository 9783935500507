.popup {
  @include position(fixed, 0 null null 0);
  @include size(100%, 100%);
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: visibility .4s $ease-out-quad, opacity .4s $ease-out-quad;

  &__close {
    z-index: 5;

    &.button-circle {
      position: absolute;
      top: 40px;
      right: 40px;

      @include mq($until: 'm') {
        top: 20px;
        right: 27px;
      }
    }
  }

  &__inner {
    @include size(100%);
    @include padding(null 50px);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;

    @include mq($until: 's') {
      @include padding(null 28px);
    }
  }

  &__spacer {
    flex: 1;
    flex-shrink: 0;
    min-height: 50px;

    @include mq($until: 'm') {
      min-height: 86px;
    }
  }

  &__content {
    @include size(100%, auto);
    @include margin(null auto);
    flex-shrink: 0;
    overflow: hidden;
    max-width: 840px;
    border-radius: 24px;
    background-color: $brand-white;
    transition: opacity .3s $ease-out-quad;

    .button {
      position: relative;
      z-index: 2;
    }

    &:not(.is-ready) {
      opacity: 0;
    }
  }

  &.js-popup-video {
    .popup__content {
      max-width: 1160px;
    }
  }

  &.popup-subscription {
    .popup__content {
      @include mq($until: 'm') {
        max-width: 560px;
        margin-right: auto;
        margin-left: auto
      }
    }
  }

  &.is-visible {
    visibility: visible;
    opacity: 1;
  }
}
