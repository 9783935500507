.module-card-icon {
  .module-card__inner {
    padding-bottom: 70px;
  }

  &__title {
    @include font('sans-serif', 'bold');
    margin-bottom: 18px;
    font-size: rem(28px);
    letter-spacing: -0.6px;
    line-height: (32 / 28);
    transition: color .3s $ease-out-quad;

    @include mq($from: 'xxl') {
      font-size: rem(32px);
      letter-spacing: -0.7px;
    }

    @include mq($until: 'm') {
      font-size: rem(24px);
    }
  }

  &__text {
    font-size: rem(16px);
    line-height: (24 / 16);
    letter-spacing: -0.5px;
  }

  &__tag {
    position: absolute;
    top: 45px;
    right: 40px;
    font-size: rem(10px);
    letter-spacing: 1px;
  }

  &__icon {
    @include size(100px);
    @include margin(null null 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    @include mq($until: 'm') {
      @include size(80px);
      margin-bottom: 25px;
    }

    img {
      display: block;
      width: 32px;
    }
  }

  &__button {
    @include position(absolute, null null 40px 50%);
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity 0.3s $ease-out-quad;
  }

  &--m,
  &--s {
    text-align: center;
    
    .module-card-icon__icon {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &--m {
    .module-card__inner {
      @include mq($from: 'm') {
        padding: 64px 56px 94px;
      }
    }

    .module-card-icon__title {
      font-size: rem(24px);
      line-height: (28 / 24);
      letter-spacing: -0.5px;
    }

    .module-card-icon__icon {
      @include size(64px);
      @include margin(null null 48px);

      @include mq($until: 'm') {
        margin-bottom: 25px;
      }
    }
  }

  &--s {
    .module-card-icon__icon {
      width: 80px;
      height: 80px;
    }

    .module-card-icon__title {
      font-size: rem(20px);
    }
  }

  &--with-button {
    .module-card__link {
      .no-touchevents &:hover,
      .no-touchevents &:focus {
        ~ .module-card__inner {
          .module-card-icon__button {
            opacity: 1;
          }
        }
      }
    }

    .module-card__inner {
      padding-bottom: 110px;
    }
  }
}
