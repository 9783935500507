.content-flexible {
  padding-top: 0;

  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include mq($until: 'l') {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &.content {
    .content-flexible__aside {
      .module-header {
        margin-top: 0;
        margin-bottom: 0;
        text-align: left;

        @include mq($until: 'l') {
          margin-left: 0;
        }

        &__text {
          margin-bottom: 0;
        }
      }
    }
  }

  &__aside {
    flex-shrink: 0;
    flex-basis: gs(3, 2);
    margin-right: gs(1, 2);

    @include mq($until: 'l') {
      display: flex;
      flex-direction: column;
      flex-basis: auto;
      margin-bottom: 60px;
      margin-right: 0;
      width: 100%;
    }
    
    @include mq($until: 'm') {
      width: 100%;
      margin-right: 0;
    }
    

    @include mq($from: 'xxl') {
      flex-basis: gs(3, 2, 1392px, 12, 48px);
      margin-right: gs(1, 2, 1392px, 12, 48px);
    }

    .sidebar-author {
      @include mq($until: 'l') {
        order: 1;
        margin-top: 0px;
      }
    }

    .sidebar-callout {
      @include mq($until: 'l') {
        margin-top: 50px;
        order: 2;
      }
    }

    .sidebar-menu {
      @include mq($until: 'l') {
        order: 3;
        margin-top: 50px;
      }
    }

    .sidebar-text {
      @include mq($until: 'l') {
        display: none;
      }
    }
  }

  &__content {
    flex: 1;
    flex-basis: 100%;

    @include mq($until: 'l') {
      width: 100%;
    }

    .module-form {
      margin-top: 10px;

      + * {
        margin-top: 80px;
      }
    }
  }
}
