.module-form__submit {
  flex-basis: gs(4, 3);

  @include mq($until: 'm') {
    flex-basis: auto;
  }

  @include mq($from: 'xxl') {
    flex-basis: gs(4, 3, 1392px, 12, 48px);
  }

  .module-form__radio,
  .module-form__checkbox {
    &--s {
      margin-bottom: 24px;
    }
  }
}
