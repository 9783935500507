.widget-videos {
  &__item {
    display: flex;
    align-items: center;
    padding: 40px;
    border: 1px solid transparent;

    @include mq($until: 's') {
      @include padding(32px);
      flex-direction: column-reverse;
    }

    &-content {
      flex: 1;
      margin-right: gs(0, 1);

      @include mq($from: 'xxl') {
        margin-right: gs(0, 1, 1392px, 12, 48px);
      }
    }

    &-title {
      font-size: rem(24px);
      line-height: (28 / 24);
      letter-spacing: -0.7px;

      @include mq($until: 'm') {
        font-size: rem(20px);
        letter-spacing: -.5px;
      }
    }

    &-text {
      margin-top: 16px;
      font-size: rem(16px);
      line-height: (24 / 16);
      letter-spacing: -0.5px;
    }

    &-video {
      flex-basis: gs(4, 3);

      @include mq($until: 'm') {
        flex-basis: 50%;
      }

      @include mq($until: 's') {
        flex-basis: auto;
        width: 100%;
        margin-bottom: 35px;
      }

      .widget-video {
        @include mq($from: 's') {
          min-height: 240px;
        }

        .button-circle {
          @include size(56px);
          @include margin(-28px null null -28px);

          svg {
            width: 10px;
            height: 12px;
          }
        }
      }
    }

    + .widget-videos__item {
      margin-top: 20px;
    }
  }
}
