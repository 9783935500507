.content-cards {
  &::after,
  &::before {
    @include position(absolute, null null null 50%);
    @include size(4px, 65px);
    @include margin(null null null -2px);
    content: '';
    border-radius: 2px;
    background-color: $brand-blue-light;
  }

  &::after {
    bottom: 0;
  }

  &::before {
    top: 0;
  }

  &--text {
    &::before {
      height: 40px;
    }

    &::after {
      content: none;
    }
  }

  &--icon,
  &--press,
  &--small {
    &::after,
    &::before {
      content: none;
    }
  }

  &--icon-medium {
    &::after,
    &::before {
      content: none;
    }
  }

  &--topic {
    &::after,
    &::before {
      height: 25px;
    }
  }

  &--habit {
    &::after,
    &::before {
      height: 40px;
    }
  }

  &--icon,
  &--press,
  &--topic,
  &--image,
  &--curriculum,
  &--icon-small,
  &--icon-medium {
    .content-cards__list {
      position: relative;

      &::after {
        content: '';
        z-index: -1;
        background-image: url('../assets/images/decorations/dot-pattern-2@2x.png');
        background-repeat: repeat;
        background-position: 50% 50%;
        background-size: 38px auto;
      }
    }
  }

  &--icon,
  &--icon-medium {
    .content-cards__list {
      &::after {
        @include position(absolute, -40px -40px null null);
        @include size(300px, 240px);
      }
    }
  }

  &--press {
    .content-cards__list {
      &::after {
        @include position(absolute, -40px -45px null null);
        @include size(240px, 300px);
      }
    }
  }

  &--topic,
  &--icon-small {
    .content-cards__list {

      &::after {
        @include position(absolute, -45px -45px null null);
        @include size(240px);
      }
    }
  }

  &--image {
    .content-cards__list {
      &::after {
        @include position(absolute, -30px null null -40px);
        @include size(240px, 300px);
      }
    }
  }

  &--curriculum {
    .content-cards__list {
      &::after {
        @include position(absolute, null null -50px -40px);
        @include size(240px, 300px);
      }
    }
  }

  &--tiles {
    .module-card__link {
      .no-touchevents &:hover,
      .no-touchevents &:focus {
        ~ .module-card__inner {
          .module-card-icon__title {
            color: $brand-purple-bright;
          }

          &::before {
            opacity: 1;
            border-color: $brand-purple-bright;
          }
        }
      }
    }

    .module-card__inner {
      &::before {
        border-color: rgba($brand-purple-bright, 0.2);
      }
    }
  }
}
