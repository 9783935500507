.sso-plans {
  width: 100%;

  .sso-grid__title {
    margin-bottom: 60px;
    
    @include mq($until: 'm') {
      margin-bottom: 30px;
    }
  }

  &__items {
    @include margin(null auto);
    max-width: 960px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include mq($until: 'm') {
      flex-direction: column;
    }
  }

  &__item {
    position: relative;
    width: calc(50% - 20px);

    @include mq($until: 'm') {
      width: 100%;

      + .sso-plans__item {
        margin-top: 30px;
      }
    }

    &-link {
      @include position(absolute, 0 null null 0);
      @include size(100%, 100%);
      z-index: 2;
      border-radius: 24px;

      .no-touchevents &:focus,
      .no-touchevents &:hover {
        ~ .sso-plans__item-content {
          border-color: $brand-blue-bright;
          transform: translateY(-12px);
        }
      }
    }
    
    &-content {
      @include padding(60px);
      width: 100%;
      background-color: $brand-white;
      border-radius: 24px;
      border: 1px solid $brand-blue-light;
      transition: border-color .3s $ease-out-quad, transform .3s $ease-out-quad;

      @include mq($until: 's') {
        @include padding(40px);
      }
    }

    &-title {
      @include font('sans-serif', 'bold');
      padding-bottom: 20px;
      font-size: rem(28px);
      letter-spacing: -.6px;
      line-height: (32 / 28);
      color: $brand-blue-bright;
      border-bottom: 1px solid currentColor;
    }

    &-list {
      &-item {
        @include padding(18px 30px 18px null);
        position: relative;
        width: 100%;
        border-bottom: 1px solid rgba($brand-blue, .3);
        color: $brand-grey-darker;

        &--disabled {
          color: $brand-grey-light;
        }

        .svg {
          @include position(absolute, 50% 0 null null);
          transform: translateY(-50%);
          fill: $brand-blue;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &-footer {
      border-top: 1px solid $brand-blue;
      padding-top: 28px;

      .button {
        margin-top: 25px;
        position: relative;
        z-index: 2;
        width: 100%;

        .svg--arrow-right {
          margin-left: auto;
        }
      }
    }

    &-price {
      @include font('sans-serif', 'bold');
      font-size: rem(36px);
      letter-spacing: -.8px;
      line-height: 1;
      color: $brand-blue-bright;

      sub {
        @include font('sans-serif', 'book');
        font-size: rem(18px);
        letter-spacing: -.6px;
      }
    }

    &--premium {
      border-color: rgba($brand-green-bright, .3);

      .sso-plans__item-link {
        .no-touchevents &:focus,
        .no-touchevents &:hover {
          ~ .sso-plans__item-content {
            border-color: $brand-green-bright;
          }
        }
      }

      .sso-plans__item-title {
        color: $brand-purple-bright;
        border-bottom-color: $brand-green-bright;
      }

      .sso-plans__item-footer {
        border-top-color: $brand-green-bright;
      }

      .sso-plans__item-list-item {
        border-bottom-color: rgba($brand-green-bright, .3);

        .svg {
          fill: $brand-green-bright;
        }
      }


      .sso-plans__item-price {
        color: $brand-purple-bright;
      }
    }
  }
}