.module-image {
  @include size(100%, auto);
  @include aspect-ratio(1, 1);
  z-index: 0;
  max-width: 460px;

  &__dots {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    z-index: -1;
    fill: transparent;
    stroke-width: 2px;
    stroke: $brand-blue-light;
    stroke-dasharray: 3px 12px;
    stroke-linecap: round;
    transition: transform 0.8s .1s $ease-out-cubic;

    circle {
      r: calc(50% - 4px);
    }
  }

  &__item {
    border-radius: 50%;
    background-color: $brand-blue-lighter;
    overflow: hidden;
    transition: clip-path 1.1s .1s $ease-out-expo, opacity .6s .1s $ease-out-quad;
    // Fix iOS
    transform: translate3d(0, 0, 0);

    picture {
      display: block;
      width: 100%;
      height: 100%;
    }
    
    img {
      @include size(100%);
      @include object-fit(cover);
      display: block;
      transition: transform 1.1s .1s $ease-out-expo;
    }

    &--1 {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  &__circles {
    @include position(absolute, 0 0 0 0);
    transition: transform 1.1s .1s $ease-out-expo;
  }
  
  .circle--1 {
    @include position(absolute, auto auto 13% 10%);
    @include size(24px);
  }

  .circle--2 {
    @include position(absolute, null null 5% 15%);
    @include size(8px);
  }

  &:not(.module-image--double) {
    .module-image__item {
      &--2 {
        position: absolute;
        width: 100px;
        height: 100px;

        @include mq($until: 'l') {
          @include size(64px);
        }
      }
    }

    &.module-image--top {
      .module-image__item {
        &--2 {
          top: 0;

          @include mq($until: 's') {
            top: -30px;
          }
        }
      }

      &.module-image--left {
        .circle--1 {
          @include position(absolute, auto 55px 45px auto);
          @include size(24px);

          @include mq($until: 's') {
            right: 20px;
            bottom: 20px;
          }
        }

        .circle--2 {
          @include position(absolute, auto 70px 10px auto);
          @include size(10px);

          @include mq($until: 's') {
            right: 40px;
            bottom: -10px;
          }
        }
      }

      &.module-image--right {
        .circle--1 {
          @include position(absolute, 105px 70px auto auto);
          @include size(8px);

          @include mq($until: 'l') {
            right: 20px;
          }

          @include mq($until: 'm') {
            top: 40px;
            right: 90px;
          }

          @include mq($until: 's') {
            top: -20px;
          }
        }

        .circle--2 {
          @include position(absolute, auto auto 90px 70px);
          @include size(16px);

          @include mq($until: 'm') {
            bottom: 40px;
          }

          @include mq($until: 's') {
            bottom: 20px;
            left: 20px;
          }
        }
      }
    }

    &.module-image--right {
      .module-image__item {
        &--2 {
          right: 0;
        }
      }
    }

    &.module-image--bottom {
      .module-image__item {
        &--2 {
          bottom: 0;

          @include mq($until: 's') {
            bottom: -30px;
          }
        }
      }

      &.module-image--left {
        .circle--1 {
          @include position(absolute, auto auto -20px 100px);
          @include size(12px);

          @include mq($until: 'm') {
            left: 90px;
            bottom: -10px;
          }

          @include mq($until: 's') {
            display: none;
          }
        }

        .circle--2 {
          @include position(absolute, 35px 50px auto auto);
          @include size(8px);

          @include mq($until: 'l') {
            top: 20px;
            right: 20px;
          }
        }

        .circle--3 {
          @include position(absolute, 65px 35px auto auto);
          @include size(24px);
        }
      }

      &.module-image--right {
        .circle--1 {
          @include position(absolute, auto -12px 112px auto);
          @include size(12px);

          @include mq($until: 'm') {
            right: 20px;
            bottom: 120px;
          }

          @include mq($until: 's') {
            display: none;
          }
        }

        .circle--2 {
          @include position(absolute, 110px auto auto 55px);
          @include size(8px);

          @include mq($until: 'l') {
            top: 50px;
            left: 0px;
          }
        }
      }
    }

    &.module-image--left {
      .module-image__item {
        &--2 {
          left: 0;
        }
      }
    }
  }

  &--double {
    .module-image__item {
      &--2 {
        @include position(absolute, 20px null null 30px);
        @include size(80px);
      }

      &--3 {
        @include position(absolute, null 20px -30px null);
        @include size(120px);
      }
    }

    .circle--1 {
      @include position(absolute, auto 35% -8% auto);
      @include size(12px);
    }
  }

  &--340,
  &--l {
    .module-image__item {
      &--1 {
        @include size(340px);
        @include margin(-170px null null -170px);


        @include mq($until: 'm') {
          section:not(.content-column) &,
          .header & {
            @include size(240px);
            @include margin(-120px null null -120px);
          }
        }
      }
    }

    &:not(.module-image--double) {
      @include mq($until: 's') {
        margin-bottom: 0;
      }

      &.module-image--left {
        .module-image__item--2 {
          @include mq($until: 's') {
            left: -20px;
          }
        }
      }

      &.module-image--bottom {
        .module-image__item--2 {
          @include mq($until: 's') {
            bottom: -20px;
          }
        }
      }
  
      &.module-image--right {
        .module-image__item--2 {
          @include mq($until: 's') {
            right: -20px;
          }
        }
      }
    }
  }

  &--280,
  &--m {
    .module-image__item {
      &--1 {
        @include size(280px);
        @include margin(-140px null null -140px);

        @include mq($from: 'xxl') {
          @include size(320px);
          @include margin(-160px null null -160px);
        }
      }
    }
  }

  &--220 {
    .module-image__item {
      &--1 {
        @include size(220px);
        @include margin(-110px null null -110px);

        @include mq($until: 'm') {
          section:not(.content-column) &,
          .header & {
            @include size(128px);
            @include margin(-64px null null -64px);
          }
        }
      }
    }

    &:not(.module-image--double) {
      &.module-image--top {
        .module-image__item--2 {
          @include mq($until: 's') {
            top: 20px;
          }
        }
      }
  
      &.module-image--bottom {
        .module-image__item--2 {
          @include mq($until: 's') {
            bottom: 20px;
          }
        }
      }
    }
  }

  &--210,
  &--s {
    .module-image__item {
      &--1 {
        @include size(210px);
        @include margin(-105px null null -105px);

        @include mq($until: 'l') {
          section:not(.content-column) &,
          .header & {
            @include size(160px);
            @include margin(-80px null null -80px);
          }
        }

        @include mq($until: 'm') {
          section:not(.content-column) &,
          .header & {
            @include size(120px);
            @include margin(-60px null null -60px);
          }
        }
      }
    }
  }

  &--200 {
    .module-image__item {
      &--1 {
        @include size(200px);
        @include margin(-100px null null -100px);

        @include mq($until: 'm') {
          section:not(.content-column) &,
          .header & {
            @include size(120px);
            @include margin(-60px null null -60px);
          }
        }
      }
    }
  }

  &--180 {
    .module-image__item {
      &--1 {
        @include size(180px);
        @include margin(-90px null null -90px);

        @include mq($until: 'm') {
          section:not(.content-column) &,
          .header & {
            @include size(110px);
            @include margin(-55px null null -55px);
          }
        }
      }
    }
  }

  &--170,
  &--xs {
    .module-image__item {
      &--1 {
        @include size(170px);
        @include margin(-85px null null -85px);
      }
    }
  }

  &--160 {
    .module-image__item {
      &--1 {
        @include size(160px);
        @include margin(-80px null null -80px);
      }
    }
  }

  &--140 {
    .module-image__item {
      &--1 {
        @include size(140px);
        @include margin(-70px null null -70px);

        @include mq($until: 'm') {
          section:not(.content-column) &,
          .header & {
            @include size(90px);
            @include margin(-45px null null -45px);
          }
        }
      }
    }

    .circle--1 {
      @include mq($until: 'm') {
        // bottom: 0;
        // left: -30px;
      }
    }
  }

  &--120,
  &--xxs {
    .module-image__item {
      &--1 {
        @include size(120px);
        @include margin(-60px null null -60px);

        @include mq($until: 'm') {
          section:not(.content-column) &,
          .header & {
            @include size(66px);
            @include margin(-33px null null -33px);
          }
        }
      }
    }
  }

  &--100 {
    .module-image__item {
      &--1 {
        @include size(100px);
        @include margin(-50px null null -50px);
      }
    }
  }

  &--80,
  &--xxxs {
    .module-image__item {
      &--1 {
        @include size(80px);
        @include margin(-40px null null -40px);

        @include mq($until: 'm') {
          section:not(.content-column) &,
          .header & {
            @include size(64px);
            @include margin(-32px null null -32px);
          }
        }
      }
    }
  }

  &--64 {
    .module-image__item {
      &--1 {
        @include size(64px);
        @include margin(-32px null null -32px);
      }
    }
  }
}

html:not(.mobile):not(.ie) .js-in-view {
  .module-image__item {
    clip-path: circle(0%);

    img {
      transform: scale(1.3);
    }

    &--2 {
      opacity: 0;
    }
  }

  .module-image__dots {
    transform: rotate(90deg) scale(0);
  }

  .module-image__circles {
    transform: scale(0);
  }

  &.in-view {
    .module-image__item {
      clip-path: circle(50%);

      img {
        transform: scale(1);
      }

      &--2 {
        opacity: 1;
      }
    }

    .module-image__dots {
      transform: rotate(0deg) scale(1);
    }

    .module-image__circles {
      transform: scale(1);
    }
  }
}