.header--landing-alternative {
  .module-image {
    &:nth-child(1) {
      @include position(absolute, 170px null null 50%);
      @include margin(null null null -400px);
      max-width: 80px;

      @include mq($until: 'l') {
        margin-left: -360px;
      }

      @include mq($until: 'm') {
        top: 130px;
        margin-left: -270px;
      }

      @include mq($until: 's') {
        margin-left: -230px;
      }
    }

    &:nth-child(2) {
      @include position(absolute, null null 90px 50%);
      @include margin(null null null 360px);
      max-width: 300px;

      @include mq($until: 'l') {
        margin-left: 260px;
      }

      @include mq($until: 'm') {
        max-width: 180px;
        margin-left: 200px;
        bottom: 230px;
      }

      @include mq($until: 's') {
        margin-left: 165px;
      }

      @include mq($until: 'xs') {
        margin-left: 110px;
        bottom: 290px;
      }

      .circle--1 {
        @include position(absolute, 10px auto auto 90px);
        @include size(24px);

        @include mq($until: 'm') {
          @include size(16px);
          top: 15px;
          left: 145px;
        }
      }

      .circle--2 {
        @include position(absolute, 15px auto auto 70px);
        @include size(8px);

        @include mq($until: 'm') {
          top: 40px;
          left: 160px;
        }
      }
    }

    &:nth-child(3) {
      @include position(absolute, null null -75px 50%);
      @include margin(null null null -640px);
      max-width: 300px;

      @include mq($until: 'l') {
        margin-left: -500px;
      }

      @include mq($until: 'm') {
        max-width: 177px;
        margin-left: -270px;
        bottom: -30px;
      }

      .circle--1 {
        @include position(absolute, 45px auto auto 40px);
        @include size(8px);

        @include mq($until: 'm') {
          top: -20px;
        }
      }
    }
  }
}
