.module-card-topic {
  &__title {
    @include font('sans-serif', 'bold');
    color: $brand-grey-darker;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-grey;
  }
}
