.widget-quote {
  border-color: $brand-blue-lighter;

  p {
    @include font('sans-serif', 'bold');
    color: $brand-blue-bright;
  }

  cite {
    @include font('sans-serif', 'medium');
    color: $brand-blue;
  }
}
