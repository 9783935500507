.module-popup-video {
  --plyr-control-radius: 0px;
  --plyr-range-thumb-height: 0px;
  --plyr-range-track-height: 4px;

  > div[class=""] {
    @include aspect-ratio(16, 9);

    iframe {
      @include position(absolute, 0 null null 0);
      @include size(100%, 100%);
    }
  }

  .plyr__controls {
    flex-wrap: wrap;
    padding-right: 40px;
    padding-bottom: 35px;
    padding-left: 40px;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 0, 0, 0.56) 100%);

    @include mq($until: 'm') {
      padding-right: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
    }
  }
  
  .plyr__progress__container {
    order: 0;
    flex: none;
    width: 100%;
    margin-right: auto;
    margin-bottom: 10px;
    padding-left: 0 !important;
  }

  [data-plyr="play"] {
    order: 1;
    margin-right: 0 !important;
  }

  .plyr__volume {
    order: 2;
  }  

  .plyr__time {
    margin-right: auto;
    order: 3;
  }

  [data-plyr="captions"] {
    order: 4;
  }

  .plyr__menu {
    order: 5;
  }

  [data-plyr="pip"] {
    order: 6;
  }

  [data-plyr="fullscreen"] {
    order: 7;
  }

  .plyr__control--overlaid,
  .plyr--full-ui.plyr--video .plyr__control--overlaid {
    display: none;
  }
}
