.button-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 3px solid transparent;
  transition: border-color 0.3s $ease-out-quart, background-color 0.3s $ease-out-quart;

  .svg {
    transition: fill 0.3s $ease-out-quart;
  }

  &--l {
    width: 72px;
    height: 72px;
  }
}
