.content-trip-pricing {
  padding-top: 120px;
  padding-bottom: 120px;

  .module-header {
    margin-bottom: 50px;
  }

  &__desktop {
    max-width: 1160px;
    margin-right: auto;
    margin-left: auto;
    padding: 65px 60px;

    @include mq($until: 'l') {
      padding: 45px 10px;
    }

    @include mq($until: 'm') {
      display: none;
    }

    th,
    td {
      &:nth-child(2),
      &:nth-child(3) {
        width: 160px;
      }
    }
  }

  &__mobile {
    @include mq($from: 'm') {
      display: none;
    }
  }
}
