.content-join {
  .center {
    position: relative;
  }

  .module-header {
    margin-bottom: 0;
    text-align: center;

    @include mq($from: 700px) {
      padding-right: 40px;
      padding-left: 40px;
    }

    &__title {
      font-size: rem(52px);
      letter-spacing: -1px;

      @include mq($until: 'm') {
        font-size: rem(42px);
      }

      @include mq($from: 'xxl') {
        font-size: rem(56px);
      }
    }

    .js-title-effect {
      > div {
        @for $n from 0 through 5 {
          &:nth-child(#{$n}) {
            transition-delay: $n / 10 * 1s + .3s;
          }
        }
      }
    }

    &__text {
      margin-top: 20px;
      font-size: rem(20px);
      line-height: (28 / 20);

      @include mq($until: 'm') {
        font-size: rem(18px);
      }
    }
  }

  &__images {
    @include position(absolute, 50% null null 50%);
    @include size(100%);
    transform: translate(-50%, -50%);
  }

  &--l {
    .module-header {
      &__text {
        @include mq($from: 'xs') {
          padding-right: 50px;
          padding-left: 50px;
        }
      }
    }
  }

  &--s {
    .module-header {
      &__title {
        font-size: rem(44px);
        letter-spacing: -0.8px;

        @include mq($until: 'm') {
          font-size: rem(42px);
        }
      }

      &__text {
        @include mq($from: 'xl') {
          padding-right: 60px;
          padding-left: 60px;
        }
      }

      &__buttons {
        margin-top: 40px;
      }
    }
  }

  &--list {
    padding-top: 100px;
    padding-bottom: 120px;
  }

  html:not(.mobile):not(.ie) &.js-in-view {
    .module-header__text,
    .button-line {
      transition-delay: .7s;
    }

    .button-icon {
      &,
      .button-icon__label,
      &::before,
      .svg--arrow-right {
        transition-delay: .8s;
      }
    }
    
    .button-line {
      transition-delay: .9s;
    }
  }
}