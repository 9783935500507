.module-table {
  text-align: left;

  &--desktop {
    table {
      width: 1040px;
      border-collapse: separate;
      border-spacing: 40px 0;

      @include mq($until: 'xl') {
        width: 100%;
      }

      @include mq($until: 'l') {
        border-spacing: 20px 0;
      }
    }

    .box {
      display: block;
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: rem(20px);
      line-height: (24 / 20);
      letter-spacing: -0.4px;

      small,
      strong {
        display: block;
      }
    }
  
    thead {
      .box {
        border: 0 none;
        font-size: rem(18px);

        small {
          font-size: rem(10px);
          letter-spacing: 1.5px;
          text-transform: uppercase;
        }
      }
  
      th {
        vertical-align: bottom;
      }
    }
  
    tfoot {
      th,
      td {
        vertical-align: top;
      }
    }
  
    th,
    td {
      position: relative;
  
      &:not(:last-child) {
        margin-right: 40px;
      }
  
      &:not(:first-child) {
        padding-right: 20px;
        padding-left: 20px;
        text-align: center;
      }
    }
  }

  &--mobile {
    @include margin(null auto);
    max-width: 560px;
    width: 100%;

    table {
      width: 100%;
      
      &:not(.is-active) {
        display: none;
      }
    }
    
    .module-table__mobile-head {
      display: flex;

      &-button {
        width: 100%;
        min-height: 50px;
        border: 0;
        font-size: rem(16px);
        line-height: 1;
        letter-spacing: -0.4px;

        small {
          margin-top: 0;
          color: currentColor;
        }

        + .module-table__mobile-head-button {
          margin-left: 12px;
        }
      }
    }

    td {
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: rem(16px);
      line-height: (20 / 16);
      letter-spacing: -.4px;

      &:not(:first-child) {
        padding-left: 10px;
        text-align: right;
      }
    }

    tr:last-child {
      td {
        border-bottom: 0;
      }
    }

    small {
      display: block;
    }
  }

  small {
    margin-top: 8px;
    font-size: rem(13px);
  }

  &__button {
    display: block;
    padding: 14px;
    border: 0;
    font-size: rem(10px);
    line-height: (14 / 10);
    letter-spacing: 1.5px;
    text-transform: uppercase;
    transition: background-color 0.3s $ease-out-quart, border-color 0.3s $ease-out-quart, color 0.3s $ease-out-quart;

    @include mq($until: 'm') {
      display: inline-block;
    }
  }
}
