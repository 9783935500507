.module-filters-bar {
  @include mq($from: 'm') {
    border-color: $brand-blue-light;
  }

  &__text,
  &__sorting-select {
    @include font('sans-serif', 'medium');
    color: $brand-grey;
  }

  &__tags {
    @include mq($until: 'm') {
      border-color: $brand-blue-light;
    }
    
    &-item {
      @include font('sans-serif', 'medium');
      border-color: $brand-blue;
      background-color: $brand-blue-lighter;
      color: $brand-grey-darker;
    }
  }

  &__sorting {
    &-select {
      border-color: #ADD5FF;
    }

    .svg--caret-down {
      fill: $brand-blue-bright;
    }
  }
}
