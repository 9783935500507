.module-partners {
  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-right: gs(0, -1);
    margin-left: gs(0, -1);

    @include mq($until: 'm') {
      @include margin(null -44px);
    }

    @include mq($from: 'xxl') {
      margin-right: gs(0, -1, 1392px, 12, 48px);
      margin-left: gs(0, -1, 1392px, 12, 48px);
    }
  }

  &__item {
    @include size(20%, auto);
    @include aspect-ratio(1, 1);
    @include padding(null gs(0, 1));
    min-height: 80px;

    @include mq($until: 'm') {
      @include padding(null 44px);
    }

    @include mq($until: 'l', $from: 'm') {
      width: 25%;

      &:nth-child(n + 9) {
        display: none;
      }
    }

    @include mq($until: 'm', $from: 'xs') {
      width: 33.33%;
      min-height: 80px;

      &:nth-child(n + 7) {
        display: none;
      }
    }

    @include mq($until: 'xs') {
      width: 50%;

      &:nth-child(odd) {
        img {
          margin-left: 20px;
        }
      }

      &:nth-child(even) {
        img {
          margin-left: -20px;
        }
      }

      &:nth-child(n + 7) {
        display: none;
      }
    }

    @include mq($from: 'xxl') {
      padding-right: gs(0, 1, 1392px, 12, 48px);
      padding-left: gs(0, 1, 1392px, 12, 48px);
    }

    a {
      @include position(absolute, 0 null null 0);
      @include size(100%);
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 80%;
      max-height: 80px;
      transform: translate(-50%, -50%);
    }
  }

  &__button {
    margin-top: 64px;
    text-align: center;
  }
}
