.module-faq {
  &__list {
    &-item {
      border-top: 1px solid transparent;

      &-head {
        @include position(relative);
        @include padding(32px null);

        @include mq($until: 'm') {
          @include padding(25px null);
        }
      }

      &-button {
        @include position(absolute, 0 null null 0);
        @include size(100%, 100%);
        border: 0;
        background: transparent;

        .svg {
          @include position(absolute, calc(50% - 8px) 0 null null);
          transform: rotate(-135deg);
          transition: transform 0.3s $ease-out-quart;
        }
      }

      &-title,
      &-text {
        padding-right: gs(1, 0);

        @include mq($from: 'xxl') {
          padding-right: gs(1, 0, 1392px, 12, 48px);
        }
      }

      &-title {
        font-size: rem(24px);
        line-height: (28 / 24);
        letter-spacing: -0.7px;
        transition: color .3s $ease-out-quad;

        @include mq($until: 'm') {
          font-size: rem(20px);
          letter-spacing: -.5px;
        }
      }

      &-text {
        display: none;
        padding-bottom: 32px;
        font-size: rem(16px);
        line-height: (22 / 16);
        letter-spacing: -0.5px;

        p {
          + p {
            margin-top: 20px;
          }
    
          + ul,
          + ol {
            margin-top: 32px;
          }
        }

        ul,
        ol {
          li {
            @include position(relative);
            @include padding(null null null 26px);

            + li {
              margin-top: 15px;
            }
          }

          + p,
          + ul,
          + ol {
            margin-top: 32px;
          }
        }

        ul {
          li {
            &::before {
              @include position(absolute, 10px null null 0);
              @include size(6px);
              content: '';
              border-radius: 50%;
            }
          }
        }

        ol {
          counter-reset: count;

          li {
            counter-increment: count;

            &::before {
              content: counter(count);
              position: absolute;
              top: 0;
              left: 0;
              font-size: rem(15px);
              line-height: (28 / 15);
              letter-spacing: -0.47px;
            }
          }
        }

        @include mq($until: 'l') {
          padding-right: 0;
        }
      }

      &.is-open {
        .module-faq__list-item-button {
          .svg {
            transform: rotate(0deg);
          }
        }
        
        .module-faq__list-item-text {
          display: block;
        }
      }
    }
  }

  &--l {
    .module-faq__list {
      &-item {
        &-text,
        &-title {
          padding-right: gs(1, 1);

          @include mq($from: 'xxl') {
            padding-right: gs(1, 1, 1392px, 12, 48px);
          }
        }

        &-text {
          font-size: rem(18px);
          line-height: (28 / 18);
          letter-spacing: -0.6px;
          
          @include mq($until: 'l') {
            padding-right: 0;
          }
        }
      }
    }
  }
}
