.widget-image {
  display: block;
  overflow: hidden;

  img,
  picture {
    display: block;
    width: 100%;
    height: auto;
  }
}
