.header--home {
  .module-image {
    &:nth-child(1) {
      @include position(absolute, 135px null null 50%);
      @include margin(null null null 320px);
      max-width: 80px;

      @include mq($until: 'm') {
        margin-left: -210px;
        bottom: 210px;
        top: auto;
      }
    }

    &:nth-child(2) {
      @include position(absolute, null null -20px 50%);
      @include margin(null null null -650px);
      max-width: 300px;

      @include mq($from: 'xxl') {
        margin-left: -775px;
      }

      @include mq($until: 'm') {
        display: none;
      }

      .circle--1 {
        @include position(absolute, auto 80px 65px auto);
        @include size(8px);
      }
    }

    &:nth-child(3) {
      @include position(absolute, null null -130px 50%);
      @include margin(null null null 330px);
      max-width: 300px;

      @include mq($until: 'm') {
        bottom: -55px;
        margin-left: 50px;
        max-width: 180px;
      }

      .circle--1 {
        @include position(absolute, auto 45px 30px auto);
        @include size(24px);

        @include mq($until: 'm') {
          bottom: 0px;
          right: 35px;
        }
      }

      .circle--2 {
        @include position(absolute, auto 55px 5px auto);
        @include size(8px);

        @include mq($until: 'm') {
          bottom: -10px;
          right: 20px;
        }
      }
    }
  }
}
