.module-popup-subscription {
  display: flex;

  @include mq($until: 'm') {
    flex-direction: column;
  }

  &__perks,
  &__content {
    padding: 85px 60px;

    @include mq($until: 'm') {
      padding: 50px 32px;
    }
  }

  &__content {
    @include mq($until: 'm') {
      width: 100%;
      text-align: center;
    }

    &-title {
      font-size: rem(36px);
      line-height: (40 / 36);
      letter-spacing: -.8px;

      @include mq($until: 'm') {
        font-size: rem(28px);
        letter-spacing: -0.6px;
      }
    }

    &-text {
      margin-top: 32px;
      font-size: rem(18px);
      line-height: (28 / 18);
      letter-spacing: -0.6px;

      a {
        text-decoration: underline;
      }
      
      &--s {
        margin-top: 12px;
        font-size: rem(13px);
        line-height: (20 / 13);
        letter-spacing: -0.4px;
      }
    }

    .button {
      margin-top: 46px;
    }
  }

  &__perks {
    width: 45.3%;
    flex-shrink: 0;

    @include mq($until: 'm') {
      width: 100%;
    }

    &-title {
      font-size: rem(24px);
      line-height: (28 / 24);
      letter-spacing: -0.7px;

      @include mq($until: 'm') {
        font-size: rem(20px);
        letter-spacing: -0.5px;
        text-align: center;
      }
    }

    &-list {
      margin-top: 40px;

      &-item {
        display: flex;
        font-size: rem(16px);
        line-height: (22 / 16);
        letter-spacing: -0.5px;

        .svg {
          flex-shrink: 0;
          margin-top: 4px;
          margin-right: 16px;
          fill: currentColor;
        }

        &:not(:first-child) {
          margin-top: 23px;
        }
      }
    }
  }
}
