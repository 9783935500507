.content-schedule {
  padding-top: 80px;
  padding-bottom: 80px;

  .module-header {
    margin-bottom: 55px;
  }

  .module-schedule {
    @include mq($from: 'm') {
      margin-right: gs(1, 1);
      margin-left: gs(1, 1);
    }

    @include mq($until: 'l', $from: 'm') {
      @include margin(null 0);
    }

    @include mq($from: 'xxl') {
      margin-right: gs(1, 1, 1392px, 12, 48px);
      margin-left: gs(1, 1, 1392px, 12, 48px);
    }

    .module-tabs {
      @include mq($from: 'l') {
        margin-right: gs(0, -1);
        margin-left: gs(0, -1);
      }

      @include mq($from: 'xxl') {
        margin-right: gs(0, -1, 1392px, 12, 48px);
        margin-left: gs(0, -1, 1392px, 12, 48px);
      }
    }
  }
}
