.module-popup-subscription {
  &__content {
    &-title {
      @include font('sans-serif', 'bold');
      color: $brand-grey-darker;
    }

    &-text {
      @include font('sans-serif', 'book');
      color: $brand-grey;

      a {
        color: $brand-purple-bright;
      }
    }
  }

  &__perks {
    background-color: $brand-green-bright;

    &-title {
      @include font('sans-serif', 'bold');
      color: $brand-grey-darker;
    }

    &-list {
      &-item {
        @include font('sans-serif', 'book');
        color: $brand-grey-darker;
      }
    }
  }
}
