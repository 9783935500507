.content-facts-slider {
  padding-top: 120px;
  padding-bottom: 120px;

  &:not(.content-quotes) .content__column:first-child {
    @include mq($until: 'm') {
      margin-bottom: 0;
    }
  }

  .module-facts-slider__list-item:last-child {
    @include mq($until: 'm') {
      padding-bottom: 0;
      
      .module-facts-slider__list-item__nav-progress {
        display: none;
      }
    }
  }

  .module-image-basic {
    @include mq($until: 'm') {
      display: none;
    }
  }

  .module-facts-slider__list-item-image {
    @include mq($from: 'm') {
      display: none;
    }
  }

  .module-image-basic__item {
    transition: opacity .3s $ease-out-quad;
    opacity: 0;

    &.is-active {
      opacity: 1;
    }
  }

  &.is-playing {
    .svg--play {
      display: none;
    }
  }

  &:not(.is-playing) {
    .svg--pause {
      display: none;
    }

    .module-facts-slider__list-item__nav-progress-bar {
      animation: none;
    }
  }
}