.form-filter-date {
  &__button {
    @include font('sans-serif', 'bold');
    color: $brand-grey-darker;
    background: $brand-white;
    border-color: #ADD5FF;

    &::before {
      border-color: #ADD5FF;
    }

    &::after {
      background-color: rgba($brand-blue, .2);
    }

    .svg:not(.svg--caret-down) {
      fill: $brand-blue;
    }
  }
}
