.module-popup-activity {
  display: flex;
  
  @include mq($until: 'm') {
    flex-direction: column;
  }

  &__image {
    @include position(relative);
    @include size(380px, auto);
    flex-shrink: 0;

    @include mq($until: 'm') {
      @include aspect-ratio(320, 214);
      @include size(100%, auto);
      max-height: 360px;
    }

    img {
      @include position(absolute, 0 null null 0);
      @include size(100%, 100%);
      @include object-fit(cover, center);
    }
  }

  &__content {
    padding: 85px 60px;

    @include mq($until: 'm') {
      padding: 55px 32px;
      text-align: center;
    }

    &-title {
      font-size: rem(36px);
      line-height: (40 / 36);
      letter-spacing: -1px;

      @include mq($until: 'm') {
        font-size: rem(28px);
        letter-spacing: -0.6px;
      }
    }

    &-text {
      max-width: 460px;
      margin-top: 32px;
      margin-right: auto;
      margin-left: auto;
      font-size: rem(16px);
      line-height: (24 / 16);
      letter-spacing: -0.5px;

      a {
        text-decoration: underline;
      }
      
      &--s {
        margin-top: 12px;
        font-size: rem(13px);
        line-height: (20 / 13);
        letter-spacing: -0.4px;
      }
    }

    .button {
      margin-top: 46px;

      @include mq($until: 'm') {
        margin-top: 36px;
      }
    }
  }
}
