.widget-included {
  &__list {
    counter-reset: count;
  }

  &__item {
    @include position(relative);
    @include padding(null null null 36px);
    counter-increment: count;

    &-title {
      font-size: rem(24px);
      line-height: (28 / 24);
      letter-spacing: -0.7px;

      @include mq($until: 'm') {
        font-size: rem(20px);
        letter-spacing: -.5px;
      }
    }

    &-text {
      margin-top: 16px;
      font-size: rem(16px);
      line-height: (22 / 16);
      letter-spacing: -0.5px;
    }

    &::before {
      content: '0' counter(count);
      position: absolute;
      top: 0;
      left: 0;
      font-size: rem(11px);
      line-height: (22 / 11);
      letter-spacing: 3px;
    }

    + .widget-included__item {
      margin-top: 32px;
    }
  }
}
