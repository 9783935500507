.alert-bar {
  @include padding(null 30px);
  background-color: $brand-grey-darker;

  p {
    @include font('sans-serif', 'medium');
    color: $brand-blue;

    a {
      color: $brand-white;
    }
  }

  &__button {
    fill: $brand-blue;
  }
}