.sidebar-text {
  &__title {
    font-size: rem(11px);
    line-height: (22 / 11);
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  &__text,
  &__button {
    font-size: rem(16px);
    line-height: (24 / 16);
    letter-spacing: -0.5px;
  }

  &__text {
    margin-top: 24px;
  }

  &__button {
    margin-top: 8px;
    text-decoration: underline;
  }

  + * {
    margin-top: 48px;
  }
}
