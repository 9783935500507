.sso-grid {
  .center {
    @include padding(120px null);
    display: flex;

    @include mq($until: 'l') {
      @include padding(60px null);
      flex-direction: column;
    }
  }
  
  &__sidebar {
    width: 430px;
    padding-right: 90px;
    flex-grow: 1;
    border-right: 1px solid rgba($brand-blue, .2);
    flex-shrink: 0;

    @include mq($until: 'l') {
      padding-right: 0;
      border-right: 0;
      margin-bottom: 35px;
    }

    @include mq($until: 'm') {
      width: 100%;
    }
  }

  &__title {
    @include font('sans-serif', 'heavy');
    color: $brand-grey-darker;
    font-size: rem(60px);
    line-height: (58 / 60);
    letter-spacing: -1.3px;

    @include mq($until: 'm') {
      font-size: rem(48px);
      letter-spacing: -1px;

      br {
        display: none;
      }
    }

    @include mq($until: 's') {
      font-size: rem(38px);
      letter-spacing: -.7px;
    }
  }

  &__text {
    margin-top: 35px;
    font-size: rem(20px);
    line-height: (28 / 20);

    a {
      text-decoration: underline;
    }

    @include mq($until: 'm') {
      font-size: rem(18px);
    }
  }

  &__content {
    padding-left: 170px;
    flex-grow: 1;
    width: 100%;

    @include mq($until: 'xl') {
      padding-left: 90px;
    }

    @include mq($until: 'l') {
      padding-left: 0;
    }
  }

  &--centered {
    .center {
      justify-content: center;
    }

    .sso-grid__title {
      text-align: center;
    }
  }
}

.template-sso--premium {
  .sso-grid__sidebar {
    border-color: rgba($brand-green-bright, 0.3);
  }
}