.content-callout {
  &::after,
  &::before {
    @include position(absolute, null null null 50%);
    @include size(4px, 60px);
    @include margin(null null null -2px);
    content: '';
    border-radius: 2px;
    background-color: $brand-blue-light;
  }

  &::after {
    bottom: 0;
    height: 25px;
  }

  &::before {
    top: 0;
  }

  .module-image {
    &.module-image--bottom {
      &.module-image--right {
        .module-image__item {
          &--2 {
            right: -25px;
            bottom: 45px;

            @include mq($until: 'm') {
              right: -65px;
              bottom: 25px;
            }
          }
        }

        .circle--1 {
          @include position(absolute, auto -35px 140px auto);
          @include size(10px);
        }
      }
    }
  }

  &--orbits {
    &::after,
    &::before {
      height: 65px;
    }

    .module-image {
      margin-bottom: 0;

      &:nth-child(1) {
        @include position(absolute, 0 null null 50%);
        @include margin(null null null -260px);
        max-width: 80px;

        @include mq($until: 'm') {
          top: 10px;
          margin-left: -160px;
        }
      }

      &:nth-child(2) {
        @include position(absolute, 145px null null 50%);
        @include margin(null null null 300px);
        max-width: 280px;

        @include mq($until: 'm') {
          top: auto;
          bottom: 160px;
          max-width: 140px;
          margin-left: 280px;
        }

        @include mq($until: 's') {
          margin-left: 200px;
        }

        @include mq($until: 'xs') {
          margin-left: 130px;
        }

        .circle--1 {
          @include position(absolute, 60px 70px auto auto);
          @include size(8px);

          @include mq($until: 'm') {
            top: -40px;
            right: auto;
            left: 10px;
          }
        }
      }

      &:nth-child(3) {
        @include position(absolute, null null 0 50%);
        @include margin(null null null -580px);
        max-width: 260px;

        @include mq($until: 'm') {
          max-width: 160px;
          bottom: -70px;
          margin-left: -250px;
        }

        .circle--1 {
          @include position(absolute, auto auto 20px 15px);
          @include size(24px);

          @include mq($until: 'm') {
            bottom: 0;
            left: 0px;
          }
        }
      }
    }
  }

  &--reflect {
    .module-image {
      margin-bottom: 0;

      &:nth-child(1) {
        margin-left: 180px;

        @include mq($until: 'm') {
          margin-left: 90px;
        }
      }

      &:nth-child(2) {
        margin-left: -580px;
        
        @include mq($until: 'm') {
          margin-left: -360px;
        }

        @include mq($until: 's') {
          margin-left: -300px;
        }

        @include mq($until: 'xs') {
          margin-left: -270px;
        }
      }

      &:nth-child(3) {
        margin-left: 320px;

        @include mq($until: 'm') {
          margin-left: 90px;
        }

        .circle--1 {
          @include mq($until: 'm') {
            display: none;
          }
        }
      }
    }
  }
}
