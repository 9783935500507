.widget-text {
  &__lead {
    margin-bottom: 32px;
    font-size: rem(36px);
    line-height: (40 / 36);
    letter-spacing: -1.1px;

    @include mq($until: 'm') {
      font-size: rem(28px);
      letter-spacing: -.6px;
    }
  }

  &__typeset {
    font-size: rem(18px);
    line-height: (28 / 18);
    letter-spacing: -0.6px;

    @include mq($until: 'm') {
      font-size: rem(16px);
      letter-spacing: -.6px;
    }

    h2:not(.widget__title),
    h3 {
      font-size: rem(28px);
      line-height: (32 / 28);
      letter-spacing: -0.6px;

      + * {
        margin-top: 24px;
      }
    }

    h4 {
      font-size: rem(24px);
      line-height: (28 / 24);
      letter-spacing: -0.7px;

      + * {
        margin-top: 16px;
      }
    }

    h5,
    h6 {
      display: flex;
      align-items: center;
      font-size: rem(11px);
      line-height: (22 / 11);
      letter-spacing: 3px;
      text-transform: uppercase;

      &::after {
        content: '';
        flex: 1;
        width: auto;
        height: 1px;
        margin-left: 12px;
      }

      + * {
        margin-top: 48px;
      }
    }

    p {
      + p {
        margin-top: 20px;
      }

      + ul,
      + ol {
        margin-top: 32px;
      }

      + h2,
      + h3,
      + h4,
      + h5,
      + h6 {
        margin-top: 80px;
      }
    }

    a:not(.button) {
      display: inline-block;
      line-height: 1.2;
      text-decoration: none;
      border-bottom: 1px solid currentColor;
      transition: color .3s $ease-out-quad;
    }

    ul,
    ol {
      li {
        @include position(relative);
        @include padding(null null null 26px);

        + li {
          margin-top: 15px;
        }
      }

      + p,
      + ul,
      + ol {
        margin-top: 32px;
      }

      + h2,
      + h3,
      + h4,
      + h5,
      + h6 {
        margin-top: 80px;
      }
    }

    ul {
      li {
        &::before {
          @include position(absolute, 10px null null 0);
          @include size(6px);
          content: '';
          border-radius: 50%;
        }
      }
    }

    ol {
      counter-reset: count;

      li {
        counter-increment: count;

        &::before {
          content: counter(count);
          position: absolute;
          top: 0;
          left: 0;
          font-size: rem(15px);
          line-height: (28 / 15);
          letter-spacing: -0.47px;
        }
      }
    }
  }

  &__button {
    margin-top: 16px;
  }
}
