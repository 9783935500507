.content-cards {
    padding-top: 120px;
    padding-bottom: 120px;

    .module-header {
        max-width: gs(6, 5);
        margin-right: auto;
        margin-left: auto;

        @include mq($from: 'm') {
            text-align: center;
        }

        @include mq($from: 'xxl') {
            max-width: gs(6, 5, 1392px, 12, 48px);
        }

        &__text {
            @include margin(null auto);
            margin-top: 24px;
            max-width: 560px;
        }
    }

    .module-card {
        width: 100%;

        @include mq($until: 'l') {
            flex-shrink: 0;
            width: 336px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;

        @include mq($until: 'l') {
            @include margin(null auto);
        }

        &.js-cards-slider {
            @include mq($until: 'l') {
                flex-wrap: nowrap;
            }
        }

        &:not(.js-cards-slider) {
            @include mq($until: 'l') {
                .module-card {
                    width: 100%;

                    &:not(:last-child) {
                        margin-bottom: 12px;
                    }
                }
            }

            @include mq($until: 'm') {
                max-width: 560px;
            }
        }
    }

    &__footer {
        margin-top: 50px;
        text-align: center;

        @include mq($until: 'm') {
            margin-top: 40px;
        }
    }

    &--text {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    &--icon,
    &--press,
    &--small {
        padding-top: 60px;
        padding-bottom: 60px;

        @include mq($until: 'm') {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    &--topic,
    &--habit,
    &--icon-medium {
        padding-top: 80px;
        padding-bottom: 80px;

        @include mq($until: 'm') {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    &--icon-small {
        .module-header {
            margin-bottom: 55px;
        }
    }

    &--grades {
        .module-card__inner {
            &:hover {
                transform: none;
            }
        }
    }

    &[data-column="3"] {
        .module-card {
            @include mq($from: 'l') {
                width: calc(33.33% - 27px);
            }

            @include mq($from: 'xxl') {
                width: gs(4, 3, 1392px, 12, 48px);
            }

            &:not(:nth-child(3n)) {
                @include mq($from: 'l') {
                    margin-right: 40px;
                }

                @include mq($from: 'xxl') {
                    margin-right: 48px;
                }
            }

            &:nth-child(1n + 4) {
                @include mq($from: 'l') {
                    margin-top: 40px;
                }
            }

            &-text {
                &:nth-child(1n + 4) {
                    @include mq($from: 'l') {
                        margin-top: 64px;
                    }
                }
            }
        }
    }

    &[data-column="4"] {
        .module-card {
            @include mq($from: 'xl') {
                width: calc(25% - 30px);
            }

            @include mq($from: 'l', $until: 'xl') {
                width: calc(33.33% - 27px);

                &:not(:nth-child(3n)) {
                    margin-right: 40px;
                }

                &:nth-child(1n + 4) {
                    margin-top: 40px;
                }
            }

            @include mq($from: 'xxl') {
                width: gs(3, 2, 1392px, 12, 48px);
            }

            &:not(:nth-child(4n)) {
                @include mq($from: 'xl') {
                    margin-right: 28px;
                }

                @include mq($from: 'xxl') {
                    margin-right: 28px;
                }
            }

            &:nth-child(1n + 5) {
                @include mq($from: 'xl') {
                    margin-top: 40px;
                }
            }
        }
    }
}

[data-view="template-dashboard"] {
    .content-cards .content-cards--icon .content-cards--tiles .content {
        padding-top: 0px;

        .header {
            padding-top: 0px;

            .header-inner {
                margin-left: 0px;
            }
        }
    }
}