.widget-profiles {
  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 48px 60px;
    border: 1px solid transparent;

    @include mq($until: 'm') {
      @include padding(32px);
    }

    &-content {
      flex: 1;
      margin-left: gs(0, 1);

      @include mq($until: 'm') {
        margin-left: 20px;
      }

      @include mq($from: 'xxl') {
        margin-left: gs(0, 1, 1392px, 12, 48px);
      }
    }

    &-image {
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;

      @include mq($until: 'm') {
        @include size(64px);
      }

      img {
        @include size(100%);
        @include object-fit(cover);
        display: block;
      }
    }

    &-title {
      font-size: rem(20px);
      line-height: (24 / 20);
      letter-spacing: -0.5px;
    }

    &-metas {
      margin-top: 4px;
      margin-bottom: 24px;
      font-size: rem(15px);
      line-height: (20 / 15);
      letter-spacing: -0.8px;
    }

    &-text {
      display: none;
      font-size: rem(16px);
      line-height: (24 / 16);
      letter-spacing: -0.5px;
    }

    &-expand {
      margin-top: 20px;
      padding-top: 12px;
      border-top: 1px solid transparent;

      [aria-expanded="false"] {
        .widget-profiles__item-expand-expanded {
          display: none;
        }
      }

      [aria-expanded="true"] {
        .widget-profiles__item-expand-unexpanded {
          display: none;
        }

        .svg--caret-down {
          transform: rotate(180deg);
        }
      }
    }

    &-less {
      display: none;
    }

    &.is-expanded {
      .widget-profiles__item-text,
      .widget-profiles__item-less {
        display: block;
      }

      .widget-profiles__item-more {
        display: none;
      }
    }

    + .widget-profiles__item {
      margin-top: 20px;
    }
  }
}
