.content {
  @include position(relative);
  @include padding(60px null);

  .module-header {
    max-width: gs(6, 5);
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 80px;
    text-align: center;

    @include mq($from: 'xxl') {
      max-width: gs(6, 7, 1392px, 12, 48px);
    }

    @include mq($until: 'm') {
      max-width: 560px;
      margin-bottom: 50px;
      text-align: left;
    }

    + .content__inner {
      .content__column {
        margin-top: 0;
      }
    }

    &--s {
      margin-bottom: 50px;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;

    @include mq($until: 'm') {
      @include padding(50px null);
      flex-direction: column;
    }
  }

  &__column {
    flex-basis: gs(6, 5);
    min-width: gs(6, 5);
    max-width: gs(6, 5);

    @include mq($from: 'xxl') {
      flex-basis: gs(6, 5, 1392px, 12, 48px);
      min-width: gs(6, 5, 1392px, 12, 48px);
      max-width: gs(6, 5, 1392px, 12, 48px);
    }

    @include mq($until: 'xl') {
      flex-basis: calc(50% - 20px);
      min-width: calc(50% - 20px);
      max-width: calc(50% - 20px);
    }

    @include mq($until: 'm') {
      flex-basis: auto;
      min-width: 100%;
      max-width: 100%;
    }
  }
}

.modules {
  > .content {
    &:not(.content--curvy):last-child {
      padding-bottom: 120px;
    }
  }
}
