.button-line {
  display: inline-flex;
  align-items: center;
  font-size: rem(18px);
  line-height: (24 / 18);
  letter-spacing: 0.3px;

  > span {
    &:not(.svg) {
      @include position(relative);
      @include padding(null null 12px null);
      transition: transform 0.3s $ease-out-quart;

      &::before {
        @include position(absolute, null null 0 0);
        @include size(100%, 5px);
        content: '';
        transition: background-color 0.3s $ease-out-quart, transform 0.3s $ease-out-quart;
      }
    }

    + span {
      margin-left: 10px;
    }
  }

  .svg {
    margin-top: -9px;
  }

  &--l {
    font-size: rem(20px);
    line-height: (24 / 20);
    letter-spacing: -0.2px;

    @include mq($until: 'l') {
      font-size: rem(16px);
      letter-spacing: -0.4px;
    }
  }

  &--m {
    font-size: rem(16px);
    line-height: (22 / 16);
    letter-spacing: -0.4px;

    span {
      &:not(.svg) {
        padding-bottom: 9px;
      }
    }

    .svg {
      margin-top: -5px;
    }
  }

  &--s {
    font-size: rem(14px);
    line-height: (18 / 14);
    letter-spacing: -0.2px;

    span {
      &:not(.svg) {
        padding-bottom: 5px;

        &::before {
          height: 3px;
        }
      }
    }

    .svg {
      margin-top: -3px;
    }
  }

  .no-touchevents &:hover,
  .no-touchevents &:focus {
    > span:not(.svg) {
      transform: translateY(-4px);

      &::before {
        transform: translateY(4px);
      }
    }
  }
}
