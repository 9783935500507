.module-form__messages {
  flex-basis: gs(5, 4);
  padding-bottom:10px;

  @include mq($until: 'm') {
    flex-basis: auto;
  }

  @include mq($from: 'xxl') {
    flex-basis: gs(5, 4, 1392px, 12, 48px);
  }
}

.module-form__message {
  @include size(100%, auto);
  @include padding(16px 16px 14px);
  border-radius: 12px;
  border: 1px solid transparent;
  font-size: rem(12px);
  line-height: (16 / 12);
  letter-spacing: 0.8px;
  text-transform: uppercase;

  + .module-form__message {
    margin-top: 16px;
  }
}
