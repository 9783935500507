.module-schedule {
  @include mq($until: 'm') {
    @include margin(null auto);
    max-width: 560px;
  }
  
  .module-tabs {
    @include mq($until: 'm') {
      margin-bottom: 10px;
    }

    @include mq($from: 'm') {
      margin-bottom: 30px;
    }
  }

  &__list {
    display: none;

    &.is-active {
      display: block;
    }
  }

  &__button {
    margin-top: 10px;
    text-align: center;
  }
}
