.sidebar-author {
  @include mq($until: 'l') {
    display: flex;
    align-items: center;
  }
  
  &__image {
    @include size(100px);
    @include margin(null null 32px);
    display: block;
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;

    img {
      @include size(100%);
      @include object-fit(cover);
      display: block;
    }

    @include mq($until: 'l') {
      margin-bottom: 0;
      margin-right: 20px;
    }

    @include mq($until: 'm') {
      @include size(72px);
    }
  }

  &__content {
    width: 100%;
  }

  &__title {
    font-size: rem(20px);
    line-height: (24 / 20);
    letter-spacing: -0.5px;
  }

  &__text {
    margin-top: 12px;
    font-size: rem(16px);
    line-height: (24 / 16);
    letter-spacing: -0.5px;

    a {
      text-decoration: underline;
      transition: color .3s $ease-out-quad;
    }
  }

  + * {
    margin-top: 48px;
  }
}
