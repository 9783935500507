.content-archive {
  padding-top: 0;

  .module-filters-bar {
    margin-bottom: 74px;

    @include mq($until: 'm') {
      margin-bottom: 45px;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  .module-card {
    width: 100%;
  }

  .content-join {
    width: 100%;
    margin-top: 140px;
    margin-bottom: 120px;
    padding-top: 0;
    padding-bottom: 0;

    &:last-child {
      margin-bottom: 25px;
    }
  }

  .module-pagination {
    margin-top: 72px;
  }

  &[data-column="1"] {
    .module-card {
      width: 100%;
      margin-bottom: 25px;
    }

    .module-card-program {
      margin-bottom: 0;

      + .module-card-program {
        margin-top: 50px;
        padding-top: 50px;
        border-top: 1px solid transparent;
      }
    }
  }

  &[data-column="3"] {
    .module-card {
      width: gs(4, 3);

      @include mq($until: 'xl') {
        width: calc(33.33% - 14px);
      }

      @include mq($until: 'l') {
        width: calc(50% - 10px);
      }

      @include mq($until: 764px) {
        width: 100%;
      }

      @include mq($from: 'xxl') {
        width: gs(4, 3, 1392px, 12, 48px);
      }

      &:not(:nth-of-type(2n)) {
        @include mq($until: 'l', $from: 764px) {
          margin-right: 20px;
        }
      }

      &:not(:last-child) {
        @include mq($until: 764px) {
          margin-bottom: 44px;
        }
      }

      &:not(:nth-of-type(3n)) {
        @include mq($from: 'l') {
          margin-right: 20px;
        }

        @include mq($from: 'xl') {
          margin-right: 40px;
        }
      }

      &:nth-of-type(3) {
        @include mq($until: 'l', $from: 764px) {
          margin-top: 40px;
        }
      }

      &:nth-of-type(1n + 4) {
        @include mq($from: 764px) {
          margin-top: 40px;
        }
      }
    }
  }

  &[data-column="4"] {
    .module-card {
      width: gs(3, 2);

      @include mq($from: 'xxl') {
        width: gs(3, 2, 1392px, 12, 48px);
      }

      &:not(:nth-of-type(4n)) {
        margin-right: 40px;
      }

      &:nth-of-type(1n + 5) {
        margin-top: 40px;
      }
    }
  }
}
