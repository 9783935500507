.form-filter-date {
  position: relative;
  z-index: 4;

  &:not(:first-child) {
    margin-left: 25px;
  }

  input {
    display: none;
  }

  &__button {
    @include position(relative);
    @include size(100%, auto);
    @include padding(20px 30px);
    display: flex;
    align-items: center;
    border-radius: 16px;
    border: 1px solid transparent;
    font-size: rem(20px);
    line-height: (24 / 20);
    letter-spacing: -0.6px;
    transition: background-color .3s $ease-out-quad;

    &::before {
      @include position(absolute, 0px);
      border: 1px solid transparent;
    }

    &::after {
      @include position(absolute, -3px);
      z-index: -1;
    }

    &::before,
    &::after {
      content: '';
      border-radius: 16px;
      opacity: 0;
      transition: opacity .3s $ease-out-quad;
    }

    .svg:not(.svg-caret-down) {
      margin-right: 9px;
    }

    .svg {
      fill: currentColor;
    }

    .svg--caret-down {
      margin-left: auto;
      transition: transform .3s $ease-out-quad;
    }

    &[aria-expanded="true"] {
      .svg--caret-down {
        transform: rotate(180deg);
      }
    }

    .no-touchevents &:hover,
    .no-touchevents &:focus {
      &::after {
        opacity: 1;
      }
    }
  }
}
