// Used by backend to load modules, not included by us
.loading-module {
    @include padding(40px null);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    min-height: 160px;
    // https://loading.io/asset/444866
    background-image: image('decorations/loading-spinner.gif');
    background-position: center;
    background-size: 100px 100px;
    background-repeat: no-repeat;
    height: 100%;
}
