[data-view="template-list-curriculum"] {
  .header--basic {
    padding-bottom: 100px;

    .center {
      &::after {
        content: none;
      }
    }
  }

  .content-archive__items {
    position: relative;

    &::after,
    &::before {
      content: '';
    }

    &::after {
      bottom: -40px;
      left: -45px;
    }

    &::before {
      top: -40px;
      right: -45px;
    }
  }
}
