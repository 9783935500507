.module-card-workshop {
  &:not(.module-card-workshop--disabled) {
    .module-card__inner {
      a,
      .button {
        position: relative;
        z-index: 6;
        pointer-events: auto;
      }
    }
  }
  
  .module-card__inner {
    @include position(relative);
    @include padding(null 32px);
    z-index: 6;
    display: flex;
    pointer-events: none;

    @include mq($until: 'm') {
      flex-direction: column;
    }

    a:not(.button) {
      text-decoration: underline;
      transition: color .3s $ease-out-quad;
    }
  }
  
  &__column {
    &:first-child {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      max-width: 203px;
      padding-right: 32px;
      border-right: 1px solid $brand-blue-light;

      @include mq($until: 'm') {
        max-width: 100%;
        padding-right: 0;
        border-right: 0;
        margin-bottom: 33px;
      }

      .button {
        margin-top: auto;

        @include mq($until: 'm') {
          width: 100%;
        }

        @include mq($until: 's') {
          text-align: center;
          margin-top: 16px;
          order: 2;
        }

        + .button {
          margin-top: 12px;
        }
      }
    }

    &:nth-child(2) {
      margin-left: 65px;
      width: 100%;

      @include mq($until: 'm') {
        margin-left: 0;
      }
    }

    &-title {
      width: 100%;
      margin-bottom: 18px;
      padding-bottom: 18px;
      border-bottom-width: 1px;
      border-style: solid;

      @include mq($until: 's') {
        order: 2;
      }
    }
  }

  &__date {
    font-size: rem(36px);
    letter-spacing: -.8px;
    line-height: (40 / 36);

    @include mq($until: 'm', $from: 's') {
      br {
        display: none;
      }
    }
    
    small {
      font-size: rem(20px);
      letter-spacing: .5px;
      line-height: (24 / 20);
    }

    + .module-card-workshop__hour {
      margin-top: 8px;
    }
  }

  &__hour {
    font-size: rem(13px);
    line-height: (20 / 13);
  }

  &__title {
    max-width: calc(100% - 190px);
    font-size: rem(36px);
    letter-spacing: -.8px;
    line-height: (40 / 36);

    @include mq($from: 'xxl') {
      max-width: 720px;
    }

    @include mq($until: 'm') {
      max-width: 100%;
      font-size: rem(28px);
      letter-spacing: -.6px;
    }
  }

  &__subtitle {
    margin-top: 9px;
    font-size: rem(15px);
    letter-spacing: -.8px;
    line-height: (20 / 15);

    @include mq($until: 'm') {
      font-size: rem(14px);
      letter-spacing: -.7px;
    }
  }

  &__text {
    margin-top: 20px;
    font-size: rem(18px);
    letter-spacing: -.6px;
    line-height: (28 / 18);

    @include mq($from: 'xl') {
      max-width: calc(100% - 168px);
    }

    @include mq($until: 'm') {
      font-size: rem(16px);
      letter-spacing: -.5px;
    }
  }

  &__profiles {
    margin-top: 36px;
    display: flex;
    flex-wrap: wrap;

    .eyebrow {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__profile {
    display: flex;
    align-items: center;

    &-content {
      width: calc(100% - 72px);
      max-width: 460px;
      font-size: rem(15px);
      letter-spacing: -.8px;
      line-height: (20 / 15);

      @include mq($until: 'm') {
        font-size: rem(14px);
        letter-spacing: -.7px;
      }
    }

    &-picture {
      @include size(60px);
      margin-right: 12px;
      flex-shrink: 0;
      border-radius: 50%;
      overflow: hidden;
      border: 4px solid $brand-white;

      @include mq($until: 'm') {
        @include size(56px);
      }

      img {
        display: block;
        width: 100%;
      }
    }

    &:first-of-type {
      margin-left: -4px;
    }
    
    &:not(:last-of-type) {
      margin-right: -30px;
    }
  }

  &__categories {
    width: 100%;

    @include mq($until: 's') {
      order: 2;
    }

    &:first-child {
      padding-bottom: 12px;
      border-bottom-width: 1px;
      border-style: solid;
    }

    &-item {
      @include padding(8px null);
      display: flex;
      align-items: center;

      .svg {
        margin-right: 12px;
      }
    }
  }

  &__tags {
    @include position(absolute, 40px 32px null null);
    display: inline-flex;
    margin-right: -8px;
    margin-left: auto;
    justify-content: flex-end;
    width: 100%;
    flex-shrink: 0;

    @include mq($until: 's') {
      position: relative;
      top: 0;
      right: auto;
      order: 1;
      justify-content: flex-start;
      margin-right: 0;
      margin-left: 0;
    }

    &-item {
      margin-right: 8px;
      flex-shrink: 0;
      margin-bottom: 8px;
    }
  }

  &--disabled {
    filter: grayscale(100%);

    .module-card-workshop__column {
      opacity: .3;
    }

    .module-card__link {
      pointer-events: none;
    }
  }

  &--s {
    .module-card-workshop__profile-content {
      display: none;
    }

    .module-card-workshop__title {
      font-size: rem(24px);
      letter-spacing: -.5px;
      line-height: (28 / 24);
    }

    .module-card-workshop__text {
      max-width: 100%;
    }
    
    .module-card-workshop__date {
      font-size: rem(24px);
      letter-spacing: -.5px;
      line-height: (28 / 24);
      
      small {
        font-size: rem(18px);
        letter-spacing: .4px;
        line-height: (24 / 18);
      }
    }
  }
}
