.content-faq {
  padding-top: 80px;
  padding-bottom: 80px;

  .module-header {
    margin-bottom: 65px;
  }

  .module-faq {
    @include mq($from: 'm') {
      margin-right: gs(1, 1);
      margin-left: gs(1, 1);
    }

    @include mq($from: 'xxl') {
      margin-right: gs(1, 1, 1392px, 12, 48px);
      margin-left: gs(1, 1, 1392px, 12, 48px);
    }

    @include mq($until: 'm') {
      @include margin(null auto);
      max-width: 560px;
    }
  }
}
