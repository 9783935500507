.widget-profiles {
  &__item {
    border-radius: 16px;
    border-color: $brand-blue-light;

    &-title {
      @include font('sans-serif', 'bold');
      color: $brand-grey-darker;
    }

    &-text,
    &-metas {
      color: $brand-grey;
    }

    &-metas {
      @include font('sans-serif', 'book');
    }

    &-text {
      @include font('sans-serif', 'regular');
    }

    &-expand {
      border-color: $brand-blue-light;
    }
  }
}
