.module-perks {
  &__item {
    &-title {
      @include font('sans-serif', 'bold');
      color: $brand-blue-bright;

      .svg {
        fill: $brand-blue-bright;
      }
    }

    &-text {
      @include font('sans-serif', 'regular');
      color: $brand-grey;
    }

    + .module-perks__item {
      border-color: $brand-blue-light;
    }
  }
}
