.header--detail {
  padding-bottom: 70px;

  @include mq($until: 'l') {
    padding-bottom: 45px;
  }
  
  .center {
    &::after {
      @include size(100%, 1px);
      @include margin(80px null null);
      content: '';
      display: block;
      background-color: $brand-blue-light;

      @include mq($until: 'l') {
        margin-top: 50px;
      }
    }
  }

  .header-inner {
    max-width: 100%;
    text-align: left;

    &__title {
      @include mq($until: 'm') {
        @include padding(null 0);
      }
    }

    &__text {
      @include mq($until: 'm') {
        @include margin(null 0);
      }
    }
  }
}
