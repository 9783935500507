.button-icon {
  @include position(relative);
  @include size(auto, auto);
  @include position(relative);
  @include padding(9px 8px 9px 22px);
  min-height: 52px;
  display: inline-flex;
  align-items: center;
  border-radius: 26px;
  font-size: rem(18px);
  line-height: 1;
  letter-spacing: -0.3px;
  transition: clip-path 1.1s $ease-out-expo;
  border: 3px solid transparent;

  .button-icon__label {
    transition: opacity 1.1s $ease-out-expo;
  }

  &::before {
    @include size(100%, 100%);
    @include position(absolute, 0 null null 0);
    content: '';
    border-radius: 26px;
    z-index: -1;
    transition: transform 1.15s $ease-out-expo;
  }

  span {
    &:not(.svg) {
      display: flex;
      align-items: center;

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 100%;
        will-change: width;
        transition: width 0.3s $ease-out-quart;
      }
    }
  }

  .svg {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    margin-left: 18px;
    border-radius: 50%;
    transition: transform 1.1s $ease-out-expo, fill 1.1s $ease-out-expo;
  }

  .svg--play {
    svg {
      margin-left: 3px;
    }
  }

  &::after {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    content: '';
    z-index: -1;
    opacity: 0;
    border-radius: 26px;
    background-color: transparent;
    transition: opacity 0.3s $ease-out-quart;
  }

  &--l {
    @include mq($from: 'l') {
      @include padding(11px null);
      font-size: rem(20px);
      border-radius: 30px;
      
      &::before {
        border-radius: 30px;
      }
  
      .svg {
        @include size(40px);
      }
    }

  }

  .no-touchevents &:hover,
  .no-touchevents &:focus {
    span:not(.svg) {
      &::after {
        width: 8px;
      }
    }
  }

  .no-touchevents &:active {
    &::after {
      opacity: 1;
    }
  }

  html:not(.mobile):not(.ie) .js-in-view & {
    clip-path: inset(50% 50%);

    .button-icon__label {
      opacity: 0;
    }

    &::before {
      transform: scale(0);
    }

    .svg--arrow-right {
      transform: translateX(-30px);
    }
  }

  html:not(.mobile):not(.ie) .js-in-view:not(.in-view) & {
    .svg {
      fill: transparent;
    }
  }

  html:not(.mobile):not(.ie) .js-in-view.in-view & {
    clip-path: inset(0% 0%);

    .button-icon__label {
      opacity: 1;
    }

    &::before {
      transform: scale(1);
    }

    .svg--arrow-right {
      transform: translateX(0px);
    }
  }
}
