.widget {
  &__title {
    @include font('sans-serif', 'bold');
    color: $brand-blue-bright;

    &::after {
      background-color: $brand-blue-light;
    }
  }
}
