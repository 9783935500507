.tooltip {
  padding: 0;
  border: 0;
  border-radius: 50%;
  min-height: 12px;
  min-width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
                            
  &--bubble {
    @include size(18px);
    font-size: rem(12px);
    
    .tooltip-label {
      &::before {
        font-family: Georgia, 'Times New Roman', Times, serif;
        content: 'i';
      }
    }
  }
}