.grid-helper {
  @include position(fixed, 0 null null 0);
  @include size(100%);
  z-index: 100000;
  pointer-events: none;

  &__inner {
    @include size(100%);
    @include padding(null 30px);
    display: flex;
    justify-content: space-between;
    box-sizing: initial;
    border-right: 1px solid rgba(green, 0.1);
    border-left: 1px solid rgba(green, 0.1);

    @include mq($from: 'xl') {
      max-width: 1160px;
      margin-right: auto;
      margin-left: auto;
    }

    @include mq($from: 'xxl') {
      max-width: 1392px;
    }

    @include mq($until: 'xl') {
      max-width: calc(100% - 60px);
    }
  }

  &__column {
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 1;
    background-color: rgba(red, 0.1);

    + .grid-helper__column {
      margin-left: 40px;

      @include mq($from: 'xxl') {
        margin-left: 48px;
      }
    }
  }
}
