.module-card-habit {
  .module-card__inner {
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
    text-align: center;

    &::before {
      content: none;
    }
  }

  &__title {
    font-size: rem(24px);
    line-height: (28 / 24);
    letter-spacing: -0.5px;
  }

  &__text {
    margin-top: 12px;
    font-size: rem(16px);
    line-height: (22 / 16);
    letter-spacing: -0.5px;
  }

  &__icon {
    @include position(relative);
    @include size(64px);
    @include margin(-32px auto 8px);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    img {
      display: block;
      width: 27px;
    }
  }

  &__image {
    @include aspect-ratio(360, 240);
    @include size(calc(100% + 80px), auto);
    @include margin(null null null -40px);
    display: block;
    overflow: hidden;
    border-radius: 16px;

    @include mq($until: 'm') {
      margin-left: -32px;
      width: calc(100% + 64px);
    }

    img {
      @include position(absolute, 0 null null 0);
      @include size(100%, 100%);
      @include object-fit(cover);
      display: block;
    }
  }
}
