.widget-quote {
  padding-left: 40px;
  border-left: 6px solid transparent;

  @include mq($until: 'm') {
    padding-left: 24px;
  }

  p {
    font-size: rem(28px);
    line-height: (32 / 28);
    letter-spacing: -0.6px;

    @include mq($until: 'm') {
      font-size: rem(24px);
      letter-spacing: -.5px;
    }

    + cite {
      margin-top: 24px;
    }
  }

  cite {
    display: block;
    font-size: rem(13px);
    line-height: (24 / 13);
    letter-spacing: 0.7px;
  }
}
