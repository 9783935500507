.module-quote {
  @include position(relative);
  @include size(100%, auto);
  display: block;

  @include mq($until: 'm') {
    text-align: center;
  }
  
  &__inner {
    @include position(relative);
    @include size(100%, 100%);
    @include padding(40px);
  }

  &__image {
    @include position(absolute, -40px null null calc(50% - 40px));
    @include size(80px);
    border-radius: 50%;

    @include mq($from: 'm') {
      display: none;
    }

    img {
      width: 100%;
      display: block;
      border-radius: 50%;
    }

    + cite {
      margin-top: 46px;
    }
  }

  cite {
    display: block;
    margin-bottom: 15px;
    font-size: rem(13px);
    line-height: (24 / 13);
    letter-spacing: 0.7px;
  }

  p {
    font-size: rem(20px);
    line-height: (24 / 20);
    letter-spacing: -0.4px;

    @include mq($until: 'm') {
      font-size: rem(18px);
    }
  }

  .button {
    margin-top: 20px;
  }
}
