.header {
  @include position(relative);
  @include padding(220px null 120px);
  z-index: 8;

  @include mq($until: 'l') {
    @include padding(200px null null null);
  }

  @include mq($until: 'm') {
    @include padding(170px null null null);
  }

  &-inner {
    max-width: gs(6, 7);
    margin-right: auto;
    margin-left: auto;

    @include mq($until: 'm') {
      max-width: 560px;
    }

    @include mq($from: 'xxl') {
      max-width: gs(6, 7, 1392px, 12, 48px);
    }

    &__text {
      max-width: gs(6, 5);
      margin-right: auto;
      margin-left: auto;

      @include mq($from: 'xxl') {
        max-width: gs(6, 5, 1392px, 12, 48px);
      }

      @include mq($until: 'm') {
        max-width: 400px;

        br {
          display: none;
        }
      }
    }
  }

  &-filters {
    max-width: gs(8, 7);
    margin-top: 65px;
    margin-right: auto;
    margin-left: auto;

    @include mq($until: 'm') {
      max-width: 288px;
    }

    @include mq($from: 'xxl') {
      max-width: gs(8, 7, 1392px, 12, 48px);
    }
  }

  .module-image {
    z-index: -1;
  }
}
