.header--landing-main {
  .module-image {
    &:nth-child(1) {
      @include position(absolute, 170px null null 50%);
      @include margin(null null null 320px);
      max-width: 80px;

      @include mq($until: 'm') {
        margin-left: 270px;
        top: 190px;
      }

      @include mq($until: 's') {
        margin-left: 200px;
      }

      @include mq($until: 'xs') {
        margin-left: 150px;
        top: 150px;
      }
    }

    &:nth-child(2) {
      @include position(absolute, null null 90px 50%);
      @include margin(null null null -660px);
      max-width: 300px;

      @include mq($until: 'l') {
        margin-left: -570px;
      }

      @include mq($until: 'm') {
        max-width: 180px;
        margin-left: -380px;
      }

      @include mq($until: 's') {
        margin-left: -320px;
      }

      @include mq($until: 'xs') {
        margin-left: -280px;
      }

      .circle--1 {
        @include position(absolute, auto 80px 75px auto);
        @include size(8px);

        @include mq($until: 'm') {
          bottom: 42px;
          right: 42px;
        }
      }
    }

    &:nth-child(3) {
      @include position(absolute, null null -75px 50%);
      @include margin(null null null 340px);
      max-width: 300px;

      @include mq($until: 'l') {
        margin-left: 230px;
      }

      @include mq($until: 'm') {
        max-width: 177px;
        bottom: -30px;
        margin-left: 100px;
      }

      @include mq($until: 'xs') {
        margin-left: 70px;
      }

      .circle--1 {
        @include position(absolute, auto 45px 20px auto);
        @include size(24px);

        @include mq($until: 'm') {
          bottom: 0px;
        }
      }

      .circle--2 {
        @include position(absolute, auto 10px 45px auto);
        @include size(8px);
        
        @include mq($until: 'm') {
          bottom: 25px;
        }
      }
    }
  }
}
