[data-view="template-home"] {
  .circles {
    .circle--1 {
      @include position(absolute, -2265px null null 50%);
      @include size(3280px);
      @include margin(null null null -1640px);
    }

    .circle--2 {
      bottom: -1400px;
    }
  }
}
