.dropdown {
  &__list {
    &-item {
      &-link {
        color: $brand-blue;

        .no-touchevents &:hover,
        .no-touchevents &:focus {
          background-color: rgba($brand-grey, .1);
        }
      }
    }
  }
}