.content-trip-pricing {
  &::after,
  &::before {
    @include position(absolute, null null null 50%);
    @include size(4px, 65px);
    @include margin(null null null -2px);
    content: '';
    border-radius: 2px;
    background-color: $brand-blue-light;
  }

  &::after {
    bottom: 0;
  }

  &::before {
    top: 0;
  }

  &__desktop {
    position: relative;
    border-radius: 16px;
    border: 1px solid $brand-blue-light;
    background-color: $brand-white;

    &::after {
      @include position(absolute, -40px -40px null null);
      @include size(240px, 300px);
      content: '';
      z-index: -1;
      background-image: url('../assets/images/decorations/dot-pattern-2@2x.png');
      background-repeat: repeat;
      background-position: 50% 50%;
      background-size: 38px auto;
    }
  }
}
