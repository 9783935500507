.module-card-join {
  text-align: center;

  .module-card__inner {
    @include padding(60px null);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $brand-green-bright;

    &::before {
      display: none;
    }
  }

  &__title {
    @include margin(null auto);
    @include font('sans-serif', 'bold');
    max-width: 240px;
    color: $brand-grey-darker;
    font-size: rem(24px);
    line-height: (28 / 24);
    letter-spacing: -.5px;
  }

  &__text {
    @include margin(null auto);
    max-width: 240px;
    margin-top: 18px;
    font-size: rem(16px);
    line-height: (24 / 16);
    letter-spacing: -.5px;
  }

  &__button {
    margin-top: 50px;
  }

  &__link {
    @include font('sans-serif', 'medium');
    margin-top: 18px;
    color: $brand-grey-darker;
    font-size: rem(16px);
    line-height: (24 / 16);
    letter-spacing: -.5px;
    text-decoration: underline;
    transition: color .3s $ease-out-quad;

    .no-touchevents &:focus,
    .no-touchevents &:hover {
      color: $brand-purple-bright;
    }
  }
}