.site-header {
  &__bar {
    border-color: rgba($brand-grey-darker, .05);
  }

  &__logo {
    @include padding(null 36px null 35px);
    border-color: rgba($brand-grey-darker, .05);

    @include mq($until: 1280px) {
      @include padding(null 32px null 30px);
    }

    &-inhub {
      width: 134px;
      flex-shrink: 0;
      margin-bottom: -13px;

      @include mq($until: 1280px) {
        width: 98px;
      }

      &-text {
        fill: $brand-grey-darker;
      }
  
      &-dot {
        fill: $brand-blue;
      }
    }

    &-thf {
      width: 40px;
      margin-right: 4px;
      margin-bottom: 5px;
      flex-shrink: 0;

      @include mq($until: 1280px) {
        width: 29px;
        margin-bottom: 1px;
      }

      &-bg {
        fill: $brand-grey-darker;
      }
  
      &-text {
        fill: $brand-white;
      }
    }
  }

  &__menu-button {
    border-color: rgba($brand-grey-darker, .05);

    &-bar {
      background-color: $brand-blue;
    }
  }

  &__desktop-submenu-backdrop {
    background-color: $brand-blue-lighter;
  }
  
  &__dropdown {
    .dropdown__button {
      border-color: $brand-blue-light;
      color: rgba($brand-grey-darker, .4);
    }
  }

  &__submenu-close-button {
    @include font('sans-serif', 'bold');
    color: $brand-blue;
    font-size: rem(14px);
    line-height: (20 / 14);
    letter-spacing: .5px;
  }

  &__nav {
    @include mq($until: 'xl') {
      background-color: $brand-blue-lighter;
    }
  }

  &__secondary-nav {
    .button {
      @include mq($until: 'xl') {
        @include padding(21px null);
        height: 52px;
        font-size: rem(11px);
      }
    }
    
    @include mq($until: 'xl') {
      &:last-child {
        border-bottom: 1px solid rgba($brand-grey-darker, .05);
      }

      &:first-child {
        @include mq($until: 'xl') {
          .site-header__secondary-nav-item:not(:first-child) {
            .site-header__secondary-nav-item-button {
              border: 1px solid $brand-blue-light;
            }
          }
        }
      }
    }

    &-item {
      &-select-wrapper {
        .svg {
          fill: rgba($brand-grey-darker, .4);
        }
      }

      &-select {
        @include font('sans-serif', 'bold');
        font-size: rem(12px);
        line-height: (16 / 12);
        letter-spacing: .4px;
        color: rgba($brand-grey-darker, .4);
        border: 1px solid $brand-blue-light;

        @include mq($until: 'xl') {
          font-size: rem(14px);
        }
      }
      
      &-button {
        @include font('sans-serif', 'bold');
        font-size: rem(12px);
        line-height: (16 / 12);
        letter-spacing: .4px;
        color: rgba($brand-grey-darker, .4);
        text-decoration: underline;

        .no-touchevents &:hover,
        .no-touchevents &:focus {
          color: $brand-grey-darker;
        }

        @include mq($until: 'xl') {
          font-size: rem(14px);
        }
      }
    }
  }

  &__primary-nav {
    &-item {
      &-button {
        @include font('sans-serif', 'medium');
        color: $brand-grey-darker;
        font-size: rem(17px);
        line-height: (24 / 17);
        letter-spacing: -.3px;

        @include mq($until: 1360px) {
          font-size: rem(16px);
        }

        @include mq($until: 1280px) {
          font-size: rem(15px);
        }

        @include mq($until: 'xl') {
          @include font('sans-serif', 'medium');
          @include padding(14px null 14px null);
          font-size: rem(24px);
        }

        @include mq($until: 'm') {
          font-size: rem(20px);
        }
        
        
        &-label {
          &::before {
            background-color: $brand-blue;
          }
        }

        .svg {
          fill: $brand-blue;
        }
      }
    }
  }

  &__submenu {
    @include mq($until: 'xl') {
      background-color: $brand-blue-lighter;
    }

    &-content {
      &-title {
        @include font('sans-serif', 'bold');
        color: $brand-grey-darker;
        font-size: rem(22px);
        line-height: (26 / 22);
        letter-spacing: -.6px;
      }

      &-text {
        @include font('sans-serif', 'regular');
        color: $brand-grey;
        font-size: rem(16px);
        line-height: (24 / 16);
        letter-spacing: -.5px;
      }
    }

    &-nav {
      &-item {
        &:not(:last-child) {
          border-color: $brand-blue-light;
        }

        &-label {
          color: $brand-grey;
        }

        &-button {
          color: $brand-blue-bright;

          .no-touchevents &:hover,
          .no-touchevents &:focus {
            color: $brand-purple-bright;
          }
        }
      }
    }
  }
}
