.header-filters {
  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: -12px;
    margin-left: -12px;

    @include mq($until: 'm') {
      flex-direction: column;
    }
  }

  &__item {
    flex: 1;
    padding-right: 12px;
    padding-left: 12px;

    &:first-child {
      z-index: 10;
    }
    
    &:nth-child(2) {
      z-index: 9;
    }

    &:nth-child(3) {
      z-index: 8;
    }

    &:nth-child(4) {
      z-index: 7;
    }

    @include mq($until: 'm') {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
