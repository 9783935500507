.content-keypoints {
  padding-top: 0;
  padding-bottom: 0;

  .content__column {
    margin-top: 60px;
    margin-bottom: 60px;
    min-width: initial;
    max-width: initial;

    @include mq($until: 'l') {
      margin-top: 0;
      margin-bottom: 0;

      &:last-child {
        .content-keypoints__list {
          .content-keypoints__item:last-child {
            margin-bottom: 0;
          } 
        }
      }
    }
  }

  .content__inner {
    @include mq($until: 'l') {
      @include padding(110px null);
      flex-direction: column;
    }

    @include mq($until: 'm') {
      @include padding(70px null)
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq($until: 'l') {
      flex-direction: column;
    }
  }

  &__item {
    text-align: center;
    
    @include mq($until: 'l', $from: 'm') {
      text-align: center;
    }

    @include mq($until: 'l') {
      @include size(100%, auto);
      @include margin(null null 22px);
    }

    @include mq($until: 'm') {
      text-align: left;
    }
  }

  &__index {
    margin-top: -8px;
    margin-bottom: 15px;
    font-size: rem(11px);
    line-height: (14 / 11);
    letter-spacing: 3px;
    text-transform: uppercase;

    @include mq($until: 'l') {
      margin-top: 0;
      flex-shrink: 0;
    }
  }

  &__text {
    font-size: rem(20px);
    line-height: (24 / 20);
    letter-spacing: -0.5px;

    @include mq($until: 'xl', $from: 'l') {
      font-size: rem(18px);
    }

    @include mq($until: 'l') {
      @include position(relative, -3px null null);
      @include size(100%, auto);
      @include margin(null null null 13px);
      
      br {
        display: none;
      }
    }
  }

  &__line {
    display: none;
  }
}
