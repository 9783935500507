.sidebar-menu {
  ul {
    margin-top: -12px;

    @include mq($until: 'l') {
      @include padding(null 44px);
      margin-left: -44px;
      display: flex;
      width: calc(100% + 88px);
      overflow: auto;
      
      &::after {
        width: 24px;
        height: 1px;
        content: '';
        flex-shrink: 0;
      }

      li {
        flex-shrink: 0;
        margin-right: 20px;
      }
    }
  }

  a {
    @include position(relative);
    @include size(100%, auto);
    @include padding(12px null);
    display: block;
    font-size: rem(11px);
    line-height: (22 / 11);
    letter-spacing: 3px;
    text-decoration: none;
    text-transform: uppercase;
    transition: color .3s $ease-out-quad;

    &::after {
      @include position(absolute, null null 0 0);
      @include size(100%, 3px);
      content: '';
      transform: scaleY(0.333);
      transform-origin: 50% 100%;
    }
  }

  + * {
    margin-top: 90px;
    
    @include mq($until: 'm') {
      margin-top: 50px;
    }
  }
}
