.content-cards-slider {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    &--centered {
      justify-content: center;
      margin-right: auto;
      margin-bottom: 70px;
      margin-left: auto;
      max-width: 800px;
      text-align: center;
    }
  }

  .content-cards-slider__header {
    .module-header {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0;
      text-align: left;

      + .content-cards-slider__controls {
        margin-left: 10px;
      }
    }

    &--centered {
      .module-header {
        max-width: gs(6, 5);
        margin-right: auto;
        margin-left: auto;
        text-align: center;

        @include mq($from: 'xxl') {
          max-width: gs(6, 5, 1392px, 12, 48px);
        }
      }
    }
  }

  &__title {
    font-size: rem(36px);
    line-height: (40 / 36);
    letter-spacing: -1px;

    @include mq($from: 'xxl') {
      font-size: rem(40px);
    }
  }

  &__text {
    font-size: rem(20px);
    line-height: (28 / 20);
    letter-spacing: -0.4px;
  }

  &__controls {
    display: flex;
    align-items: center;

    .button {
      transition: opacity .3s $ease-out-quad;
    }

    .button:last-child {
      margin-left: 12px;
    }

    .button[disabled] {
      opacity: 0;
      cursor: not-allowed;
    }
  }

  &__list {
    position: relative;

    .content-cards-slider__controls {
      @include position(absolute, 50% null null 0);
      @include size(100%, auto);
      z-index: 4;
      pointer-events: none;
      transform: translateY(-50%);
      justify-content: space-between;

      @include mq($until: 'm') {
        display: none;
      }

      .button {
        pointer-events: auto;
        box-shadow: 0 6px 15px 0 rgba(6,41,78,0.08);
        
        &:first-child {
          transform: translateX(-50%);
        }

        &:last-child {
          transform: translateX(50%);
        }
      }
    }
  }

  &__footer {
    margin-top: 50px;
    text-align: center;
  }

  .tns-slider {
    display: flex;
  }

  .tns-item {
    transition: opacity .4s .1s $ease-out-quad, visibility .4s .1s $ease-out-quad;
  }
  
  .tns-item[aria-hidden="true"] {
    @include mq($from: 'l') {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }

  .module-card {
    @include mq($until: 'l') {
      width: 336px;
      flex-shrink: 0;
    }
  }

  &--activity {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  &--disabled {
    .content-cards-slider__title {
      width: 100%;
      text-align: center;
    }
  }
}
