.sidebar-callout {
  @include position(relative);
  @include padding(48px 52px);
  @include margin(null -15px);
  text-align: center;

  @include mq($until: 'l') {
    @include padding(32px 40px);
  }

  &__title {
    font-size: rem(20px);
    line-height: (24 / 20);
    letter-spacing: -0.5px;

    .svg--arrow-right {
      @include mq($until: 'm') {
        display: none;
      }
    }
  }

  &__text {
    margin-top: 16px;
    font-size: rem(13px);
    line-height: (20 / 13);
    letter-spacing: -0.4px;

    @include mq($until: 'l') {
      display: none;
    }
  }
  
  &__button {
    margin-top: 32px;

    @include mq($until: 'm') {
      margin-top: 20px;
    }
  }

  + * {
    margin-top: 48px;
  }
}
