.module-perks {
  margin-top: -35px;
  margin-bottom: -32px;

  &__item {
    padding-top: 35px;
    padding-bottom: 32px;

    @include mq($from: 'xxl') {
      padding-right: gs(1, 0, 1392px, 12, 48px);
    }

    &-title {
      position: relative;
      font-size: rem(24px);
      line-height: (28 / 24);
      letter-spacing: -0.6px;

      @include mq($until: 'm') {
        font-size: rem(20px);
        letter-spacing: -.5px;
      }

      .svg {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;

        &--check {
          top: 5px;
        }
      }
    }

    &-text {
      @include margin(16px null null);
      @include padding(null gs(1, 0) null null);
      font-size: rem(16px);
      line-height: (22 / 16);
      letter-spacing: -0.5px;
    }

    + .module-perks__item {
      border-top: 1px solid transparent;
    }

    &:first-child {
      padding-top: 0;
    }
  }

  &--l {
    .module-perks__item {
      &-text,
      &-title {
        padding-left: 40px;
      }

      &-text {
        padding-right: 0;
      }

      &-title {
        .svg {
          opacity: 1;
        }
      }
    }
  }
}
