.module-image-basic {
  @include aspect-ratio(1, 1);

  @include mq($until: 'm') {
    @include margin(null auto);
    max-width: 560px;
  }

  img {
    display: block;
    width: 100%;
  }

  &__item {
    @include position(absolute, 0 null null 0);
    @include size(100%, 100%);
    @include object-fit(contain, center);
    display: block;
    width: 100%;
  }
}
