.module-form {
  &__toggle {
    @include font('sans-serif', 'medium');
    color: $brand-blue-bright;
  }

  &__group {
    legend {
      @include font('sans-serif', 'bold');
      border-color: rgba($brand-blue, 0.2);
      color: $brand-grey-darker;
    }
  }

  &__footer {
    border-color: rgba($brand-blue, 0.2);
  }

  &--premium {
    .module-form__group {
      legend {
        border-color: rgba($brand-green-bright, 0.3);
      }
    }

    .module-form__select:after {
      background-image: url('../assets/images/icons/select-arrow-premium@2x.png');
    }

    .module-form__field .module-form__input,
    .module-form__checkbox label:before {
      border-color: $brand-green-bright;
    }
  
    .module-form__footer {
      border-color: rgba($brand-green-bright, 0.3);
    }
  }

  &--togglable {
    &:not(.module-form--disabled) {
      .module-form__toggle {
        color: $brand-purple-bright;
      }

      .module-form__field:not(.module-form__field--error) {
        .module-form__input {
          border-color: $brand-purple-bright;
        }
      }
    }
  }
}
