.content-archive {
  &__items {
    position: relative;

    &::after,
    &::before {
      z-index: -1;
      position: absolute;
      width: 240px;
      height: 300px;
      background-image: url('../assets/images/decorations/dot-pattern-2@2x.png');
      background-repeat: repeat;
      background-position: 50% 50%;
      background-size: 38px auto;
    }
  }
}
