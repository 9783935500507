.button-line {
  @include font('sans-serif', 'bold');

  &--blue {
    color: $brand-blue-bright;

    span {
      fill: $brand-blue-bright;

      &:not(.svg) {
        &::before {
          background-color: rgba($brand-blue-bright, .3);
        }
      }
    }

    .no-touchevents &:active,
    .no-touchevents &:hover {
      span:not(.svg) {
        &::before {
          background-color: $brand-blue-bright;
        }
      }
    }
  }
  
  &--orange {
    color: $brand-orange-bright;

    span {
      fill: $brand-orange;

      &:not(.svg) {
        &::before {
          background-color: $brand-orange;
        }
      }
    }

    .no-touchevents &:active {
      span:not(.svg) {
        &::before {
          background-color: $brand-orange-bright;
        }
      }
    }
  }

  &--purple-bright {
    color: $brand-purple-bright;

    span {
      fill: $brand-purple;

      &:not(.svg) {
        &::before {
          background-color: $brand-purple;
        }
      }
    }

    .no-touchevents &:active {
      span:not(.svg) {
        &::before {
          background-color: $brand-purple-bright;
        }
      }
    }
  }

  &--grey-darker {
    color: $brand-grey-darker;

    span {
      fill: $brand-grey-light;

      &:not(.svg) {
        &::before {
          background-color: $brand-grey-light;
        }
      }
    }

    .no-touchevents &:active {
      span:not(.svg) {
        &::before {
          background-color: $brand-grey-darker;
        }
      }
    }
  }

  &[disabled] {
    color: #4d5a7c;

    span:not(.svg) {
      &::before {
        background-color: rgba(#4d5a7c, 0.2);
      }
    }
  }
}
