.module-capture {
  &__list {
    border-radius: 16px;
    background-color: $brand-blue-lighter;
    box-shadow: -1px 0 15px rgba($brand-grey-darker, 0.1), 0px 20px 30px rgba($brand-blue-bright, 0.1);
  }

  &__toggle {
    svg {
      fill: $brand-blue-bright;
    }
  }

  &__dots {
    button {
      background-color: $brand-blue-light;
    }

    .is-active {
      button {
        background-color: transparent;
      }
    }

    li:not(.is-active) {
      button {
        .no-touchevents &:hover,
        .no-touchevents &:focus {
          background-color: darken($brand-blue-light, 10%);
        }
      }
    }

    svg {
      stroke: $brand-blue;
    }
  }

  &__caption {
    @include font('sans-serif', 'regular');
    color: $brand-grey;
  }
}
