.module-card-workshop {
  .module-card__inner {
    a:not(.button) {
      color: $brand-blue-bright;

      .no-touchevents &:hover,
      .no-touchevents &:focus {
        color: $brand-purple-bright;
      }
    }
  }

  &__column {
    &-title {
      border-color: $brand-blue-light;
    }
  }

  &__date {
    @include font('sans-serif', 'bold');
  }

  &__hour {
    @include font('sans-serif', 'medium');
  }

  &__date,
  &__hour {
    color: $brand-blue-bright;
  }

  &__title {
    @include font('sans-serif', 'bold');
    color: $brand-grey-darker;
  }

  &__categories {
    &:first-child {
      border-color: $brand-blue-light;
    }

    &-item {
      @include font('sans-serif', 'bold');
      color: $brand-grey;
      font-size: rem(12px);
      line-height: (16 / 12);
      letter-spacing: 0.8px;
      text-transform: uppercase;

      .svg {
        fill: $brand-blue;
      }
    }
  }

  &__profile {
    @include font('sans-serif', 'book');
    
    &-title {
      color: $brand-grey-darker;
    }

    &-text {
      color: $brand-grey;
    }
  }
}
