.content-quotes {
  --positionTop: 0px;
  --positionBottom: 0px;

  padding-top: 0;
  padding-bottom: 0;

  .content__column {
    min-width: initial;
    max-width: initial;
    margin-top: 60px;
    margin-bottom: 12px;

    &:first-child {
      margin-right: gs(0, 1);

      @include mq($from: 'xxl') {
        margin-right: gs(0, 1, 1392px, 12, 48px);
      }
    }

    &:last-child {
      margin-left: gs(0, 1);

      @include mq($from: 'xxl') {
        margin-left: gs(0, 1, 1392px, 12, 48px);
      }

      @include mq($until: 'm') {
        margin-top: 0px;

        .module-quote {
          margin-top: 0;
        }
      }
    }
  }

  &__mobile {
    @include mq($from: 'm') {
      display: none;
    }

    .content-quotes__button {
      margin-top: 30px;
      text-align: center;
    }
  }

  &__desktop {
    @include mq($until: 'm') {
      display: none;
    }

    .module-quote {
      margin-bottom: 48px;
  
      &--3 {
        max-width: gs(4, 3);
  
        @include mq($from: 'xxl') {
          max-width: gs(3, 3, 1392px, 12, 48px);
        }
      }

      &--2 {
        margin-top: 120px;
      }
    }
  }

  &__images {
    @include position(absolute, null null null 50%);
    @include size(140px);
    @include margin(60px null null -70px);
    top: var(--positionTop);
    bottom: var(--positionBottom);

    &-list {
      @include position(absolute, 50% null null 50%);
      @include size(80px);
      @include margin(-40px null null -40px);
      overflow: hidden;
      border-radius: 50%;
      border: 4px solid transparent;

      img {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        opacity: 0;
        border-radius: 50%;

        &.is-active {
          opacity: 1;
        }
      }
    }
  }

  &__mobile {
    margin-top: 90px;
  }

  &__slider {
    display: flex;
  }

  &__expand {
    [aria-expanded="false"] {
      .content-quotes__expand-expanded {
        display: none;
      }
    }

    [aria-expanded="true"] {
      .content-quotes__expand-unexpanded {
        display: none;
      }

      .svg--caret-down {
        transform: rotate(180deg);
      }
    }
  }

  &__expand,
  &__button {
    display: block;
    margin-bottom: 48px;
  }

  &__hidden {
    display: none;

    &.is-visible {
      display: block;
    }
  }

  &--multiple {
    .module-quote {
      @for $n from 0 through 5 {
        $a: ($n * 5) + 1;
        $b: ($n * 5) + 2;
        $c: ($n * 5) + 3;
        $d: ($n * 5) + 4;
        $e: ($n * 5) + 5;

        max-width: 100%;

        &--#{$a},
        &--#{$d} {
          max-width: gs(4, 4);

          @include mq($from: 'xxl') {
            max-width: gs(4, 4, 1392px, 12, 48px);
          }
        }

        &--#{$e} {
          max-width: gs(4, 3);

          @include mq($from: 'xxl') {
            max-width: gs(4, 3, 1392px, 12, 48px);
          }
        }
      }
    }
  }

  html:not(.mobile):not(.ie) &.js-in-view {
    .content-quotes__images {
      svg {
        transform: rotate(90deg) scale(0);
        transition: transform 0.8s .1s $ease-out-cubic;
      }

      &-list {
        clip-path: circle(8px);
        transition: clip-path .5s $ease-out-quad, background-color .2s .5s $ease-out-quad;

        img {
          opacity: 0;
          transform: scale(1.15);
          transition: opacity .3s $ease-out-quad, transform .5s .2s $ease-out-quad;
        }
      }
    }

    &.in-view {
      .content-quotes__images {
        svg {
          transform: rotate(0deg) scale(1);
        }

        &-list {
          clip-path: circle(50%);
          background-color: $brand-white;

          img {
            transform: scale(1);

            &.is-active {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
