.module-pagination {
  display: flex;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 28px;
  border-top: 1px solid transparent;

  &__button {
    display: flex;
    align-items: center;
    font-size: rem(15px);
    line-height: (20 / 15);
    letter-spacing: -0.8px;
    fill: currentColor;

    .svg {
      transition: transform 0.3s $ease-out-quart;
    }

    &--prev {
      margin-right: auto;

      .no-touchevents &:hover,
      .no-touchevents &:focus {
        .svg {
          transform: translateX(-5px);
        }
      }
    }
    
    &--next {
      margin-left: auto;

      .no-touchevents &:hover,
      .no-touchevents &:focus {
        .svg {
          transform: translateX(5px);
        }
      }
    }

    @include mq($until: 's') {
      display: none;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-left: auto;

    &-item {
      padding: 5px 11px;
      border: 1px solid transparent;
      border-radius: 6px;
      font-size: rem(16px);
      line-height: (22 / 16);
      letter-spacing: -0.5px;
      transition: color 0.3s $ease-out-quart;
    }

    span {
      position: relative;
      top: -4px;
    }
  }
}
