// Kennel Settings
// -----------------------------------------------------------------------------
$base-url: '../assets/';
$base-font-size: 16px;
$base-line-height: 2;

// Random Variables
// -----------------------------------------------------------------------------
$PI: 3.1415926536;

// Overrides Default Color Palette
// -----------------------------------------------------------------------------
$brand-black: #000;
$brand-white: #fff;

$brand-blue: #3396ff;
$brand-blue-bright: #0067e2;
$brand-blue-light: #d6eaff;
$brand-blue-lighter: #e5f7fe;
$brand-blue-lightest: rgba(#e5f7fe, 0.3);

$brand-grey: #4d5a7c;
$brand-grey-light: rgba(#06294e, 0.3);
$brand-grey-dark: rgba(#06294e, 0.9);
$brand-grey-darker: #06294e;

$brand-purple: rgba(#9d46d3, 0.2);
$brand-purple-bright: #9d46d3;

$brand-ic-purple: #a51890;

$brand-green: #b2f6e6;
$brand-green-bright: #00e0ac;
$brand-green-dark: #10A884;
$brand-green-darker: #0d8c7b;

$brand-yellow: #fbba02;

$brand-orange: rgba(#ff5f24, 0.2);
$brand-orange-bright: #ff5f24;

$default-colors: (
  social-twitter: #04aceb,
  social-facebook: #3b5998,
  social-linkedin: #0085af,
  social-youtube: #cd201f,
  social-instagram: #e12f67,

  alert-valid: #61a450,
  alert-warning: orange,
  alert-error: #f6482e,
);

// Layers Manager
// -----------------------------------------------------------------------------
$z-layers: (
  above: 999,
  highest: 60,
  higher: 50,
  high: 40,
  low: 20,
  lower: 10,
  lowest: 0,
  below: -1,
);

// Font Stack Declaration
// -----------------------------------------------------------------------------
$base-font-stack: (
  sans-serif: (
    book: (
      family: ('FF-Mark', serif),
      weight: 400,
      style: normal,
      file: (
        family: 'FF-Mark',
        path: 'FF-Mark-Book',
        formats: (woff woff2)
      )
    ),
    book-italic: (
      family: ('FF-Mark', serif),
      weight: 400,
      style: italic,
      file: (
        family: 'FF-Mark',
        path: 'FF-Mark-Book-Italic',
        formats: (woff woff2)
      )
    ),
    regular: (
      family: ('FF-Mark-Pro', serif),
      weight: 400,
      style: normal,
      file: (
        family: 'FF-Mark-Pro',
        path: 'FF-Mark-Pro-Regular',
        formats: (woff woff2)
      )
    ),
    regular-italic: (
      family: ('FF-Mark-Pro', serif),
      weight: 400,
      style: italic,
      file: (
        family: 'FF-Mark-Pro',
        path: 'FF-Mark-Pro-Regular-Italic',
        formats: (woff woff2)
      )
    ),
    medium: (
      family: ('FF-Mark', serif),
      weight: 500,
      style: normal,
      file: (
        family: 'FF-Mark',
        path: 'FF-Mark-Medium',
        formats: (woff woff2)
      )
    ),
    medium-italic: (
      family: ('FF-Mark', serif),
      weight: 500,
      style: italic,
      file: (
        family: 'FF-Mark',
        path: 'FF-Mark-Medium-Italic',
        formats: (woff woff2)
      )
    ),
    bold: (
      family: ('FF-Mark-Pro', serif),
      weight: 700,
      style: normal,
      file: (
        family: 'FF-Mark-Pro',
        path: 'FF-Mark-Pro-Bold',
        formats: (woff woff2)
      )
    ),
    bold-italic: (
      family: ('FF-Mark-Pro', serif),
      weight: 700,
      style: italic,
      file: (
        family: 'FF-Mark-Pro',
        path: 'FF-Mark-Pro-Bold-Italic',
        formats: (woff woff2)
      )
    ),
    heavy: (
      family: ('FF-Mark-Pro', serif),
      weight: 900,
      style: normal,
      file: (
        family: 'FF-Mark-Pro',
        path: 'FF-Mark-Pro-Heavy',
        formats: (woff woff2)
      )
    ),
    heavy-italic: (
      family: ('FF-Mark-Pro', serif),
      weight: 900,
      style: italic,
      file: (
        family: 'FF-Mark-Pro',
        path: 'FF-Mark-Pro-Heavy-Italic',
        formats: (woff woff2)
      )
    )
  ),
);

// Responsive Settings
// -----------------------------------------------------------------------------
$mq-responsive: true;
$mq-static-breakpoint: l;
$mq-show-breakpoints: (xxs, xs, s, m, l, xl, xxl);
