.header--landing-video {
  .module-image {
    &:nth-child(1) {
      @include position(absolute, 180px null null 50%);
      @include margin(null null null -500px);
      max-width: 80px;

      @include mq($until: 'l') {
        margin-left: -380px;
      }

      @include mq($until: 'm') {
        margin-left: -300px;
        top: 210px;
      }

      @include mq($until: 's') {
        margin-left: -280px;
        top: 250px;
      }

      @include mq($until: 'xs') {
        margin-left: -240px;
      }

      .circle--1 {
        @include position(absolute, auto -20px -20px auto);
        @include size(8px);

        @include mq($until: 'm') {
          bottom: -10px;
          right: -8px;
        }
      }
    }

    &:nth-child(2) {
      @include position(absolute, null null 50px 50%);
      @include margin(null null null 340px);
      max-width: 300px;

      @include mq($until: 'l') {
        margin-left: 230px;
      }

      @include mq($until: 'm') {
        max-width: 177px;
        bottom: -30px;
        margin-left: 100px;
      }

      @include mq($until: 'xs') {
        margin-left: 70px;
      }

      .circle--1 {
        @include position(absolute, auto 0 65px auto);
        @include size(24px);

        @include mq($until: 'm') {
          bottom: 20px;
          right: 15px;
        }
      }

      .circle--2 {
        @include position(absolute, auto 30px 65px auto);
        @include size(8px);

        @include mq($until: 'm') {
          bottom: 30px;
          right: 0px;
        }
      }
    }
  }
}
