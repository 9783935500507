.module-card {
  &__link {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    @include padding(null 0);
    border: 0;
    z-index: 5;
    background: transparent;

    .no-touchevents &:focus,
    .no-touchevents &:hover {
      ~ .module-card__inner {
        .module-card__image-overlay {
          opacity: 1;
        }
      }
    }
  }

  &__inner-wrapper {
    @include position(relative);
    @include size(auto, 100%);
  }

  &__inner {
    @include size(auto, 100%);
    @include padding(38px 40px);
    display: block;
    overflow: hidden;

    @include mq($until: 'm') {
      @include padding(null 32px);
    }
  }

  &__image-overlay {
    @include position(absolute, 0 null null 0);
    @include size(100%, 100%);
    content: '';
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s $ease-out-quad;

    &::before,
    &::after {
      @include position(absolute, 0 null null 0);
      @include size(100%, 100%);
      content: '';
      z-index: 2;
    }
    
    &::before {
      background: rgba(0,181,247,0.60);
    }

    &::after {
      opacity: .6;
      background-image: linear-gradient(198deg, rgba(0,103,226,0.00) 0%, #0A0870 98%);
    }
  }
}
