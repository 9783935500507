.alert-bar {
  @include position(relative);
  text-align: center;
  transition: height .3s $ease-out-quad;
  overflow: hidden;
  
  p {
    @include padding(26px 70px);
    font-size: rem(16px);
    line-height: (20 / 16);
    letter-spacing: -.5px;

    @include mq($until: 'l') {
      @include padding(null 40px);
      font-size: rem(15px);
    }

    @include mq($until: 's') {
      @include padding(null 15px);
      font-size: rem(14px);
    }

    a {
      margin-left: 7px;
    }
  }

  &__button {
    @include position(absolute, 0 40px null null);
    @include size(40px, 100%);
    @include padding(0);
    background: transparent;
    border: 0;

    @include mq($until: 'l') {
      right: 20px;
    }

    @include mq($until: 's') {
      right: 0;
    }

    .svg {
      transition: transform .3s $ease-out-quad;
    }

    &:hover {
      .svg {
        transform: rotate(90deg);
      }
    }
  }

  &.is-hidden {
    height: 0px !important;
  }
}