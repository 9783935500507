.module-card-small {
  &__icon {
    background-color: $brand-blue-lighter;

    + .module-card-small__title {
      color: $brand-grey-darker;
    }
  }

  &__title {
    @include font('sans-serif', 'bold');
    color: $brand-blue-bright;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-grey;
  }
}
