.form-filter {
  &__button {
    @include font('sans-serif', 'bold');
    color: $brand-grey-darker;
    background: $brand-white;
    border-color: #ADD5FF;

    &::before {
      border-color: #ADD5FF;
    }

    &::after {
      background-color: rgba($brand-blue, .2);
    }

    .svg:not(.svg--caret-down) {
      fill: $brand-blue;
    }
  }

  &__dropdown {
    background-color: $brand-white;
    box-shadow: 0 10px 40px 0 rgba(6,41,78,0.08);

    &-footer {
      border-color: $brand-blue-light;
    }

    &-clear {
      @include font('sans-serif', 'medium');
      color: $brand-grey;
    }
  }

  .module-form__checkbox {
    label {
      color: $brand-blue-bright;

      &::after {
        background-image: url('../assets/images/icons/checked-white@2x.png');
      }
      
      &::before {
        border-color: $brand-blue-bright;
      }
    }

    input:checked {
      + label::before {
        border-color: $brand-blue-bright;
        background-color: $brand-blue-bright;
      }
    }
  }
}
