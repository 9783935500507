.module-capture {
  &__list {
    @include size(100%, auto);
    @include margin(null null 32px);
    @include aspect-ratio(660px, 440px);
    z-index: 0;
    max-width: 100%;
    overflow: hidden;

    @include mq($until: 'm') {
      display: block;
      max-width: 560px;
      margin-right: auto;
      margin-left: auto;
    }

    &::before{
        padding-top:72%!important;
    }
  }

  &__item {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    opacity: 0;
    z-index: 0;

    img {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      @include object-fit(cover);
    }

    &:nth-child(1) {
      opacity: 1;
      z-index: 10;
    }

    &.is-active {
      opacity: 1;
      z-index: 10;
    }

    &.is-inactive {
      opacity: 0;
      z-index: 0;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__toggle {
    margin-right: 16px;

    .svg--play {
      display: none;
    }

    .svg--pause {
      display: block;
    }

    &.is-paused {
      .svg--play {
        display: block;
      }

      .svg--pause {
        display: none;
      }
    }
  }

  &__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;

    li {
      min-height: 8px;
      max-height: 8px;

      + li {
        margin-left: 16px;
      }

      &.is-active {
        svg {
          animation: 5s linear both;

          @include animation-keyframes {
            0% {
              stroke-dashoffset: 2 * $PI * 4;
            }

            100% {
              stroke-dashoffset: 2 * $PI * 0;
            }
          }
        }
      }

      &.is-inactive {
        svg {
          animation: 1s $ease-out-expo both;

          @include animation-keyframes {
            0% {
              stroke-dashoffset: 2 * $PI * 0;
            }

            100% {
              stroke-dashoffset: 2 * $PI * -4;
            }
          }
        }
      }
    }

    button {
      @include position(relative);
      @include size(8px);
      display: block;
      border-radius: 50%;
      transition: background-color 0.3s $ease-out-quart;
    }

    svg {
      @include position(absolute, 50% null null 50%);
      @include size(12px);
      @include margin(-6px null null -6px);
      fill: transparent;
      stroke-width: 2px;
      stroke-dasharray: 2 * $PI * 4;
      stroke-dashoffset: 2 * $PI * 4;
      transform: rotate(-90deg);
    }
  }

  &__captions {
    margin-top: 24px;
    font-size: rem(13px);
    line-height: (20 / 13);
    letter-spacing: -0.4px;
    text-align: center;

    &-item{
        &.is-inactive{
            display:none;
        }
        &.is-active{
            display:block;
        }
    }
  }
}
