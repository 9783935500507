.module-popup-general {
  @include padding(75px 110px);
  text-align: center;

  @include mq($until: 'm') {
    @include padding(null 32px);
  }

  .module-header__title {
    font-size: rem(34px);
    line-height: (38 / 34);
    letter-spacing: -1px;

    @include mq($until: 'm') {
      font-size: rem(24px);
      letter-spacing: -.6px;
    }
  }
}

.js-popup-general {
  .popup__content {
    max-width: 760px;
  }
}