.header--landing-testimonials {
  .module-image {
    &:nth-child(1) {
      @include position(absolute, 170px null null 50%);
      @include margin(null null null -400px);
      max-width: 80px;

      @include mq($until: 'l') {
        margin-left: -360px;
      }

      @include mq($until: 'm') {
        top: 130px;
        margin-left: -270px;
      }

      @include mq($until: 's') {
        margin-left: -230px;
      }
    }

    &:nth-child(2) {
      @include position(absolute, 245px null null 50%);
      @include margin(null null null 340px);
      max-width: 300px;

      @include mq($until: 'l') {
        margin-left: 260px;
      }

      @include mq($until: 'm') {
        max-width: 180px;
        margin-left: 200px;
        bottom: 230px;
      }

      @include mq($until: 's') {
        margin-left: 165px;
      }

      @include mq($until: 'xs') {
        margin-left: 110px;
        bottom: 290px;
      }

      .circle--1 {
        @include position(absolute, 45px auto auto 40px);
        @include size(8px);

        @include mq($until: 'm') {
          top: -20px;
        }
      }
    }

    &:nth-child(3) {
      @include position(absolute, null null 10px 50%);
      @include margin(null null null -660px);
      max-width: 300px;

      @include mq($until: 'l') {
        margin-left: -500px;
      }

      @include mq($until: 'm') {
        max-width: 177px;
        margin-left: -270px;
        bottom: -30px;
      }

      .circle--1 {
        @include position(absolute, 0px null null 90px);
        @include size(24px);

        @include mq($until: 'm') {
          @include size(16px);
          top: 0px;
          left: 15px;
        }
      }

      .circle--2 {
        @include position(absolute, 0px null null 60px);
        @include size(8px);

        @include mq($until: 'm') {
          top: 0px;
          left: 10px;
        }
      }
    }

    &:nth-child(4) {
      @include position(absolute, null null -90px 50%);
      @include margin(null null null 280px);
      max-width: 120px;
    }
  }
}
