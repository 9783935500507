.module-card-image {
  .module-card__inner {
    padding-top: 0;
    padding-bottom: 73px;

    @include mq($until: 'm') {
      padding-bottom: 45px;
    }
  }

  .module-card__dot {
    &::before {
      background-image: image('decorations/card-small-arrow-white@2x.png');
    }
  }

  .module-card__link:hover {
    ~ .module-card__inner {
      .module-card-image__image {
        &::before {
          opacity: 1;
        }
        
        &::after {
          opacity: .6;
        }
      }
    }
  }

  &__title {
    @include position(relative);
    @include margin(null null 18px);
    font-size: rem(24px);
    letter-spacing: -0.5px;
    line-height: (28 / 24);
  }

  &__text {
    font-size: rem(16px);
    line-height: (24 / 16);
    letter-spacing: -0.5px;
  }

  &__tag {
    @include position(relative, -4px null null null);
    @include margin(null null null 4px);
    font-size: rem(10px);
    letter-spacing: 1px;
  }

  &__image {
    @include aspect-ratio(432, 264);
    @include size(calc(100% + 80px), auto);
    @include margin(null null 45px -40px);
    display: block;

    img {
      @include position(absolute, 0 null null 0);
      @include size(100%, 100%);
      @include object-fit(cover, center);
      display: block;
    }
  }
}
