.widget-video {
  border-radius: 16px;

  &__play {
    z-index: 3;

    .svg {
      fill: $brand-blue;
    }
  }

  &__poster {
    &::before,
    &::after {
      @include size(100%, 100%);
      @include position(absolute, 0 null null 0);
      content: '';
      z-index: 2;
    }
    
    &::before {
      background: rgba(0,181,247,0.60);
    }
    
    &::after {
      background-image: linear-gradient(198deg, rgba(0,103,226,0.00) 0%, #0A0870 98%);
      opacity: .6;
    }

    .no-touchevents &:hover,
    .no-touchevents &:focus {
      .widget-video__play {
        background-color: $brand-blue;
      }
    }
  }
}
