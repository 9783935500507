.widget-location {
  &__inner {
    display: flex;
    align-items: center;

    @include mq($until: 's') {
      flex-direction: column-reverse;
    }
  }

  &__content {
    flex: 1;
    margin-right: gs(1, 1);

    @include mq($until: 'm') {
      margin-right: 20px;
    }

    @include mq($until: 's') {
      width: 100%;
      margin-right: 0;
      margin-top: 35px;
    }

    @include mq($from: 'xxl') {
      margin-right: gs(1, 1, 1392px, 12, 48px);
    }
  }

  &__location {
    font-size: rem(24px);
    line-height: (28 / 24);
    letter-spacing: -0.7px;

    @include mq($until: 'm') {
      font-size: rem(20px);
      letter-spacing: -.5px;
    }
  }

  &__address {
    margin-top: 16px;
    font-size: rem(16px);
    line-height: (24 / 16);
    letter-spacing: -0.5px;

    a {
      color: currentColor;
      text-decoration: none;
    }
  }

  &__button {
    margin-top: 24px;
  }

  &__image {
    flex-basis: gs(4, 4);

    @include mq($until: 'm') {
      flex-basis: 50%;
    }
    
    @include mq($until: 's') {
      width: 100%;
      flex-basis: auto;
    }

    @include mq($from: 'xxl') {
      flex-basis: gs(4, 4, 1392px, 12, 48px);
    }
  }
}
