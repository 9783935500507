.widget {
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    font-size: rem(11px);
    line-height: (22 / 11);
    letter-spacing: 3px;
    text-transform: uppercase;

    @include mq($until: 'm') {
      margin-bottom: 30px;
    }

    &::after {
      content: '';
      flex: 1;
      width: auto;
      height: 1px;
      margin-left: 12px;
    }
  }

  + .widget {
    margin-top: 80px;

    @include mq($until: 'm') {
      margin-top: 60px;
    }
  }
}
