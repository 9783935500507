.widget-included {
  &__item {
    &-title {
      @include font('sans-serif', 'bold');
      color: $brand-grey-darker;
    }

    &-text {
      @include font('sans-serif', 'regular');
      color: $brand-grey;
    }

    &::before {
      @include font('sans-serif', 'bold');
      color: $brand-blue;
    }
  }
}
