.module-form__field,
.module-form__radio,
.module-form__checkbox {
  label {
    display: block;
    margin-bottom: 12px;
    font-size: rem(18px);
    line-height: (24 / 18);
    letter-spacing: -0.5px;
    text-align: left;

    a {
      text-decoration: underline;
    }
  }

  small {
    margin-top: 14px;
    font-size: rem(13px);
    line-height: (16 / 13);
    letter-spacing: -0.4px;
  }
}

select {
  background-color: #fff;
  appearance: none;

  &::-ms-expand {
    display: none;
  }
}

.module-form__field {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  > *:not(.tooltip) {
    width: 100%;
  }
  
  textarea {
    min-height: 260px;
    resize: vertical;
  }

  // ios shadows
  input[type=text],
  input[type=email],
  input[type=password],
  textarea {
    -webkit-appearance: none;
  }

  .payment-methods {
    @include position(absolute, 2px 0 null null);
    justify-content: flex-end;

    @include mq($until: 'm') {
    }

    @include mq($until: 's') {
      position: relative;
      top: auto;
      right: auto;
      margin-top: 10px;
    }
  }

  .tooltip {
    @include position(absolute, 2px 24px null null);

    @include mq($until: 'm') {
      right: 20px;
    }
  }

  .module-form__radio,
  .module-form__checkbox,
  .module-form__checkbox-radio {
    &--s {
      margin-top: 14px;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .module-form__input {
    @include size(100%, 65px);
    @include padding(21px 24px);
    border-radius: 12px;
    border: 1px solid transparent;
    font-size: rem(16px);
    line-height: (22 / 16);
    letter-spacing: -0.5px;

    &:disabled {
      background-color: #fff;
      opacity: 1;
    }
  }

  .module-form__inner {
    position: relative;

    &::after {
      @include position(absolute, 50% 30px null null);
      @include size(10px, 10px);
      @include margin(-5px null null);
      pointer-events: none;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 10px auto;
    }
  }

  &-link {
    font-size: rem(13px);
    line-height: 16px;
    letter-spacing: -0.4px;
  }

  .module-form__password {
    position: relative;

    &-show {
      @include position(absolute, 0 0 null null);
      @include padding(null 20px null 10px);
      @include size(auto, 100%);
      background: transparent;
      border: 0;
    }
  }

  &--date {
    .module-form__inner {
      .module-form__input {
        padding-right: 75px;
      }

      &::after {
        @include size(18px, 20px);
        @include margin(-10px null null);
        content: '';
        background-size: 18px auto;
      }
    }
  }

  &--narrow {
    max-width: 50%;

    @include mq($until: 'm') {
      max-width: 100%;
    }
  }
}

.module-form__select {
  position: relative;

  .module-form__input {
    padding-right: 60px;
  }

  &::after {
    @include position(absolute, 50% 30px null null);
    @include size(10px, 5px);
    @include margin(-3px null null);
    content: '';
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 10px auto;
    pointer-events: none;
  }
}

.module-form__radio,
.module-form__checkbox,
.module-form__checkbox-radio {
  label {
    @include position(relative);
    @include margin(null null 0);
    @include padding(null null null 36px);
    line-height: 24px;
    
    &::after,
    &::before {
      content: '';
    }

    &::before {
      @include position(absolute, 0 null null 0);
      @include size(24px);
      border: 1px solid transparent;
      background-color: $brand-white;
      transition: background-color .3s $ease-out-quad;
    }

    &::after {
      opacity: 0;
      transition: opacity .3s .1s $ease-out-quad;
    }
  }

  input:focus {
    + label {
      body.keyboard-user & {
        outline: auto 1px -webkit-focus-ring-color;
      }
    }
  }

  input:checked {
    + label {
      &::after {
        opacity: 1;
      }
    }
  }

  &--s {
    label {
      padding-left: 24px;
      font-size: rem(13px);
      line-height: 16px;
      letter-spacing: -0.4px;

      &::before {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.module-form__radio {
  label {
    &::after,
    &::before {
      border-radius: 50%;
    }

    &::after {
      @include position(absolute, 7px null null 7px);
      @include size(10px);
    }
  }

  &--s {
    label {
      &::after {
        @include position(absolute, 4px null null 4px);
        @include size(8px);
      }
    }
  }
}

.module-form__checkbox-radio {
  label {
    padding-left: 38px;

    &::before {
      @include size(32px, 16px);
      border-radius: 8px;
      border: 0;
      background-color: rgba($brand-green-bright, .3);
    }
    
    &::after {
      @include position(absolute, 1px null null 1px);
      @include size(14px);
      border-radius: 50%;
      background-color: $brand-white;
      background-image: none;
      opacity: 1;
      transition: transform .3s $ease-out-quad;
      transform: translateX(16px);
    }
  }

  input {
    &:checked {
      + label {
        &::before {
          background-color: $brand-green-bright;
        }
        
        &::after {
          transform: translateX(0);
        }
      }
    }
  }
}

.module-form__checkbox {
  label {
    &::before {
      border-radius: 6px;
    }

    &::after {
      @include position(absolute, 0 null null 0);
      @include size(24px);
      background-size: 14px auto;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &--s {
    label {
      &::before {
        border-radius: 4px;
      }

      &::after {
        width: 16px;
        height: 16px;
        background-size: 9px auto;
      }
    }
  }
}
