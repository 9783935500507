.button-pill {
  @include position(relative);
  @include size(auto, 40px);
  @include padding(16px 23px);
  border-radius: 30px;
  font-size: rem(10px);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 1;
  transition: background-color 0.3s $ease-out-quart, border-color 0.3s $ease-out-quart, color 0.3s $ease-out-quart;

  &::after {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    content: '';
    z-index: -1;
    opacity: 0;
    border-radius: 30px;
    transition: opacity 0.3s $ease-out-quart;
  }

  &--bordered {
    padding-top: 14px;
    padding-bottom: 14px;
    border: 2px solid transparent;

    &::after {
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
    }
  }

  .no-touchevents &:active {
    &::after {
      opacity: 1;
    }
  }
}
