.content-column {
    padding-top: 0;
    padding-bottom: 0;

    .module-faq,
    .module-text,
    .module-perks,
    .module-facts-slider {
        @include mq($from: 'm') {
            margin-right: gs(1, 1);
            margin-left: gs(1, 1);
        }

        @include mq($from: 'xxl') {
            margin-left: gs(1, 1, 1392px, 12, 48px);
            margin-right: gs(1, 1, 1392px, 12, 48px);
        }

        @include mq($until: 'xl', $from: 'm') {
            @include margin(null auto);
        }

        @include mq($until: 'm') {
            @include margin(null auto);
            max-width: 560px;
        }
    }

    .module-text {
        &__header {
            margin-bottom: -25px;
        }

        &__content {
            @include mq($from: 'm') {
                margin-top: 55px;
            }
        }
    }

    .module-faq {
        @include mq($from: 'm') {
            margin-top: 25px;
        }
    }

    .module-perks,
    .module-capture {
        @include mq($from: 'm') {
            margin-top: 40px;
        }
    }

    .module-video {
        @include mq($from: 'm') {
            margin-top: -70px;
        }

        @include mq($from: 'xl') {
            margin-top: -215px;
        }
    }

    .module-image-basic {
        @include mq($from: 'm') {
            margin-top: 60px;
            margin-right: gs(1, 0);
            margin-left: gs(1, 0);
        }
    }

    &:not(.content-quotes) .content__column {
        @include mq($from: 'm') {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        @include mq($from: 'l') {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        @include mq($until: 'm') {
            &:first-child {
                margin-bottom: 5px;
            }
        }
    }

    .content__inner {
        @include mq($until: 'm') {
            flex-direction: column;
        }
    }

    &--reflect {
        .content__inner {
            @include mq($from: 'm') {
                flex-direction: row-reverse;
            }
        }

        .content__column {
            &:first-child {
                .module-faq,
                .module-text,
                .module-perks,
                .module-facts-slider {
                    @include mq($until: 'xl', $from: 'm') {
                        @include padding(null null null 30px);
                    }
                }

                .module-image,
                .module-video,
                .module-quote {
                    @include mq($from: 'm') {
                        margin-left: auto;
                    }
                }

                .module-quote {
                    margin-right: auto;
                }

                .module-video {
                    @include mq($from: 'xl') {
                        margin-left: 160px;
                    }

                    @include mq($until: 'm') {
                        margin-bottom: -40px;
                    }
                }

                .module-capture {
                    margin-left: gs(1, 1);

                    @include mq($from: 'xxl') {
                        margin-left: gs(1, 1, 1392px, 12, 48px);
                    }

                    @include mq($until: 'l', $from: 'm') {
                        margin-right: 0px;
                        margin-left: 30px;
                    }

                    @include mq($until: 'm') {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }
            }

            &:last-child {
                .module-faq,
                .module-text,
                .module-perks,
                .module-facts-slider {
                    @include mq($until: 'xl', $from: 'm') {
                        @include padding(null 30px null null);
                    }
                }

                .module-image,
                .module-video {
                    @include mq($from: 'm') {
                        margin-right: auto;
                    }
                }

                .module-quote {
                    margin-left: auto;
                }

                .module-video {
                    @include mq($from: 'm') {
                        margin-left: -20px;
                    }

                    @include mq($from: 'xl') {
                        margin-left: -250px;
                    }

                    @include mq($until: 'm') {
                        margin-bottom: -40px;
                    }
                }

                .module-capture {
                    margin-right: gs(1, 1);

                    @include mq($from: 'xxl') {
                        margin-right: gs(1, 1, 1392px, 12, 48px);
                    }

                    @include mq($until: 'l', $from: 'm') {
                        margin-left: 0;
                        margin-right: 30px;
                    }

                    @include mq($until: 'm') {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &--mobile-reflect {
        .content__inner {
            @include mq($until: 'm') {
                flex-direction: column-reverse;
            }
        }

        .content__column {
            &:first-child {
                @include mq($until: 'm') {
                    margin-bottom: 0;
                }
            }
        }

        .module-image {
            @include mq($until: 'm') {
                margin-top: -30px;
                margin-bottom: -20px;
            }
        }
    }

    &:not(.content-column--reflect) {
        .content__column {
            &:first-child {
                .module-faq,
                .module-text,
                .module-perks,
                .module-facts-slider {
                    @include mq($until: 'xl', $from: 'm') {
                        @include padding(null 30px null null);
                    }
                }

                .module-image,
                .module-video {
                    @include mq($from: 'm') {
                        margin-right: auto;
                    }
                }

                .module-quote {
                    margin-left: auto;
                }

                .module-video {
                    @include mq($from: 'xl') {
                        margin-left: -250px;
                    }

                    @include mq($until: 'm') {
                        margin-bottom: -40px;
                    }
                }

                .module-capture {
                    margin-right: gs(1, 1);

                    @include mq($from: 'xxl') {
                        margin-right: gs(1, 1, 1392px, 12, 48px);
                    }

                    @include mq($until: 'l', $from: 'm') {
                        margin-left: 0;
                        margin-right: 30px;
                    }

                    @include mq($until: 'm') {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }

            &:last-child {
                .module-faq,
                .module-text,
                .module-perks,
                .module-facts-slider {
                    @include mq($until: 'xl', $from: 'm') {
                        @include padding(null null null 30px);
                    }
                }

                .module-image,
                .module-video {
                    @include mq($from: 'm') {
                        margin-left: auto;
                    }
                }

                .module-quote {
                    margin-right: auto;
                }

                .module-video {
                    @include mq($from: 'xl') {
                        margin-left: -250px;
                    }
                }

                .module-capture {
                    margin-left: gs(1, 1);

                    @include mq($from: 'xxl') {
                        margin-left: gs(1, 1, 1392px, 12, 48px);
                    }

                    @include mq($until: 'l', $from: 'm') {
                        margin-right: 0px;
                        margin-left: 30px;
                    }

                    @include mq($until: 'm') {
                        margin-right: 0;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}