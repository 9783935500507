.footer-join {
  padding-top: 60px;

  &__inner {
    @include position(relative);
    @include padding(120px null);
    z-index: 0;

    .module-header {
      max-width: gs(8, 7);
      margin-right: auto;
      margin-left: auto;
      text-align: center;

      @include mq($until: 'm') {
        max-width: 560px;
      }

      @include mq($from: 'xxl') {
        max-width: gs(8, 7, 1392px, 12, 48px);
      }

      &__title {
        @include mq($from: 'xxl') {
          font-size: rem(52px);
        }
      }
    }

    &::after {
      @include position(absolute, null null 0 0);
      @include size(100%, 152px);
      z-index: -1;
      background-position: 50%;
      background-repeat: repeat;
    }
  }
}
