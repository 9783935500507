.content-join {
  &::after,
  &::before {
    @include position(absolute, null null null 50%);
    @include size(4px, 50px);
    @include margin(null null null -2px);
    content: '';
    border-radius: 2px;
    background-color: $brand-blue-light;
  }

  &::after {
    bottom: 0;
  }

  &::before {
    top: 0;
  }

  &__bubble {
    @include margin(null auto);
    @include position(relative);
    display: flex;
    align-items: center;
    text-align: center;

    &-content {
      @include margin(null auto);
    }

    &::before {
      @include position(absolute, 50% null null 50%);
      @include size(100%);
      content: '';
      border-radius: 50%;
      background-color: $brand-green-bright;
      transform: translate(-50%, -50%);
      transition: transform 1.1s .1s $ease-out-expo;

      @include mq($until: 'm') {
        // display: none;
      }
    }
  }

  .module-image {
    &__dots {
      stroke: rgba($brand-green-dark, 0.4);
    }
  }

  &--l {
    .content-join__bubble {
      @include size(760px);

      @include mq($until: 'm') {
        @include size(600px);
      }

      @include mq($until: 700px) {
        width: 100%;
      }
      
      &::before {
        @include size(760px);

        @include mq($until: 'm') {
          @include size(600px);
        }
      }
    }
    
    .content-join__images {
      @include mq($until: 'm') {
        @include size(600px);
      }
    }

    .module-image {
      &:nth-child(1) {
        @include position(absolute, 95px -80px null null);
        max-width: 220px;

        @include mq($until: 'm') {
          top: -10px;
          right: 80px;
          max-width: 126px;
        }
      }

      &:nth-child(2) {
        @include position(absolute, null null 75px -175px);
        max-width: 340px;

        @include mq($until: 'm') {
          bottom: -65px;
          left: 45px;
          max-width: 204px;
        }
      }

      &:nth-child(3) {
        @include position(absolute, null -55px 120px null);
        max-width: 80px;

        @include mq($until: 'm') {
          bottom: -50px;
          right: 105px;
          max-width: 64px;
        }
      }
    }
  }

  &--s {
    .content-join__bubble {
      @include size(560px);

      @include mq($until: 'm') {
        @include size(520px);
      }
      
      @include mq($until: 700px) {
        width: 100%;
      }

      &::before {
        @include size(560px);

        @include mq($until: 'm') {
          @include size(520px);
        }
      }
    }

    .content-join__images {
      @include mq($until: 'm') {
        @include size(520px);
      }
    }

    .module-image {
      &:nth-child(1) {
        @include position(absolute, 50px -75px null null);
        max-width: 180px;

        @include mq($until: 'm') {
          top: -10px;
          right: -15px;
        }

        .circle--1 {
          @include position(absolute, -25px auto auto 50px);
          @include size(12px);
        }
      }

      &:nth-child(2) {
        @include position(absolute, null null 80px -65px);
        max-width: 64px;

        @include mq($until: 'm') {
          bottom: 0;
          left: 75px;
        }

        .circle--1 {
          @include position(absolute, auto 0 -15px auto);
          @include size(8px);
        }
      }
    }
  }

  &--list {
    &::after,
    &::before {
      content: none;
    }
  }

  &--icons {
    .content-join__bubble {
      &::after {
        @include position(absolute, 35px null null 35px);
        @include size(40px);
        content: '';
        border-radius: 50%;
        background-color: $brand-green;

        @include mq($until: 'm') {
          display: none;
        }
      }
    }

    &.content-join--l {
      .module-image {
        &:nth-child(2) {
          @include mq($from: 'm') {
            @include position(absolute, null null 100px -140px);
            max-width: 280px;
          }
        }
      }
    }
  }
}

html:not(.mobile):not(.ie) .js-in-view {
  .content-join {
    &__bubble::before {
      transform: translate(-50%, -50%) scale(0);
    }
  }

  &.in-view {
    .content-join {
      &__bubble::before {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
}