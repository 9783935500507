.module-form {
  position: relative;

  &__toggle {
    font-size: rem(13px);
    letter-spacing: -.4px;
    text-decoration: underline;
    border: 0;
    background: transparent;

    &:not(.module-form__toggle--inline) {
      @include position(absolute, 10px 0 null null);

      @include mq($until: 'm') {
        top: 5px;
      }
    }

    &--inline {
      display: inline-block;
      margin-bottom: 40px;
    }
  }

  &__group {
    legend {
      @include size(100%, auto);
      @include margin(null null 32px);
      @include padding(null null 25px);
      border-bottom: 1px solid transparent;
      font-size: rem(28px);
      line-height: (32 / 28);
      letter-spacing: -0.6px;

      @include mq($until: 'm') {
        font-size: rem(22px);
        letter-spacing: -.5px;
      }
      
      br {
        @include mq($until: 'm') {
          display: none;
        }  
      }
    }

    p {
      max-width: gs(6, 5);
      margin-bottom: 40px;
      font-size: rem(18px);
      line-height: (28 / 18);
      letter-spacing: -0.6px;

      @include mq($until: 'm') {
        font-size: rem(16px);
        line-height: (24 / 16);
        letter-spacing: -0.5px;
      }

      @include mq($from: 'xxl') {
        max-width: gs(6, 5, 1392px, 12, 48px);
      }
    }

    + .module-form__group {
      margin-top: 80px;
    }
  }

  &__footer {
    @include margin(65px null null);
    @include padding(40px null null);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-top: 1px solid transparent;

    @include mq($until: 'm') {
      margin-top: 40px;
      flex-direction: column;

      > div:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  + .module-form {
    margin-top: 80px;
  }

  // Dashboard feature
  &--togglable {
    legend {
      position: relative;
      padding-right: 40px;
    }

    .module-form__footer {
      margin-top: 0;
      border-top: 0;
    }

    &.module-form--disabled {
      label {
        cursor: initial;
      }

      .module-form__footer {
        display: none;
      }

      .module-form__enabled-only {
        display: none;

        + .module-form__disabled-only {
          margin-top: 0;
        }
      }
    }

    &:not(.module-form--disabled) {
      .module-form__disabled-only {
        display: none;

        + .module-form__enabled-only {
          margin-top: 0;
        }
      }
    }
  }
}
