.module-card-text {
  .module-card__inner {
    padding: 0;
    border-radius: 0;

    &::before {
      content: none;
    }
  }

  &__eyebrow {
    margin-bottom: 32px;
    padding-bottom: 12px;
    border-bottom: 1px solid transparent;
  }

  &__title {
    font-size: rem(24px);
    line-height: (28 / 24);
    letter-spacing: -0.5px;
  }

  &__text {
    margin-top: 16px;
    font-size: rem(18px);
    line-height: (28 / 18);
    letter-spacing: -0.6px;
  }
}
