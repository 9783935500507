.module-facts-slider {
  &__list {
    &-item {
      @include position(relative);
      @include padding(null null 35px);

      &-head {
        position: relative;
      }

      &-body {
        padding-bottom: 20px;
        
        @include mq($from: 'm') {
          display: none;
        }
      }

      &-eyebrow {
        margin-bottom: 15px;
      }
      
      &-title {
        margin-bottom: 18px;
        font-size: rem(28px);
        line-height: (32 / 28);
        letter-spacing: -0.5px;
        transition: color 0.3s $ease-out-quart;

        @include mq($until: 'm') {
          font-size: rem(20px);
        }
      }

      &-text {
        font-size: rem(16px);
        line-height: (22 / 16);
        letter-spacing: -0.5px;
      }

      &-button {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        border: 0;
        background: transparent;
        
        @include mq($until: 'm') {
          pointer-events: none;
        }
      }
      
      &__nav {
        @include position(absolute, null null 20px 0);
        @include size(100%, auto);
        display: flex;
        align-items: center;

        @include mq($until: 'm') {
          bottom: 10px;
        }
        
        &-button {
          @include position(absolute, 50% null null 0);
          @include padding(null null null 0);
          z-index: 2;
          flex-shrink: 0;
          border: 0;
          background: $brand-white;
          transform: translateY(-50%);
          visibility: hidden;

          @include mq($until: 'm') {
            display: none;
          }
        }

        &-progress {
          @include size(100%, 2px);
          @include position(relative);
          border-radius: 1px;

          &-bar {
            @include position(absolute, 0 null null 0);
            @include size(100%, 100%);
            display: block;
            transform: scale(0);
            transform-origin: 0 50%;
          }
        }
      }

      &-image {
        margin-top: 30px;
        display: block;
        width: 100%;

        img {
          display: block;
          width: 100%;
        }
      }

      &.is-active {
        .module-facts-slider__list-item-body {
          display: block;
        }

        .module-facts-slider__list-item__nav-button {
          visibility: visible;
        }
      }

      &:not(.is-active) {
        .module-facts-slider__list-item__nav-progress-bar {
          animation: none;
        }
      }

      &:not(:last-child) {
        margin-bottom: 35px;
      }
    }
  }
}

@keyframes factSliderBarAnimation {
  from {transform: scaleX(0);}
  to {transform: scaleX(1);}
}
