.dropdown {
  @include position(relative);

  &__button {
    @include position(relative);
    @include padding(0);
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    transition: box-shadow .3s $ease-out-quad, background-color .3s $ease-out-quad;

    .svg--caret-down {
      margin-left: 20px;
      fill: currentColor;
      transition: transform .3s $ease-out-quad;
    }
  }

  &__list {
    @include position(absolute, calc(100% + 5px) null null 0);
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    background-color: $brand-white;
    visibility: hidden;
    z-index: 5;
    box-shadow: 0 10px 40px 0 rgba(6,41,78,0.08);

    &-item {
      @include font('sans-serif', 'bold');
      font-size: rem(12px);
      letter-spacing: .4px;
      
      &-link {
        @include position(relative);
        @include padding(11px 12px);
        display: block;
        padding-right: 40px;
        transition: background-color .3s $ease-out-quad;

        .svg--arrow-right {
          @include position(absolute, 14px 10px null null);
          fill: currentColor;
        }
      }

      &:not(:last-child) {
        .dropdown__list-item-link {
          border-bottom: 1px solid $brand-blue-light;
        }
      }
    }
  }

  &.is-open {
    .dropdown__button {
      .svg--caret-down {
        transform: rotate(180deg);
      }
    }

    .dropdown__list {
      visibility: visible;
    }
  }

  &--top {
    .dropdown__list {
      top: auto;
      bottom: calc(100% + 5px);
    }
  }
}

.no-touchevents .dropdown:not(.is-open) .dropdown__button:hover:not(.is-open) {
  box-shadow: 0 3px 12px 0 rgba(6,41,78,0.08);
}