.sidebar-text {
  &__title {
    @include font('sans-serif', 'bold');
    color: $brand-blue-bright;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-grey;
  }

  &__button {
    a {
      @include font('sans-serif', 'medium');
      color: $brand-purple-bright;
    }
  }
}
