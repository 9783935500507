.content-key-numbers {
  padding-top: 0;
  padding-bottom: 0;

  .content__column {
    margin-top: 60px;
    margin-bottom: 60px;
    min-width: initial;
    max-width: initial;

    @include mq($until: 'l') {
      margin-top: 0;
      margin-bottom: 0;

      &:last-child {
        .content-key-numbers__list {
          .content-key-numbers__item:last-child {
            margin-bottom: 0;
          } 
        }
      }
    }
  }

  .content__inner {
    @include mq($until: 'l') {
      @include padding(110px null);
      flex-direction: column;
    }

    @include mq($until: 'm') {
      @include padding(70px null)
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq($until: 'l') {
      flex-direction: column;
    }
  }

  &__item {
    text-align: center;

    @include mq($from: 'xl') {
      flex-basis: gs(3, 2);
    }

    @include mq($until: 'l') {
      @include size(100%, auto);
      @include margin(null null 22px);
    }
  }

  &__value {
    margin-bottom: 6px;
    font-size: rem(42px);
    line-height: (60 / 42);
    letter-spacing: -0.5px;

    @include mq($until: 'l') {
      font-size: rem(32px);
      line-height: (50 / 32);
    }
  }

  &__text {
    font-size: rem(16px);
    line-height: (22 / 16);
    letter-spacing: -0.5px;

    @include mq($until: 'l') {
      br {
        display: none;
      }
    }
  }

  &__line {
    display: none;
  }
}
