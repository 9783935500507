.sidebar-callout {
  border-radius: 16px;
  background-color: $brand-green-bright;

  &__title {
    @include font('sans-serif', 'bold');
    color: $brand-grey-darker;
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-grey;
  }

  &::after {
    @include mq($from: 'l') {
      @include position(absolute, -20px null null -45px);
      @include size(240px, 300px);
      content: '';
      z-index: -1;
      background-image: url('../assets/images/decorations/dot-pattern-2@2x.png');
      background-repeat: repeat;
      background-position: 50% 50%;
      background-size: 38px auto;
    }
  }
}
