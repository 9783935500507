.payment-methods {
  display: flex;
  align-items: center;

  &__item {
    @include padding(null 2px);
    flex-shrink: 0;
    opacity: 0.3;
    transition: opacity .3s $ease-out-quad;

    .svg {
      fill: $brand-grey-darker;
    }

    &--is-active {
      opacity: 1;
    }
  }
}