.widget-videos {
  &__item {
    border-radius: 16px;
    border-color: $brand-blue-light;

    &-title {
      @include font('sans-serif', 'bold');
      color: $brand-grey-darker;
    }

    &-text {
      @include font('sans-serif', 'medium');
      color: $brand-grey;
    }
  }
}
