.dashboard-header {
  @include position(absolute, 0 null null 0);
  @include size(100%, auto);
  z-index: 900;

  @include mq($until: 'xl') {
    transition: transform .3s $ease-out-quad;

    // body.header-sticky & {
    //   position: fixed;
    //   top: -117px;
    // }

    body.menu-is-open & {
      position: fixed;
    }

    // body.header-sticky:not(.header-is-hidden) &,
    // body.header-sticky.menu-is-open & {
    //   transform: translateY(100%);
    // }
  }

  @include mq($until: 'm') {
    body.header-sticky & {
      position: fixed;
      top: -81px;
    }
  }

  &__bar {
    @include position(relative);
    padding-right: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid rgba($brand-grey-darker, .05);

    @include mq($until: 'xl') {
      padding-right: 0;
      display: flex;
      justify-content: space-between;

      &::before {
        @include position(absolute, 0 null null 0);
        @include size(100%, 100%);
        content: '';
        background-color: $brand-white;
        z-index: 2;
      }
      
      body.header-sticky &,
      body.menu-is-open & {
        background-color: $brand-white;
      }
    }

    @include mq($until: 'm') {
      min-height: 79px;
    }
  }

  &__logo {
    @include padding(26px 36px 26px 35px);
    display: flex;
    align-items: center;
    border-right: 2px solid rgba($brand-grey-darker, .05);

    @include mq($until: 1280px) {
      @include padding(null 32px null 30px);
    }

    @include mq($from: 'xl') {
      max-height: 122px;
    }

    @include mq($until: 'xl', $from: 'l') {
      max-height: 113px;
    }

    &-inhub {
      width: 134px;
      flex-shrink: 0;
      margin-bottom: -13px;

      @include mq($until: 1280px) {
        width: 98px;
      }

      &-text {
        fill: $brand-grey-darker;
      }
  
      &-dot {
        fill: $brand-blue;
      }
    }

    &-thf {
      width: 40px;
      margin-right: 4px;
      margin-bottom: 5px;
      flex-shrink: 0;

      @include mq($until: 1280px) {
        width: 29px;
        margin-bottom: 1px;
      }

      &-bg {
        fill: $brand-grey-darker;
      }
  
      &-text {
        fill: $brand-white;
      }
    }

    img {
      display: block;
      width: 220px;

      @include mq($until: 1280px) {
        width: 160px;
      }

      @include mq($until: 'm') {
        width: 140px;
      }
    }

    @include mq($until: 'xl') {
      position: relative;
      border: 0;
      z-index: 3;
    }
  }

  &__menu-button {
    @include padding(0);
    @include size(84px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 0;
    border-left: 2px solid rgba($brand-grey-darker, .05);

    @include mq($from: 'xl') {
      display: none;
    }

    @include mq($until: 'xl') {
      position: relative;
      z-index: 3;
    }

    @include mq($until: 'm') {
      @include size(79px);
    }

    &-bars {
      @include size(17px, 17px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-bar {
      @include size(100%, 3px);
      display: block;
      flex-shrink: 0;
      border-radius: 1.5px;
      
      background-color: $brand-blue;
      transition: transform .3s $ease-out-quad, opacity .2s $ease-out-quad;

      &:nth-child(1) {
        transform-origin: left center;
      }

      &:nth-child(3) {
        transform-origin: left center;
      }

      body.menu-is-open & {
        &:nth-child(1) {
          transform: translate(2px, 1px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translate(2px, -1px) rotate(-45deg);
        }
      }
    }
  }

  &__dropdown {
    width: 360px;

    .dropdown__button {
      @include position(relative);
      @include padding(7px 14px);
      @include font('sans-serif', 'bold');
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-shrink: 0;
      min-width: 200px;
      border: 1px solid $brand-blue-light;
      border-radius: 8px;
      font-size: rem(12px);
      line-height: (16 / 12);
      letter-spacing: .4px;
      color: $brand-grey-darker;

      @include mq($until: 's') {
        width: 100%;
        background-color: $brand-white;
      }
      
      .svg {
        flex-shrink: 0;
        fill: currentColor;

        &.svg--caret-down {
          margin-left: auto;
        }
      }
      
      .svg:not(.svg--caret-down) {
        margin-right: 10px;
      }
    }
  }

  &__nav {
    @include padding(null null null 40px);
    width: 100%;

    @include mq($until: 'xl') {
      @include position(fixed, 0 null null 0);
      @include padding(113px 30px null 30px);
      width: 100%;
      height: 100vh; /* Fallback */
      height: calc(var(--vh, 1vh) * 100);
      visibility: hidden;
      opacity: 0;
      overflow: auto;
      background-color: $brand-blue-lighter;
      transition: opacity .3s $ease-out-quad, visibility .3s $ease-out-quad;

      body.menu-is-open & {
        visibility: visible;
        opacity: 1;
      }
    }

    @include mq($until: 'm') {
      @include padding(79px null null null);
    }

    &-wrapper {
      @include mq($until: 'xl') {
        @include padding(40px null 40px null);
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
  
        body.menu-is-open & {
          visibility: visible;
        }
      }
    }
    
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @include mq($until: 'xl') {
        flex-direction: column;
      }
    }
  }

  &__secondary-nav {
    display: flex;
    align-items: center;

    @include mq($until: 'xl') {
      width: 100%;
    }

    @include mq($until: 'xl') {
      &:last-child {
        border-bottom: 1px solid rgba($brand-grey-darker, .05);
      }

      &:first-child {
        @include mq($until: 'xl') {
          .dashboard-header__secondary-nav-item:not(:first-child) {
            .dashboard-header__secondary-nav-item-button {
              border: 1px solid $brand-blue-light;
            }
          }
        }
      }
    }

    &:first-child {      
      @include mq($until: 'xl') {
        flex-direction: column;
        padding-top: 46px;
      }

      .dashboard-header__secondary-nav-item:not(:first-child) {
        @include mq($until: 'xl') {
          margin-top: 18px;
        }

        .dashboard-header__secondary-nav-item-button {
          @include mq($until: 'xl') {
            @include position(relative);
            @include padding(14px 16px 14px 16px);
            flex-shrink: 0;
            border: 1px solid transparent;
            border-radius: 8px;
            background-color: $brand-white;

            @include mq($until: 's') {
              background: transparent;
              border: 0 !important;
            }
          }
  
          .svg--blank {
            @include mq($from: 'xl') {
              display: none;
            }
  
            @include position(absolute, 16px null null 13px);
          }
        }
      }
    }
    
    &:last-child {
      @include mq($until: 'xl') {
        flex-direction: column-reverse;
        padding-bottom: 40px;
        border-bottom: 1px solid transparent;
        margin-top: 20px;
      }

      .dashboard-header__secondary-nav-item {
        @include mq($until: 's') {
          &-button,
          .button {
            text-align: center;
            justify-content: center;
          }
        }

        &:not(:last-child) {
          @include mq($until: 'xl') {
            margin-top: 18px;
          }
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;

      @include mq($until: 's') {
        width: 100%;
        text-align: center;
        justify-content: center;

        .button {
          width: 100%;
        }
      }

      &:not(:last-child) {
        @include mq($from: 'xl') {
          margin-right: 30px;
        }
      }

      &-select-wrapper {
        .svg {
          fill: rgba($brand-grey-darker, .4);
        }
      }

      &-select {
        @include font('sans-serif', 'bold');
        @include padding(7px 10px);
        border-radius: 8px;
        display: flex;
        align-items: center;
        transition: color .3s $ease-out-quad, border-color .3s $ease-out-quad;
        font-size: rem(12px);
        line-height: (16 / 12);
        letter-spacing: .4px;
        color: rgba($brand-grey-darker, .4);
        border: 1px solid $brand-blue-light;

        @include mq($until: 'xl') {
          font-size: rem(14px);
        }

        .svg {
          fill: currentColor;
          margin-right: 8px;
        }
      }

      &-button {
        @include font('sans-serif', 'bold');
        display: flex;
        align-items: center;
        flex-shrink: 0;
        transition: color .3s $ease-out-quad;
        font-size: rem(12px);
        line-height: (16 / 12);
        letter-spacing: .4px;
        color: rgba($brand-grey-darker, .4);
        text-decoration: underline;

        @include mq($until: 'xl') {
          font-size: rem(14px);
        }

        .svg {
          margin-right: 8px;
          fill: currentColor;
        }

        & + .dashboard-header__secondary-nav-item-button {
          margin-left: 5px;
        }

        .no-touchevents &:hover,
        .no-touchevents &:focus {
          color: $brand-grey-darker;
        }
      }
    }

    .button {
      @include mq($until: 'xl') {
        @include padding(21px null);
        height: 52px;
        font-size: rem(11px);
      }
    }
  }
}