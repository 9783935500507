.module-facts-slider {
  &__list {
    &-item {
      &-title {
        @include font('sans-serif', 'bold');
        color: $brand-blue-bright;
      }

      &-button {
        .no-touchevents &:hover,
        .no-touchevents &:focus {
          ~ .module-facts-slider__list-item-title {
            color: $brand-purple-bright;
          }
        }
      }

      &-text {
        @include font('sans-serif', 'regular');
        color: $brand-grey;
      }

      &__nav {
        .svg {
          fill: $brand-blue-bright;
        }

        &-progress {
          background-color: $brand-blue-light;

          &-bar {
            background-color: $brand-blue-bright;
          }
        }
      }

      &.is-active {
        .module-facts-slider__list-item-title {
          @include mq($from: 'm') {
            color: $brand-grey-darker;
          }
        }
      }
    }
  }
}
