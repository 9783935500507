@import 'flatpickr/dist/flatpickr.min';

.flatpickr-days,
.flatpickr-weekdays,
.flatpickr-calendar,
.flatpickr-rContainer {
  width: 100%;
}

.flatpickr-weekdays {
  margin-bottom: 5px;
}

.flatpickr-days {
  .dayContainer {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    justify-content: space-between;
  }
}

.flatpickr-calendar,
.flatpickr-calendar.inline {
  @include margin(15px null null);
  @include padding(20px 33px 18px 33px);
  top: 100% !important;
  left: 50% !important;
  border-radius: 16px;
  font-size: initial;
  line-height: initial;
  min-width: 340px;
  transform: translateX(-50%);

  &.arrowTop,
  &.arrowLeft {
    &::after,
    &::before {
      content: none;
    }
  }

  &.animate {
    &.open {
      animation: none;
    }
  }

  &.is-visible {
    display: block;
  }
}

.flatpickr-months,
.flatpickr-innerContainer {
  max-width: 294px;
  margin-right: auto;
  margin-left: auto;
}

.flatpickr-months {
  align-items: center;
  margin-bottom: 10px;

  .flatpickr-month,
  .flatpickr-prev-month,
  .flatpickr-next-month {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flatpickr-month {
    fill: initial;
    height: auto;
    color: initial;
    overflow: visible;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    @include position(relative, auto auto null auto);
    @include size(34px);
    @include padding(0);

    svg {
      width: 6.4px;
      height: 10.3px;
    }

    &.flatpickr-disabled {
      display: flex;
      opacity: 0;
      pointer-events: none;
    }

    &:hover {
      color: initial;
    }
  }
}

.flatpickr-current-month {
  @include position(relative, auto null null auto);
  @include size(auto);
  @include padding(0);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem(18px);
  line-height: (24 / 18);
  letter-spacing: -0.5px;

  span.cur-month {
    margin-left: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;

    &:hover {
      background-color: transparent;
    }
  }

  .numInputWrapper {
    display: none;
  }
}

.flatpickr-day {
  width: 40px;
  height: 40px;
  border: 0 none;
  border-radius: 50%;
  font-size: rem(15px);
  line-height: 40px;
  letter-spacing: -0.8px;

  &.today,
  &.selected,
  &:hover,
  &:focus {
    border-color: transparent;
  }

  &.prevMonthDay,
  &.nextMonthDay {
    opacity: 0;
    pointer-events: none;
  }
}

span.flatpickr-weekday {
  font-size: rem(13px);
  line-height: (20 / 13);
  letter-spacing: -0.4;
}
