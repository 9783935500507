.module-card-press {
  &__title {
    @include font('sans-serif', 'bold');
    color: $brand-grey-darker;
  }

  &__date {
    @include font('sans-serif', 'medium');
    color: $brand-grey;
    border-color: $brand-blue-light;
  }
}
