.module-quote {
  &__inner {
    border-radius: 24px;
    border: 1px solid $brand-blue-light;
    background-color: $brand-white;
  }

  &__image {
    border: 4px solid #3296FF;
  }

  &__dots {
    @include position(absolute, calc(50% - 67px) null null calc(50% - 67px));
    @include size(134px);
    fill: transparent;
    stroke-width: 2px;
    stroke: $brand-blue-light;
    stroke-dasharray: 3px 12px;
    stroke-linecap: round;

    circle {
      r: calc(50% - 4px);
    }
  }

  cite {
    @include font('sans-serif', 'medium');
    color: $brand-blue;
  }

  p {
    @include font('sans-serif', 'medium');
    color: $brand-grey-darker;
  }

  b,
  strong {
    @include font('sans-serif', 'heavy');
  }

  i,
  em {
    @include font('sans-serif', 'medium-italic');
  }
}
