.content-facts-slider {
  &::before {
    @include position(absolute, 0 null null 50%);
    @include size(4px, 40px);
    @include margin(null null null -2px);
    content: '';
    border-radius: 2px;
    background-color: $brand-blue-light;
  }
}
