.content-keypoints {
  .content__inner {
    @include mq($until: 'l') {
      min-height: 560px;
      justify-content: center;
    }

    @include mq($until: 's') {
      min-height: 460px;
    }

    &::before {
      border-radius: 50%;
      background-color: $brand-blue-lightest;
    }
    
    @include mq($until: 'l') {
      @include position(relative);
      @include margin(null auto);
      max-width: 400px;

      &::before {
        @include size(560px);
        @include position(absolute, calc(50% - 280px) null null calc(50% - 280px));
        content: '';
      }
    }

    @include mq($until: 's') {
      max-width: 320px;

      &::before {
        @include size(460px);
        @include position(absolute, calc(50% - 230px) null null calc(50% - 230px));
      }
    }

    @include mq($until: 'xs') {
      max-width: 100%;
    }
  }

  .content__line {
    margin-right: 30px;
    margin-left: 30px;

    @include mq($until: 'l') {
      display: none;
    }

    &-spark {
      &::before {
        transform-origin: left center;
      }
    }

    &-segment {
      &--1 {
        min-height: 160px;
        max-height: 160px;
      }
    }
  }

  &__circle {
    @include size(228px);
    @include padding(null 34px);
    @include position(relative);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::before {
      @include size(100%, 100%);
      @include position(absolute, 0 null null 0);
      content: '';
      border-radius: 50%;
      background-color: $brand-blue-lightest;
      z-index: -1;

      @include mq($until: 'l') {
        display: none;
      }
    }

    @include mq($until: 'xl', $from: 'l') {
      @include size(200px);
    }

    @include mq($until: 'l') {
      @include padding(null 0);
      @include margin(null auto);
      width: 280px;
      height: auto;
      background: transparent;
    }

    @include mq($until: 'm') {
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__index {
    @include font('sans-serif', 'bold');
    color: $brand-blue;
  }

  &__text {
    @include font('sans-serif', 'bold');
    color: $brand-blue-bright;
  }

  &__line {
    display: block;
    width: 40px;
    height: 4px;
    border-radius: 2px;
    background-color: $brand-blue-light;

    @include mq($until: 'l') {
      display: none;
    }
  }

  html:not(.mobile):not(.ie) &.js-in-view {
    .content__column {
      &:first-child {
        .content-keypoints__line {
          transform-origin: right center;
        }
      }
      
      &:last-child {
        .content-keypoints__line {
          transform-origin: left center;
        }
      }
    }

    .content-keypoints__index {
      @include mq($from: 'l') {
        transform: translateY(20px);
        opacity: 0;
        transition: transform 0.4s .3s $ease-out-quad, opacity 0.4s .3s $ease-out-quad;
      }
    }
    
    .content-keypoints__line,
    .content__line-spark::before {
      transform: scaleX(0);
      transition: transform 0.5s .2s $ease-out-quad
    }

    .content-keypoints__circle {
      &::before {
        transform: scale(0);
        transition: transform 1.1s .2s $ease-out-expo;
      }
    }

    .content__inner {
      &::before {
        transform: scale(0);
        transition: transform 1.1s .1s $ease-out-expo;
      }
    }

    &.in-view {
      .content-keypoints__line,
      .content__line-spark::before {
        transform: scaleX(1);
      }

      .content-keypoints__index {
        transform: translateY(0px);
        opacity: 1;
      }

      .content-keypoints__circle {
        &::before {
          transform: scale(1);
        }
      }

      .content__inner {
        &::before {
          transform: scale(1);
        }
      }
    }
  }
}
