.header--landing-detail {
  .module-image {
    &:nth-child(1) {
      @include position(absolute, 170px null null 50%);
      @include margin(null null null 320px);
      max-width: 80px;

      @include mq($until: 'm') {
        margin-left: 270px;
        top: 190px;
      }

      @include mq($until: 's') {
        margin-left: 200px;
      }

      @include mq($until: 'xs') {
        margin-left: 150px;
        top: 150px;
      }
    }

    &:nth-child(2) {
      @include position(absolute, null null 80px 50%);
      @include margin(null null null -620px);
      max-width: 240px;

      @include mq($until: 'l') {
        margin-left: -570px;
      }

      @include mq($until: 'm') {
        max-width: 180px;
        margin-left: -410px;
        bottom: 230px;
      }

      @include mq($until: 's') {
        margin-left: -340px;
      }

      @include mq($until: 'xs') {
        bottom: 250px;
        margin-left: -280px;
      }

      .circle--1 {
        @include position(absolute, 20px 40px auto auto);
        @include size(24px);

        @include mq($until: 'm') {
          @include size(16px);
          right: 35px;
        }
      }

      .circle--2 {
        @include position(absolute, -24px 72px auto auto);
        @include size(8px);

        @include mq($until: 'm') {
          right: 62px;
          top: -20px;
        }
      }
    }

    &:nth-child(3) {
      @include position(absolute, null null -50px 50%);
      @include margin(null null null 390px);
      max-width: 240px;

      @include mq($until: 'l') {
        margin-left: 230px;
      }

      @include mq($until: 'm') {
        max-width: 177px;
        bottom: -30px;
        margin-left: 100px;
      }

      @include mq($until: 'xs') {
        margin-left: 70px;
      }

      .circle--1 {
        @include position(absolute, 25px auto auto 40px);
        @include size(8px);

        @include mq($until: 'm') {
          display: none;
        }
      }
    }
  }
}
