.content-cards-slider {
  &::after,
  &::before {
    @include position(absolute, null null null 50%);
    @include size(4px, 65px);
    @include margin(null null null -2px);
    border-radius: 2px;
    background-color: $brand-blue-light;
  }

  &::after {
    top: 0;
  }

  &::before {
    bottom: 0;
  }

  &__title {
    @include font('sans-serif', 'heavy');
    color: $brand-grey-darker;
  }

  &__text {
    @include font('sans-serif', 'book');
    color: $brand-grey;
  }

  &--activity {
    &::after,
    &::before {
      content: '';
    }
  }
}
