.content-callout {
  padding-top: 20px;
  padding-bottom: 80px;

  .module-image {
    margin-right: auto;
    margin-bottom: -15px;
    margin-left: auto;

    @include mq($until: 'm') {
      margin-bottom: 15px;
    }
  }

  .module-header {
    max-width: gs(6, 5);
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0;
    text-align: center;

    @include mq($from: 'xxl') {
      max-width: gs(6, 5, 1392px, 12, 48px);
    }
  }

  &--orbits {
    @include padding(120px null);

    @include mq($until: 'm') {
      @include padding(80px null);
    }
  }
}
