.content {
  .module-header {
    + .content__inner {
      .content__line-dot,
      .content__line-segment--1 {
        display: none;
      }
    }
  }

  &__line {
    display: flex;
    flex-basis: gs(0, 1);
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;

    @include mq($from: 'xxl') {
      flex-basis: gs(0, 1, 1392px, 12, 48px);
    }

    @include mq($until: 'm') {
      display: none;
    }

    &-dot,
    &-sparks {
      @include position(relative);
      @include size(8px);
      @include margin(10px null);
      display: block;
      border-radius: 50%;
      background-color: $brand-blue;
    }

    &-segment {
      flex: 1;
      width: 4px;
      border-radius: 2px;
      background-color: $brand-blue-light;
    }

    &-spark {
      @include position(absolute, 50% null null 50%);
      @include size(34px, 4px);
      @include margin(-2px null null);
      @include padding(null null null 14px);
      transform-origin: 0% 50%;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 2px;
        background-color: $brand-blue-light;
      }

      &--2 {
        transform: rotate(45deg);
      }

      &--3 {
        transform: rotate(135deg);
      }

      &--4 {
        transform: rotate(180deg);
      }

      &--5 {
        transform: rotate(225deg);
      }

      &--6 {
        transform: rotate(315deg);
      }

      &--7 {
        transform: rotate(90deg);
      }

      &--8 {
        transform: rotate(270deg);
      }
    }
  }

  + .content {
    .content__line {
      &-segment {
        &--1 {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }

    &::before {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
}
