.module-card-small {
  @include mq($until: 'l') {
    min-height: 88px;
  }

  .module-card__link {
    + .module-card__inner {
      @include mq($until: 'l') {
        padding-right: 80px;
      }
    }
  }

  .module-card__inner {
    display: flex;
    flex-direction: column;

    @include mq($until: 'l') {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__icon {
    @include size(48px);
    @include margin(null 20px null -20px);
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    @include mq($until: 'm') {
      margin-left: -15px;
      margin-right: 15px;
    }

    img {
      display: block;
      width: 24px;
    }
  }

  &__title {
    font-size: rem(20px);
    letter-spacing: -0.6px;
    line-height: (24 / 20);
    
    @include mq($from: 'l') {
      @include margin(auto null null);
      @include padding(75px null null);
    }

    @include mq($until: 'm') {
      font-size: rem(18px);
      letter-spacing: -.4px;
    }

    @include mq($from: 'xxl') {
      font-size: rem(22px);
    }
  }

  &__text {
    margin-top: 16px;
    font-size: rem(16px);
    line-height: (22px);
    letter-spacing: -0.5px;
  }

  .module-card__dot {
    @include mq($until: 'l') {
      top: 28px;
    }
  }

  &--narrow {
    .module-card__inner {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .module-card__link {
      + .module-card__inner {
        padding-top: 28px;
        padding-right: 80px;
        padding-bottom: 28px;
      }
    }

    .module-card__dot {
      @include mq($from: 'l') {
        top: 36px;

        &::before {
          top: -13px;
          right: 7px;
          transform: rotate(45deg);
        }
      }

      @include mq($until: 'l') {
        top: 22px;
      }
    }

    .module-card-small__icon {
      + .module-card-small__title {
        margin-top: auto;
      }
    }
    
    .module-card-small__title {
      @include margin(0 null auto);
      @include padding(0 null null);
    }
  }
}
