.tag {
  @include position(relative);
  @include padding(5px 8px);
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  border-radius: 6px;
  white-space: nowrap;
  font-size: rem(10px);
  letter-spacing: 1px;
  text-transform: uppercase;

  &::before {
    @include position(absolute, 0 null null 0);
    @include size(100%, 100%);
    content: '';
    z-index: -1;
    background-color: $brand-white;
  }

  .svg {
    margin-top: -1px;
    fill: currentColor;
    margin-right: 5px;
  }
}
