.sidebar-menu {
  a {
    @include font('sans-serif', 'bold');
    color: $brand-blue-bright;

    .no-touchevents &:hover,
    .no-touchevents &:focus {
      color: $brand-purple-bright;
    }

    &::after {
      background-color: $brand-blue-light;
    }
  }
}
