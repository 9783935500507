.module-card-schedule {
  &__text,
  &__caption {
    @include font('sans-serif', 'regular');
  }
  
  &__hour {
    color: $brand-blue-bright;
  }
  
  &__text {
    color: $brand-grey-darker;
  }

  &__hour {
    @include font('sans-serif', 'bold');
  }

  &__caption {
    color: $brand-grey;
  }

  + .module-card-schedule {
    border-color: $brand-blue-light;
  }
}
