.button-circle {
  &--blue {
    border-color: $brand-blue;

    .svg {
      fill: $brand-blue;
    }

    .no-touchevents &:not([disabled]):hover,
    .no-touchevents &:not([disabled]):focus {
      background-color: $brand-blue;

      .svg {
        fill: $brand-white;
      }
    }
  }

  &--white {
    border: $brand-white;
    background-color: $brand-white;

    .svg {
      fill: $brand-blue-bright;
    }

    .no-touchevents &:not([disabled]):hover,
    .no-touchevents &:not([disabled]):focus {
      border-color: $brand-blue-bright;
      background-color: $brand-blue-bright;

      .svg {
        fill: $brand-white;
      }
    }
  }
}
