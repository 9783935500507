.content-subscription-pricing {
  &::after,
  &::before {
    @include position(absolute, null null null 50%);
    @include size(4px, 65px);
    @include margin(null null null -2px);
    content: '';
    border-radius: 2px;
    background-color: $brand-blue-light;
  }

  &::after {
    bottom: -6px;
  }

  &::before {
    top: 0;
  }

  .module-table--desktop {
    position: relative;
    border-radius: 16px;
    border: 1px solid $brand-blue-light;
    background-color: $brand-white;

    &::after {
      @include position(absolute, -40px null null -40px);
      @include size(240px, 300px);
      content: '';
      z-index: -1;
      background-image: url('../assets/images/decorations/dot-pattern-2@2x.png');
      background-repeat: repeat;
      background-position: 50% 50%;
      background-size: 38px auto;
    }

    th,
    td {
      &:nth-child(2) {
        background-color: $brand-blue-lighter;
      }

      &:nth-child(3) {
        background-color: #E5FBF6;

        .box {
          fill: $brand-green-darker;
        }

        .module-table__button {
          color: $brand-grey-darker;
          background-color: $brand-green-bright;

          .no-touchevents &:hover,
          .no-touchevents &:focus {
            background-color: rgba($brand-green-bright, .4);
          }
        }
      }
    }

    th {
      &:nth-child(2) {
        .box {
          color: $brand-blue-bright;

          small {
            color: $brand-blue-bright;
          }
        }
      }

      &:nth-child(3) {
        .box {
          color: $brand-green-darker;

          small {
            color: $brand-green-darker;
          }
        }
      }
    }

    thead {
      th:nth-child(3) {
        border-color:rgba($brand-green-bright, .3);
      }
    }

    tbody {
      td:nth-child(3) {
        &::after {
          background-color: rgba($brand-green-bright, .2);
        }
      }
    }

    tfoot {
      td:nth-child(3) {
        border-color:rgba($brand-green-bright, .3);
      }
    }
  }

  .module-table--mobile {
    tfoot {
      tr {
        &:first-child {
          td {
            border-bottom: 0;
          }
        }

        &:not(:first-child) {
          td {
            padding-top: 0;
            border-top: 0;
          }
        }
      }

      .button {
        width: 100%;
        text-align: center;
      }
    }
  }
}
