.site-footer {
  @include padding(100px null 95px);
  @include position(relative);
  overflow: hidden;

  @include mq($until: 'm') {
    @include padding(65px null);
  }

  &__row {
    display: flex;
    width: 100%;

    @include mq($until: 'm') {
      flex-wrap: wrap;
    }

    @include mq($until: 's') {
      flex-direction: column;
    }

    &:last-child {
     /* margin-top: 85px;*/
      justify-content: space-between;

      @include mq($until: 'm') {
        margin-top: 45px;
      }

      @include mq($until: 's') {
        margin-top: 22px;
      }
    }
  }

  &__column {
    width: gs(2, 1);

    @include mq($until: 'm', $from: 's') {
      width: calc(50% - 18px);
      flex-shrink: 0;

      &:nth-child(odd) {
        margin-right: 36px;
      }

      &:nth-child(n + 3) {
        margin-top: 40px;
      }
    }

    @include mq($until: 's') {
      width: 100%;
      
      &:nth-child(1) {
        margin-bottom: 40px;
      }

      &:nth-child(2) {
        margin-bottom: 20px;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        border-bottom: 2px solid transparent;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    @include mq($from: 'xxl') {
      width: gs(2, 1, 1392px, 12, 48px);
    }

    &-toggler {
      @include mq($from: 's') {
        display: none;
      }
  
      @include mq($until: 's') {
        @include padding(20px 0);
        @include size(100%, auto);
        @include position(relative);
        @include font('sans-serif', 'book');
        background: transparent;
        border: 0;
        text-align: left;
        font-size: rem(16px);
        line-height: (22 / 16);
        letter-spacing: -.4px;

        .svg {
          @include position(absolute, calc(50% - 3px) 0 null null);
          fill: currentColor;
        }
        
        ~ .site-footer__eyebrow,
        ~ .site-footer__secondary-nav {
          display: none;
        }

        &.is-active {
          .svg {
            transform: rotate(180deg);
          }

          ~ .site-footer__secondary-nav {
            display: block;
          }
        }
      }
    }

    &:first-child,
    &:nth-child(2) {
      @include mq($from: 'm') {
        width: gs(3, 2);
      }

      @include mq($from: 'xxl') {
        width: gs(3, 2, 1392px, 12, 48px);
      }
    }
    
    &:not(:last-child) {
      @include mq($from: 'm') {
        margin-right: gs(0, 1);
      }

      @include mq($from: 'xxl') {
        margin-right: gs(0, 1, 1392px, 12, 48px);
      }
    }
  }

  &__primary-nav {
    &-item {
      &:first-child {
        .site-footer__primary-nav-item-button {
          padding-top: 0;
        }
      }
      
      &-button {
        @include padding(8px null);
        display: block;
        color: $brand-white;
        transition: color .3s $ease-out-quad;
      }
    }
  }

  &__secondary-nav {
    @include mq($until: 's') {
      padding-bottom: 20px;
    }

    &-item {
      &:first-child {
        .site-footer__secondary-nav-item-button {
          padding-top: 0;
        }
      }

      &-button {
        @include padding(4px null);
        @include font('sans-serif', 'book');
        display: flex;
        align-items: center;
        color: $brand-white;
        font-size: rem(16px);
        line-height: (22 / 16);
        letter-spacing: -.4px;
        transition: color .3s $ease-out-quad;

        .svg {
          opacity: .6;
          margin-right: 8px;
          min-width: 16px;
          fill: currentColor;
          transition: opacity .3s $ease-out-quad;

          &--facebook {
            margin-left: 2px;
            margin-right: 6px;
          }
        }

        .no-touchevents &:focus,
        .no-touchevents &:hover {
          .svg {
            opacity: 1;
          }
        }
      }
    }
  }

  &__eyebrow {
    display: block;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__text {
    @include font('sans-serif', 'regular');
    font-size: rem(16px);
    line-height: (24 / 16);
    letter-spacing: -.5px;
  }

  &__metas {
    display: flex;
    align-items: center;

    @include mq($until: 's') {
      display: inline-flex;
      flex-wrap: wrap;
    }

    &-item,
    &-item-button {
      @include font('sans-serif', 'regular');
      font-size: rem(14px);
      line-height: (20 / 14);
    }

    &-item {
      @include mq($until: 's') {
        margin-right: 28px;

        &:first-child {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      &--copyright {
        @include mq($until: 's') {
          margin-bottom: 40px;
          display: block;
          width: 100%;
          margin-right: 0;
          margin-top: 20px;
        }
      }

      &-button {
        text-decoration: underline;
        transition: color .3s $ease-out-quad;
      }

      + .site-footer__metas-item {
        margin-left: 30px;

        @include mq($until: 's') {
          margin-left: 0;
        }
      }
    }
  }

  &__dropdown {
    .dropdown__button {
      @include padding(13px 29px 13px 24px);
      border-radius: 30px;
      color: $brand-white;
      text-transform: uppercase;
      border: 0;

      @include mq($until: 's') {
        @include padding(18px null);
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  &__partners {
    display: flex;
    align-items: flex-end;
    margin-top: -20px;

    @include mq($until: 'm') {
      margin-top: 30px;
      width: 100%;
    }

    @include mq($until: 's') {
      margin-top: 0;
    }

    &-item {
      display: block;

      &:not(:last-child) {
        margin-right: 40px;
      }

      img {
        display: block;
        max-width: 100px;
        max-height: 60px;
      }
    }
  }
}
