.content-key-numbers {
  .content__inner {
    @include mq($until: 'l') {
      min-height: 560px;
      justify-content: center;
    }

    @include mq($until: 's') {
      min-height: 460px;
    }

    &::before {
      border-radius: 50%;
      background-color: $brand-blue-lightest;
      z-index: -1;
    }

    @include mq($until: 'l') {
      @include position(relative);
      @include margin(null auto);
      max-width: 400px;

      &::before {
        @include size(560px);
        @include position(absolute, calc(50% - 280px) null null calc(50% - 280px));
        content: '';
      }
    }

    @include mq($until: 's') {
      max-width: 320px;

      &::before {
        @include size(460px);
        @include position(absolute, calc(50% - 230px) null null calc(50% - 230px));
      }
    }

    @include mq($until: 'xs') {
      max-width: 100%;
    }
  }

  .content__line {
    margin-right: 30px;
    margin-left: 30px;

    @include mq($until: 'l') {
      display: none;
    }
  }

  &__value {
    @include font('sans-serif', 'regular');
    color: $brand-blue;

    b,
    strong {
      @include font('sans-serif', 'bold');
    }
  }

  &__text {
    @include font('sans-serif', 'regular');
    color: $brand-grey;
  }

  &__line {
    display: block;
    width: 40px;
    height: 4px;
    border-radius: 2px;
    background-color: $brand-blue-light;

    @include mq($until: 'l') {
      display: none;
    }
  }
}
