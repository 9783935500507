.module-card-schedule {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;

  @include mq($until: 'm') {
    flex-direction: column;
  }

  &__metas {
    flex-basis: gs(2, 1);
    flex-shrink: 0;

    @include mq($until: 'm') {
      flex-basis: 100%;
      margin-bottom: 11px;
    }

    @include mq($from: 'xxl') {
      flex-basis: gs(2, 1, 1392px, 12, 48px);
    }
  }

  &__hour {
    font-size: rem(16px);
    line-height: (24 / 16);
  }

  &__content {
    flex: 1;
    margin-right: gs(0, 1);
    margin-left: gs(0, 1);

    @include mq($until: 'm') {
      margin-right: 0;
      margin-left: 0;
    }

    @include mq($from: 'xxl') {
      margin-right: gs(0, 1, 1392px, 12, 48px);
      margin-left: gs(0, 1, 1392px, 12, 48px);
    }
  }

  &__text {
    font-size: rem(16px);
    line-height: (24 / 16);
    letter-spacing: -0.5px;
  }

  &__caption {
    margin-top: 12px;
    font-size: rem(13px);
    line-height: (16 / 13);
    letter-spacing: -0.4px;
  }

  &__button {
    flex-basis: gs(2, 1);
    flex-shrink: 0;
    margin-top: -3px;
    text-align: right;

    @include mq($until: 'm') {
      margin-top: 14px;
      text-align: left;
      flex-basis: auto;
    }

    @include mq($from: 'xxl') {
      flex-basis: gs(2, 1, 1392px, 12, 48px);
    }
  }

  + .module-card-schedule {
    margin-top: -1px;
    border-top: 1px solid transparent;
  }
}
