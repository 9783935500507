.module-card {
  &__link {
    .no-touchevents &:hover ~ .module-card__inner,
    .no-touchevents &:focus ~ .module-card__inner,
    ~ .module-card__inner:hover {
      transform: translateY(-12px);
      box-shadow: 0 6px 15px 0 rgba(6, 41, 78, 0.08);

      &::before {
        opacity: 0;
      }

      .module-card__dot::before {
        opacity: 1;
      }
    }
  }

  &__dot {
    @include position(absolute, 32px 32px null null);
    @include size(8px);
    border-radius: 50%;
    pointer-events: none;
    background-color: $brand-green-bright;
    z-index: 3;

    &::before {
      @include size(34px);
      @include position(absolute, 1px 1px null null);
      content: '';
      opacity: 0;
      background-image: image('decorations/card-small-arrow-light-grey@2x.png');
      background-size: contain;
      background-repeat: no-repeat;
      transition: opacity 0.3s $ease-out-quart;

      @include mq($until: 'l') {
        opacity: 1;
      }
    }
  }

  &__inner {
    border-radius: 16px;
    background: $brand-white;
    transition: transform 0.3s $ease-out-quad, box-shadow 0.3s $ease-out-quad, border-radius 0.3s $ease-out-quart;

    &::before {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      content: '';
      border-radius: 16px;
      border: 1px solid $brand-blue-light;
      transition: opacity 0.3s $ease-out-quart, border-color 0.3s $ease-out-quad;
    }
  }
}
