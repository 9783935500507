.header-inner {
  width: 100%;
  text-align: center;

  &__eyebrow {
    margin-bottom: 20px;
  }

  &__tags {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__text {
    margin-top: 32px;
    margin-right: auto;
    margin-left: auto;
    font-size: rem(20px);
    line-height: (28 / 20);
    letter-spacing: -0.4px;
  }

  &__title {
    font-size: rem(60px);
    line-height: (58 / 60);
    letter-spacing: -1.3px;

    @include mq($from: 'xxl') {
      font-size: rem(68px);
      line-height: (66 / 68);
      letter-spacing: -1.5px;
    }

    @include mq($until: 'm') {
      @include padding(null 30px);
      font-size: rem(48px);
      letter-spacing: -1px;

      br {
        display: none;
      }
    }

    @include mq($until: 's') {
      font-size: rem(44px);
      letter-spacing: -.8px;
    }

    @include mq($until: 'xs') {
      @include padding(null 0);
    }
  }

  &__metas {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 24px;
    font-size: rem(20px);
    line-height: (28 / 20);
    letter-spacing: -0.4px;

    @include mq($until: 'm') {
      font-size: rem(18px);
      line-height: (26 / 20);
    }

    li {
      &:not(:last-child) {
        display: flex;
        align-items: center;

        &::after {
          @include size(4px);
          @include margin(null 12px);
          content: '';
          display: block;
          border-radius: 50%;
          flex-shrink: 0;
        }
      }
    }
  }

  &__form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 66px;
    margin-right: -12px;
    margin-left: -12px;

    .form-filter {
      flex: 1;
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 48px;

    @include mq($until: 'm') {
      flex-direction: column;
    }

    @include mq($from: 'm') {
      margin-right: -10px;
      margin-left: -10px;
    }

    .button-icon {
      &,
      .button-icon__label,
      &::before,
      .svg--arrow-right {
        transition-delay: .4s;
      }
    }

    li {
      @include mq($from: 'm') {
        padding-right: 10px;
        padding-left: 10px;
      }

      @include mq($until: 'm') {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

html:not(.mobile):not(.ie) .js-in-view {
  .header-inner {
    &__eyebrow,
    &__text {
      opacity: 0;
      transform: translateY(30px);
      transition: opacity .4s .1s $ease-out-quad, transform .4s .1s $ease-out-quad;
    }

    &__text {
      transition-delay: .5s;
    }
  }

  &.in-view {
    .header-inner {
      &__eyebrow,
      &__text {
        opacity: 1;
        transform: translateY(0px);
      }
    }
  }
}
